import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFiltersAction } from "@icarius-common/filtersDialog/actions";

const useFilterDialog = () => {

  const [ filtersDialogIsOpen, setFiltersDialogIsOpen ] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(filtersDialogIsOpen){
      dispatch(getFiltersAction());
    }
  }, [dispatch, filtersDialogIsOpen])

  return { filtersDialogIsOpen, setFiltersDialogIsOpen };
}

export default useFilterDialog;
