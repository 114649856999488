import React from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { openDialogAction } from "@icarius-common/dialog/actions";
import PollIcon from '@material-ui/icons/Poll';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import SubjectIcon from '@material-ui/icons/Subject';
import DescriptionIcon from '@material-ui/icons/Description';
import { LinkIcon } from "@icarius-icons/index";
import TreeItem from '@material-ui/lab/TreeItem';
import { previewFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

const Material = ({ data, index, classes }) => {

  const dispatch = useDispatch();

  const icon = (() => {
    if (data.link) return <LinkIcon color="inherit" className={classes.labelIcon} />;
    if (data.file) return <DescriptionIcon color="inherit" className={classes.labelIcon} />;
    return <SubjectIcon color="inherit" className={classes.labelIcon} />;
  })();

  const isOnlyGuide = Boolean(
    data.consent === "" &&
    data.poll === "" &&
    data.link === "" &&
    data.file === null
  );

  const textStyle = (() => {
    if (isOnlyGuide) return { textDecoration: "underline" };
    return {}
  })();

  const handleClick = () => {
    if (!isOnlyGuide) return;
    dispatch(openDialogAction({
      title: data.name,
      msg: data.guide,
      onConfirm: null,
      acceptOnly: true,
      maxWidth: "md",
      fullWidth: true,
      acceptText: "Cerrar"
    }));
  }

  return (
    <>
      <TreeItem
        nodeId={`material-main-${data.code}-${index}`}
        label={
          <div className={classes.labelRoot}>
            {icon}
            <Typography
              variant="body2"
              className={classes.labelText}
              style={textStyle}
              onClick={handleClick}
            >
              {isOnlyGuide ? `Instructivo o guía de uso: ${data.name}` : data.name}
            </Typography>
            {
              data.link &&
              <Typography
                variant="caption"
                color="inherit"
                style={{ textDecoration: "underline" }}
                onClick={() => previewFromURL(data.link)}
              >
                {data.link}
              </Typography>
            }
            {
              data.file &&
              <Typography
                variant="caption"
                color="inherit"
                style={{ textDecoration: "underline" }}
                onClick={() => previewFromURL(RESOURCES_ENDPOINT + data.file.path)}
              >
                {data.file.name}
              </Typography>
            }
          </div>
        }
      />
      <TreeItem
        nodeId={`material-access-${data.code}-${index}`}
        label={
          <div className={classes.labelRoot}>
            <HowToRegIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {`Con acceso para: ${data.access}`}
            </Typography>
          </div>
        }
      />
      {
        data.poll &&
        <TreeItem
          nodeId={`material-poll-${data.code}-${index}`}
          label={
            <div className={classes.labelRoot}>
              <PollIcon color="inherit" className={classes.labelIcon} />
              <Typography
                variant="body2"
                className={classes.labelText}
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  dispatch(openDialogAction({
                    title: data.poll,
                    msg: data.pollDescription,
                    onConfirm: null,
                    acceptOnly: true,
                    acceptText: "Cerrar"
                  }))
                }}
              >
                {`Encuesta - ${data.poll}`}
              </Typography>
            </div>
          }
        />
      }
      {
        data.consent &&
        <TreeItem
          nodeId={`material-consent-${data.code}-${index}`}
          label={
            <div className={classes.labelRoot}>
              <AssignmentTurnedInIcon color="inherit" className={classes.labelIcon} />
              <Typography variant="body2" className={classes.labelText}>
                {`Consentimiento: ${data.consent}`}
              </Typography>
            </div>
          }
        >
          <TreeItem
            nodeId={`material-accessRequired-${data.code}-${index}`}
            label={
              <div className={classes.labelRoot}>
                <Typography variant="body2" className={classes.labelText}>
                  {`Uso o acceso obligatorio: ${data.accessRequired}`}
                </Typography>
              </div>
            }
          />
        </TreeItem>
      }
    </>
  )
}

export default Material;