import React from "react";
import { PlayCircleOutlineIcon, TextIcon } from "@icarius-icons";
import { Grid, MenuItem } from "@material-ui/core";
import { previewFromURL } from "@icarius-utils/download";
import { BASE_URL } from "@icarius-connection/endpoints";

const iconStyle = { marginRight: "5px" };

//si se quita el forwardRef tira un warning misterioso sobre una ref aunque no se use ninguna
const MenuItemWithIcon = React.forwardRef((props, ref) => {

  const {
    text,
    onClick,
    type,
    value,
  } = props;

  const handleClick = () => {
    switch (type) {
      case 'video': {
        let dlAnchorElem = document.getElementById("downloadAnchorElem");

        dlAnchorElem.setAttribute("href", value);
        dlAnchorElem.setAttribute("target", "_blank");
        dlAnchorElem.click();
        return;
      }
      case 'file': {
        previewFromURL(BASE_URL + value);
        return;
      }
      default: return;
    }
  }

  const getIcon = () => {
    switch (type) {
      case 'video': return <PlayCircleOutlineIcon style={iconStyle} className="whiteText" fontSize="small" />;
      case 'file': return <TextIcon style={iconStyle} className="whiteText" fontSize="small" />;
      default: return "";
    }
  }

  return (
    <MenuItem onClick={onClick || handleClick}>
      <Grid container alignItems="center">
        {getIcon()}
        <Grid item>
          {text}
        </Grid>
      </Grid>
    </MenuItem>
  );
})

export default MenuItemWithIcon;