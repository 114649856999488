import React from "react";
import { Grid, Chip, Typography } from "@material-ui/core";
import { DeleteForeverIcon } from "@icarius-icons";

const FileList = (props) => {

  const {
    files,
    filesWithError,
    handleClick,
    multiple,
  } = props;

  return (
    <>
      <Grid container style={{ marginTop: 5, marginBottom: 5, maxHeight: 150, overflow: "auto" }}>
        {
          files?.map((file) => {
            return (
              <Grid key={file.name} item xs={12} style={{ marginTop: 5 }}>
                <Chip
                  label={file.name}
                  className={filesWithError?.includes(file.name) ? "errorColor" : "whiteText"}
                  variant="outlined"
                  style={{ borderColor: "transparent" }}
                  avatar={
                    <DeleteForeverIcon
                      style={{ cursor: "pointer" }}
                      className="whiteText"
                      onClick={() => handleClick(file)}
                    />
                  }
                />
              </Grid>
            )
          })
        }
      </Grid>
      {
        multiple &&
        <Typography variant='subtitle1' className="whiteText">
          Cantidad de archivos seleccionados: <b>{files?.length || 0}</b>
        </Typography>
      }
    </>
  );
}

export default FileList;
