import React from 'react';
import ReactEmojiPicker, {
    EmojiStyle,
    Categories,
} from "emoji-picker-react";
import { useSelector } from 'react-redux';
import { getTheme } from '@icarius-pages/login/selectors';
import { Popover } from '@material-ui/core';

const DEFAULT_CATEGORIES = [
    {
        name: "Emojis y personas",
        category: Categories.SMILEYS_PEOPLE,
    },
    {
        name: "Animales y naturaleza",
        category: Categories.ANIMALS_NATURE,
    },
    {
        name: "Alimentos y bebidas",
        category: Categories.FOOD_DRINK,
    },
    {
        name: "Actividades",
        category: Categories.ACTIVITIES,
    },
    {
        name: "Viajes y lugares",
        category: Categories.TRAVEL_PLACES,
    },
    {
        name: "Objetos",
        category: Categories.OBJECTS,
    },
    {
        name: "Símbolos",
        category: Categories.SYMBOLS,
    },
];

const EmojiPicker = (props) => {

    const {
        anchorEl,
        handleChange,
        handleClose,
    } = props;

    const { theme } = useSelector(getTheme);

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handleClose}
        >
            <ReactEmojiPicker
                onEmojiClick={(e) => {
                    handleChange(e.emoji);
                    handleClose();
                }}
                height={350}
                width={350}
                categories={DEFAULT_CATEGORIES}
                theme={theme}
                autoFocusSearch
                previewConfig={{ showPreview: false }}
                searchPlaceHolder="Buscar emoji"
                emojiStyle={EmojiStyle.NATIVE}
                emojiVersion="11.0"
            />
        </Popover>
    );
}

export default EmojiPicker;