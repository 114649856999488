import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const Plan = (props) => {

    const {
        name,
        level,
        status,
        pointsToExchange,
        showPointsToExchange,
        dialog,
    } = props;

    const color = useSelector(getAppColor);

    const getLevelMessage = () => {
        if (level === 1) return '¡Sigue sumando puntos!';
        if (level === 2) return '¡Colaborador estrella!';
        return '¡Colaborador super estrella!';
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 20,
            }}
        >
            <Typography className="whiteText" align="center" style={{ fontSize: 16, marginTop: -5 }}>
                {'Plan de reconocimiento:'}
            </Typography>
            <Typography className="whiteText" align="center" style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                {`${name} ${status === 'H' ? '(Histórico)' : ''}`}
            </Typography>
            {
                (!dialog || showPointsToExchange) &&
                <>
                    <Typography className="whiteText" align="center" style={{ fontSize: 16 }}>
                        {'Puntos disponibles para canje:'}
                    </Typography>
                    <Typography align="center" style={{ fontSize: 18, color: color, fontWeight: 600 }}>
                        {formatNumberExactDecimals(pointsToExchange, 0)}
                    </Typography>
                </>
            }
            <Typography className="whiteText" style={{ fontWeight: 600, fontSize: 18 }}>
                {getLevelMessage()}
            </Typography>
        </div>
    )
}

export default Plan;
