import React from "react";
import { Typography } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useZoom from "@icarius-utils/hooks/useZoom";
import useBoardLogic from "./boardLogic/useBoardLogic";
import VerticalLabel from "./components/labels/verticalLabel";
import HorizontalLabel from "./components/labels/horizontalLabel";
import FreeUsersLabel from "./components/labels/freeUsersLabel";
import QuadrantDnd from "./components/quadrantDnd";
import Quadrant from "./components/quadrantDnd";
import Piece from "./components/piece";
import ZoomButtons from "./components/zoomButtons";

const DEFAULT_QUADRANT_ORDER = ["1,1", "1,2", "1,3", "2,1", "2,2", "2,3", "3,1", "3,2", "3,3"];

const NineBoxChart = (props) => {

  // Explicación props:
  // initialData: Obligatorio. Array con la información de las piezas. Cada objeto tiene que tener un campo positions:{ x, y }
  // nineBoxDefinition: Opcional. Tiene la información de los cuadrantes.
  // onChange: Opcional. Se ejecuta cuando cambia de posicion alguna pieza del board. 
  //    Si no se pasa la prop, se desactivan los drag and drop
  // onPieceDrop: Opcional. Se ejecuta al soltar un elemento.
  //    Si se pasa la prop, esta tiene que encargarse de actualizar la posicion.
  //    Si no se pasa la prop pero SI se pasa la prop onChange, el componente se encarga de actualizar la posicion automaticamente.
  //    Si no se pasa la prop y tampoco se pasa la prop onChange, no se actualiza la posición.
  // onQuadrantClick: Opcional. Se ejecuta al hacer clic en un cuadrante.
  // renderQuadrantTooltipContent: Opcional. Función que devuelve contenido del tooltip de cada cuadrante.
  // renderPieceContent: Obligatorio. Función que renderiza cada pieza.
  // hideFreeUsers: Opcional. Booleano para ocultar la seccion de usuarios sin clasificacion
  // hideZoom: Opcional. Booleano para ocultar el zoom
  // initialZoom: Opcional. Tamaño inicial del zoom.

  const {
    initialData,
    nineBoxDefinition,
    onPieceDrop,
    onChange,
    onQuadrantClick,
    renderQuadrantTooltipContent,
    renderPieceContent,
    hideFreeUsers,
    hideZoom,
    initialZoom,
  } = props;

  const dragAndDropEnabled = Boolean(onChange);

  const {
    boardLogic,
    pieces,
  } = useBoardLogic(initialData, onChange);

  const {
    zoom,
    zoomIn,
    zoomOut,
  } = useZoom(initialZoom || 15, 10, 25)

  const QuadrantToUse = dragAndDropEnabled ? QuadrantDnd : Quadrant;

  const handleDrop = (item, x, y) => {
    if (!dragAndDropEnabled) return;
    if (!onPieceDrop) {
      boardLogic.movePiece(item, x, y);
      return;
    }
    onPieceDrop(boardLogic, item, x, y);
  }

  const handleQuadrantClick = (quadrantBoxDefinition) => {
    if (onQuadrantClick) onQuadrantClick(quadrantBoxDefinition);
  }

  const getRightOrderArray = (arrayToOrder) => {
    // como los cuadrantes se cuentan de abajo para arriba, tengo que modificar el orden (de a 3, que son los items por fila)
    // tambien se usa para boxDefinition
    return [...arrayToOrder.slice(6, 9), ...arrayToOrder.slice(3, 6), ...arrayToOrder.slice(0, 3)];
  }

  const renderSquare = (coordinates, indexQuad) => {
    const quadrantX = parseInt(coordinates.split(',')[1]);
    const quadrantY = parseInt(coordinates.split(',')[0]);

    const piecesOnSquare = pieces.filter((piece) => {
      const pieceX = piece.position.x;
      const pieceY = piece.position.y;
      return pieceX === quadrantX && pieceY === quadrantY;
    });

    const quadrantBoxDefinition = (nineBoxDefinition && indexQuad !== null) ? getRightOrderArray(nineBoxDefinition)[indexQuad] : null;

    return (
      <div key={coordinates}>
        <QuadrantToUse
          x={quadrantX}
          y={quadrantY}
          tooltipContent={renderQuadrantTooltipContent ? renderQuadrantTooltipContent(quadrantBoxDefinition, piecesOnSquare?.length) : ''}
          onDrop={(item, x, y) => handleDrop(item, x, y)}
          handleClick={() => handleQuadrantClick(quadrantBoxDefinition)}
        >
          {
            piecesOnSquare.map((item, pieceIndex) => {
              return (
                <Piece
                  key={`${coordinates},${pieceIndex}`}
                  item={item}
                  readOnly={!dragAndDropEnabled}
                >
                  {renderPieceContent({ item, boxDefinition: quadrantBoxDefinition })}
                </Piece>
              )
            })
          }
        </QuadrantToUse>
      </div>
    );
  }

  const hasFreeUsers = pieces.some((item) => !item.position.y);
  const nineBoxSquareSize = `${zoom}vw`;

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ margin: "0 auto", paddingBottom: 20, position: 'relative' }}>
        {
          !hideZoom &&
          <ZoomButtons
            zoomIn={zoomIn}
            zoomOut={zoomOut}
          />
        }

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginRight: 35,
            paddingTop: hideZoom ? 0 : 50,
          }}
        >
          <VerticalLabel size={nineBoxSquareSize} />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: nineBoxSquareSize + " " + nineBoxSquareSize + " " + nineBoxSquareSize,
              gridTemplateRows: nineBoxSquareSize + " " + nineBoxSquareSize + " " + nineBoxSquareSize,
            }}
          >
            {getRightOrderArray(DEFAULT_QUADRANT_ORDER).map((item, index) => renderSquare(item, index))}
          </div>
          <HorizontalLabel size={nineBoxSquareSize} />

          {
            hasFreeUsers && !hideFreeUsers &&
            <div style={{ width: '100%', paddingLeft: 70 }}>
              <Typography className="whiteText" align="center" style={{ fontWeight: 700, width: '100%', margin: 20 }}>
                {"Con clasificación de desempeño y sin clasificación de potencial"}
              </Typography>
              <div style={{ width: `calc(${nineBoxSquareSize} * 3)`, margin: '0 auto' }}>
                <FreeUsersLabel size={nineBoxSquareSize} />
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: nineBoxSquareSize + " " + nineBoxSquareSize + " " + nineBoxSquareSize,
                  }}
                >
                  {["0,1", "0,2", "0,3"].map((item) => renderSquare(item, null))}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </DndProvider>
  )
};

export default NineBoxChart;