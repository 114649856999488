import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  filters: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_FILTERS:
      return {...state, loading: true};
    case actionTypes.GET_FILTERS_FULFILLED:
      return {...state, loading: false, filters: action.payload.filters };
    case actionTypes.GET_FILTERS_REJECTED:
      return {...state, loading: false};

    case actionTypes.APPLY_FILTERS:
      return {...state, loading: true};
    case actionTypes.APPLY_FILTERS_FULFILLED:
      return {...state, loading: false };
    case actionTypes.APPLY_FILTERS_REJECTED:
      return {...state, loading: false};

    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
}
