import React, { useState } from "react";
import CustomTextField from "@icarius-common/input/textField";
import { valueIsOfType, valueTypes } from "@icarius-utils/types";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { MAX_PIN_LENGTH, MIN_PIN_LENGTH } from "@icarius-utils/constants";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const ConfirmPinDialog = (props) => {

  const {
    open,
    text,
    requiresSelection,
    selectionData,
    handleConfirm,
    handleClose,
  } = props;

  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [selectedData, setSelectedData] = useState("");

  const color = useSelector(getAppColor);

  const handleSubmit = () => {
    try {
      validateInputs(pin);
      handleConfirm(pin, selectedData);
      handleClose();
    } catch (e) {
      setError(e);
    }
  };

  const validateInputs = (pin) => {
    if (requiresSelection && !selectedData) throw getLocalizedErrorString("noNextSigner");
    if (!pin) throw getLocalizedErrorString("noPin");
    if (pin.length < MIN_PIN_LENGTH) throw getLocalizedErrorString("pinMustHaveMinCharacters");
    if (pin.length > MAX_PIN_LENGTH) throw getLocalizedErrorString("pinMustHaveMaxCharacters");
    if (!valueIsOfType(pin, valueTypes.pin)) throw getLocalizedErrorString("pinMustBeAlphanumeric");
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={"xs"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("confirmPin")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          {
            text &&
            <Typography variant={"subtitle1"} className={"whiteText"}>
              {text}
            </Typography>
          }
          <div className={"center-input"}>
            <form noValidate>
              {/* el div que sigue es para que no tire passwords guardados */}
              <div style={{ display: "none" }}>
                <CustomTextField
                  label={""}
                  value={pin}
                  showState
                  color={color}
                  isValid={true}
                  type={"password"}
                  onChange={null}
                  maxWidth={true}
                />
              </div>
              <CustomTextField
                label={getLocalizedString("pin")}
                value={pin}
                showState
                isValid={valueIsOfType(pin, valueTypes.pin)}
                type={"password"}
                onChange={value => {
                  setPin(value);
                  setError("");
                }}
                maxWidth={true}
              />
              {
                requiresSelection &&
                <>
                  <Typography className="whiteText" style={{ marginTop: 20 }}>
                    {'Este tipo de documento requiere múltiples aprobadores o firmantes.'}
                  </Typography>
                  <Typography className="whiteText" style={{ marginTop: 20 }}>
                    {'Seleccione quien será el siguiente firmante que también deberá aprobar este documento luego que sea firmado por usted.'}
                  </Typography>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel required id={`label`}>{"Siguiente firmante"}</InputLabel>
                    <Select
                      value={selectedData}
                      labelId={`label`}
                      id={`select`}
                      onChange={(e) => setSelectedData(e.target.value)}
                      margin={"none"}
                    >
                      {
                        selectionData.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </>
              }
            </form>
          </div>
          {
            error &&
            <Typography variant={"subtitle1"} className={"error-text"}>
              {error}
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ConfirmPinDialog;