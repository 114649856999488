import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { executeProcessesByPathAction, getProcessesByPathAction } from "./actions";
import { getProcessList, getIsLoading } from "./selectors";
import { CLEAR_PROCESSES_BY_PATH } from "./actionTypes";

const useProcessesByPath = (ignore, pathToUseForProcess, processByPathParameter = [], addCodemps) => {

  const [selectedCodemps, setSelectedCodemps] = useState(null);
  const [selectedCodes, setSelectedCodes] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [paramsToUse, setParamsToUse] = useState(null);
  const [paramDialogIsOpen, setParamDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const pathToUse = ignore ? "" : (pathToUseForProcess || location.pathname.replace('/', ''));

  const everyProcessList = useSelector(getProcessList);
  const isLoading = useSelector(getIsLoading);
  const userData = useSelector(getUserData);
  const processList = everyProcessList?.[pathToUse];

  useEffect(() => {
    if (userData.level !== "C" && pathToUse) {
      dispatch(getProcessesByPathAction(pathToUse));
    }

    return () => {
      // limpiar los procesos de ese path
      dispatch({
        type: CLEAR_PROCESSES_BY_PATH,
        payload: { path: pathToUse },
      })
    };
  }, [dispatch, userData.level, pathToUse])

  const handleCloseParamDialog = () => {
    setParamDialogIsOpen(false);
    setParamsToUse(null);
    setSelectedProcess(null);
    setSelectedCodes(null);
    setSelectedCodemps(null);
  }

  const executeProcess = (param, codes, codemps, process) => {
    const processToUse = process || selectedProcess;
    const codesToUse = codes || selectedCodes;
    const codempsToUse = codemps || selectedCodemps;

    dispatch(executeProcessesByPathAction({
      codes: codesToUse,
      process: processToUse.key,
      parameters: param || processByPathParameter,
      codemps: codempsToUse,
    })).then(() => {
      handleCloseParamDialog();
    })
  }

  const handleSelectCodesAndProcess = (gridRef, process, codeFieldName = 'Código de empleado') => {
    const selectedRows = gridRef?.api?.getSelectedRows();

    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro de la grilla', severity: 'warning' }));
      return;
    }

    const codes = selectedRows?.map(item => item[codeFieldName]);
    const codemps = addCodemps ? selectedRows?.map(item => item.codemp) : null;

    setSelectedCodes(codes);
    setSelectedCodemps(codemps);
    setSelectedProcess(process);

    dispatch(openDialogAction({
      title: "Atención",
      msg: `¿Está seguro que desea ejecutar el proceso: ${process.value}?`,
      onConfirm: () => handleVerifyParamOrExecute(codes, codemps, process),
      onCancel: () => handleCloseParamDialog(),
    }));
  }

  const handleVerifyParamOrExecute = (codes, codemps, process) => {
    // verificar si el proceso seleccionado tiene parámetros
    if (process?.parameters?.length) {
      setParamsToUse(process.parameters);
      setParamDialogIsOpen(true);
      return;
    }

    // si no tiene parametros, ejecutar
    executeProcess(null, codes, codemps, process);
  }

  const processesByPathData = {
    processList,
    paramsToUse,
    paramDialogIsOpen,
    isLoading,
  }

  const processesByPathFunctions = {
    handleSelectCodesAndProcess,
    handleCloseParamDialog,
    executeProcess,
  }

  return {
    processesByPathData,
    processesByPathFunctions,
  }
}

export default useProcessesByPath;
