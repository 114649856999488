import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import ReactApexChart from 'react-apexcharts';
import { PRIZE_TYPES } from "@icarius-pages/recognitionPlans/constants";
import { getHistoryData } from "@icarius-common/medalTable/selectors";
import { formatNumberExactDecimals } from "@icarius-utils/format";
const CHART_SIZE = 130;

const CurrentRecognition = (props) => {

    const {
        pointsEarned,
        pointsRemaining,
        type,
        dialog,
        handleOpenTimeline,
    } = props;

    const color = useSelector(getAppColor);
    const historyData = useSelector(getHistoryData);

    const chartData = {
        id: 'medalTableDonutGraph',
        series: [pointsEarned, pointsRemaining],
        options: {
            chart: {
                type: 'donut',
                height: CHART_SIZE,
            },
            colors: [color, '#b0b0b0'],
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            stroke: {
                show: false,
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    labels: {
                        show: false,
                    },
                    donut: {
                        size: "80%"
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: false,
            }
        }
    };

    return (
        <div style={{ margin: '10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div style={{ width: CHART_SIZE, display: 'inline-block', position: 'relative' }}>
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type={chartData.options.chart.type}
                    height={chartData.options.chart.height}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    {React.cloneElement(PRIZE_TYPES[type].icon(), { height: 40 })}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginRight: 20 }}>
                <Typography className="whiteText">
                    Reconocimiento en progreso:
                </Typography>
                {
                    [
                        {
                            color: color,
                            value: pointsEarned,
                            text: 'Puntos acumulados: ',
                        },
                        {
                            color: '#b0b0b0',
                            value: pointsRemaining,
                            text: 'Te faltan: ',
                        },
                    ].map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: 10, height: 10, borderRadius: 2, background: item.color, flexShrink: 0 }} />
                                <Typography className="whiteText" style={{ marginLeft: 10 }}>
                                    {item.text} <span style={{ fontWeight: 600, fontSize: 18 }}>{formatNumberExactDecimals(item.value, 0)}</span>
                                </Typography>
                            </div>
                        )
                    })
                }
                {
                    Boolean(historyData?.length) && dialog &&
                    <Typography
                        onClick={handleOpenTimeline}
                        className="whiteText"
                        style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 600, paddingTop: 10 }}>
                        Ver línea de tiempo
                    </Typography>
                }
            </div>
        </div>
    )
}

export default CurrentRecognition;
