import React, { useMemo } from "react";
import ProfileImg from "@icarius-common/profileImg";

const UsersImageArray = ({ users }) => {

    const usersToShow = useMemo(() => {
        return (users.length < 1 ? [] : users.length > 15 ? users.slice(0, 15) : users);
    }, [users]);

    return (
        <div style={{ display: "flex", height: 20, overflow: "hidden" }}>
            {
                usersToShow.map((user, index) => {
                    const employee = users.find(employee => employee.key === user.key);
                    const profileImgToUse = Boolean(employee && employee.image !== "" && employee.image !== "-") ? employee.image : "";
                    return (
                        <div key={index} style={{ position: "relative", left: `-${5 * index}px` }}>
                            <ProfileImg
                                image={profileImgToUse}
                                size={20}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default UsersImageArray;
