import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import {
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { SearchIcon } from "@icarius-icons";
import UserSection from "./userSection";

const UserSelectionColumn = (props) => {

  const {
    employees,
    filterPlaceholder,
    loggedUserIsDisabled,
    handleClick,
  } = props;

  const [searchValue, setSearchValue] = useState("");

  const { theme } = useSelector(getTheme);

  const filteredEmployees = useMemo(() => {
    if (!searchValue) return employees;

    return [...employees].filter((item) => {
      return item.name && item.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [employees, searchValue])

  return (
    <Grid container direction="row" item xs={12} md={6}>
      <Grid item xs={12} style={{ paddingBottom: 20 }}>
        <div style={{ display: "flex", alignItems: "center", height: 32 }}>
          <TextField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={filterPlaceholder || "Buscar colaborador"}
            style={{ borderBottom: `1px solid ${theme === "dark" ? "#747474" : "#949494"}` }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <UserSection
          employees={filteredEmployees}
          handleClick={handleClick}
          loggedUserIsDisabled={loggedUserIsDisabled}
          isSelection={true}
        />
      </Grid>
    </Grid>
  );
}

export default UserSelectionColumn;
