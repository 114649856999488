import React from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "@material-ui/core";
import { openAudioPlayerAction } from "@icarius-common/audioPlayer/actions";
import { PlayCircleOutlineIcon } from "@icarius-icons";

const PlayButton = ({ audioName, title }) => {

  const dispatch = useDispatch();

  const openAudioPlayer = (audioName, title) => {
    if (!audioName || !title) return;
    dispatch(openAudioPlayerAction(audioName, title));
  }

  return (
    <div>
      <IconButton
        style={{ padding: 0 }}
        size={"medium"}
        disableRipple
        onClick={() => openAudioPlayer(audioName, title)}
      >
        <PlayCircleOutlineIcon className="whiteText" />
      </IconButton>
    </div>
  )
}

export default PlayButton;