import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { withStyles } from "@material-ui/core/styles";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { confirmReceiptToServer, confirmCertificateToServer } from "../actions";

const CustomButtonYes = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#16520b",
    },
    backgroundColor: "#268a15",
    marginRight: 10,
    color: "white",
  },
})(Button);

const CustomButtonNo = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#871313",
    },
    backgroundColor: "#df1b1b",
    color: "white",
  },
})(Button);

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const ConfirmationDialog = (props) => {

  const {
    open,
    isCertificate,
    document,
    process,
    handleClose,
    classes,
  } = props;

  const [decided, setDecided] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [text, setText] = useState("");

  const dispatch = useDispatch();

  const textIsInvalid = () => {
    return !text || text.length > 250;
  };

  const validateAndConfirm = () => {
    if (textIsInvalid()) {
      dispatch(openSnackbarAction({ msg: "Debe ingresar el motivo de disconformidad", severity: "error", duration: 10000 }));
      return;
    }

    handleSubmit();
  };

  const handleSubmit = () => {
    let answer = {
      code: isCertificate ? "CERT" : document.code,
      reference: isCertificate ? document.code : process.reference,
      accepted: !isRejected,
    };

    if (isRejected) {
      answer.text = text;
    }

    dispatch(isCertificate ? confirmCertificateToServer(answer) : confirmReceiptToServer(answer))
      .then((res) => handleClose(res));
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString(isCertificate ? "myCertificatesConfirmationTitle" : "myReceiptsConfirmationTitle")}
          <DialogTitleDivider />
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {
              !decided &&
              <Grid container item xs={12} justify="center">
                <CustomButtonYes
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {getLocalizedString("yes")}
                </CustomButtonYes>
                <CustomButtonNo
                  variant="contained"
                  onClick={() => {
                    setIsRejected(true);
                    setDecided(true);
                  }}
                >
                  {getLocalizedString("no")}
                </CustomButtonNo>
              </Grid>
            }
            {
              decided && isRejected &&
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={getLocalizedString(isCertificate ? "confirmCertificateReceipt" : "confirmReceipt")}
                  variant="outlined"
                  multiline
                  rows={3}
                  margin={"none"}
                  value={text}
                  onChange={(e) => setText(e.target.value.slice(0, 250))}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                  inputProps={{ spellCheck: 'false' }}
                />
                <Typography
                  style={{ paddingTop: 5 }}
                  className={textIsInvalid() ? "errorColor" : "whiteText"}
                  variant="caption"
                  gutterBottom>
                  {`${getLocalizedString("currentChar")} ${text.length}. ${getLocalizedString("maxCharAnnex")}.`}
                </Typography>
              </Grid>
            }
          </Grid>
          {
            decided && isRejected &&
            <DialogActions>
              <ButtonDialogAction
                type="submit"
                onClick={() => {
                  setIsRejected(false);
                  setDecided(false);
                  setText("");
                }}
                text={getLocalizedString("disagree")}
              />
              <ButtonDialogAction
                isAccept
                onClick={validateAndConfirm}
                text={getLocalizedString("submit")}
              />
            </DialogActions>
          }
        </DialogContent>
      </div>
    </Dialog >
  );
}

export default withStyles(styles)(ConfirmationDialog);