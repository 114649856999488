import React from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getFileExtension } from "@icarius-utils/fileUpload";

const FileInput = (props) => {

  const {
    id, // para pantallas con mas de un input, cada input con id distinto arregla bug
    acceptedFormats,
    label,
    extraLabel,
    handleChange,
    multiple,
    disabled,
    preSubmitCallback,
  } = props;

  const dispatch = useDispatch();

  const filesAreValid = (files) => files.every((file) => acceptedFormats.includes(`.${getFileExtension(file.name)}`.toLowerCase()));

  const submitFiles = (event) => {
    preSubmitCallback && preSubmitCallback();

    const fileArray = Array.from(event.target.files);
    event.target.value = '';

    if (!filesAreValid(fileArray)) {
      dispatch(openSnackbarAction({
        msg: 'Hay archivos de formato inválido',
        severity: "error",
      }));
      return;
    }

    handleChange(fileArray);
  }

  const inputId = id || 'fileInput';

  return (
    <div>
      <input
        disabled={disabled}
        type="file"
        id={inputId}
        style={{ display: "none" }}
        multiple={multiple}
        onChange={(e) => submitFiles(e)}
        accept={acceptedFormats.join(',')}
      />
      <label htmlFor={inputId}>
        <Typography
          className="whiteText"
          style={{
            textDecoration: disabled ? "" : "underline",
            cursor: disabled ? "" : "pointer",
          }}
        >
          {label || 'Seleccionar archivo'}
        </Typography>
      </label>
      <div style={{ marginTop: 10 }}>
        {
          extraLabel &&
          <Typography className="whiteText" variant="subtitle2">
            {extraLabel}
          </Typography>
        }
        <Typography className="whiteText" variant="subtitle2">
          {`Formatos válidos: ${acceptedFormats.join(', ')}`}
        </Typography>
      </div>
    </div>
  )
}

export default FileInput;
