import React from "react";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";

const Answer = ({ option, amount, total }) => {

  const { theme } = useSelector(getTheme);

  const opacity = theme === 'dark' ? 0.4 : 0.1;
  const answerPercentage = amount * 100 / total;

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0px' }}>
      <div style={{ position: 'relative', width: '100%' }}>
        <Typography className="whiteText" style={{ fontSize: 18, padding: '6px 12px', position: 'relative', zIndex: 1 }}>
          {option}
        </Typography>
        <div
          style={{
            width: `${answerPercentage || 0.5}%`,
            height: '100%',
            position: 'absolute',
            top: 0,
            background: 'black',
            opacity: opacity,
            borderRadius: 8,
          }}
        />
      </div>
      <Typography className="whiteText" style={{ fontSize: 18, fontWeight: 600, marginLeft: 20 }}>
        {formatNumberExactDecimals(answerPercentage, 0)}%
      </Typography>
    </div>
  );
}

export default Answer;