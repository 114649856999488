import NAME from "./constants";

export const GET = NAME + "/GET";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";
export const GET_REJECTED = NAME + "/GET_REJECTED";

export const MODIFY = NAME + "/MODIFY";
export const MODIFY_FULFILLED = NAME + "/MODIFY_FULFILLED";
export const MODIFY_REJECTED = NAME + "/MODIFY_REJECTED";

export const RESET = NAME + "/RESET";