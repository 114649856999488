import React, { useState } from "react";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import StyledHelpMenu from "@icarius-common/styledMenu";

const SelectProcessByPathButton = ({ processList = [], onClick }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  if (processList.length === 0) return null;

  if (processList.length === 1) {
    return (
      <CustomIconButton
        title={`Ejecutar proceso: ${processList[0].value}`}
        onClick={() => onClick(processList[0])}
        type={"processByPath"}
      />
    )
  };

  return (
    <>
      <CustomIconButton
        title={'Ejecutar proceso'}
        type={"processByPath"}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <StyledHelpMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {
          processList.map(item => {
            return (
              <MenuItemWithIcon
                key={item.key}
                text={item.value}
                onClick={() => {
                  onClick(item);
                  setAnchorEl(null);
                }}
              />
            )
          })
        }
      </StyledHelpMenu>
    </>
  );
}

export default SelectProcessByPathButton;
