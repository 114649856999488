import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Tooltip, Typography } from "@material-ui/core";
import KanbanContext from "./context";
import Column from "./column";

const Kanban = (props) => {

    const {
        data,
        columnDefinition,
        handleChange,
        renderPieceContent,
        getIsDisabled,
        getAllowedDestinationColumns,
        height,
    } = props;

    const [columnsDisabled, setColumnsDisabled] = useState([]);

    const addItemAndReorder = (arrayToReorder, newItem, newItemIndex) => {
        const firstSubarray = [...arrayToReorder].splice(0, newItemIndex);
        const lastSubarray = [...arrayToReorder].splice(newItemIndex);
        return [...firstSubarray, newItem, ...lastSubarray];
    };

    const moveToDestination = (destination, draggableId) => {
        const taskToMove = [...data].find((task) => task.code === draggableId);
        const dataWithoutTask = [...data].filter((task) => task.code !== draggableId);

        const destinationColumnId = destination.droppableId;
        const otherColumnsTasks = dataWithoutTask.filter((task) => task.status !== destinationColumnId);
        const destinationColumnTasks = dataWithoutTask.filter((task) => task.status === destinationColumnId);

        // poner task en destination en el orden en que se lo soltó
        const reorderedDestinationColumnData = addItemAndReorder(
            destinationColumnTasks,
            { ...taskToMove, status: destinationColumnId },
            destination.index
        );

        handleChange([
            ...otherColumnsTasks,
            ...reorderedDestinationColumnData,
        ]);
    }

    const handleDragEnd = ({ source, destination, draggableId }) => {
        setColumnsDisabled([]);

        if (!destination) return; // se soltó afuera
        if (source.droppableId === destination.droppableId && source.index === destination.index) return; // se soltó en el mismo lugar donde estaba

        const destinationColumn = columnDefinition.find((column) => column.code === destination.droppableId);

        if (Boolean(destinationColumn.confirmation)) {
            destinationColumn.confirmation(() => moveToDestination(destination, draggableId));
            return;
        }

        moveToDestination(destination, draggableId);
    };

    const handleDragStart = ({ draggableId }) => {
        // busco las columnas a las que me puedo mover
        const columnsEnabled = getAllowedDestinationColumns(draggableId);

        // obtengo las que no estan incluidas
        const newColumnsDisabled = columnDefinition.filter((item) => !columnsEnabled.includes(item.code)).map((item) => item.code);
        setColumnsDisabled(newColumnsDisabled);
    }

    return (
        <KanbanContext.Provider
            value={{
                renderPieceContent,
                getIsDisabled,
            }}
        >
            <DragDropContext
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >
                <div
                    style={{
                        overflowX: "auto",
                        // margin: "0 -10px", // para sacarle el border de afuera al table
                    }}
                >
                    <table
                        style={{
                            height: 100,  // hay que setear un height para que el Droppable de cada columna tome el 100%
                            borderSpacing: "10px 0px",
                            paddingBottom: 10,
                            width: "100%",
                        }}
                    >
                        <thead
                            style={{
                                position: "sticky",
                                top: 0,
                                height: 60,
                                background: "var(--mainBackgroundColor)",
                            }}
                        >
                            <tr>
                                {
                                    columnDefinition.map((column) => {
                                        return (
                                            <th
                                                key={column.code}
                                                style={{
                                                    width: `${100 / columnDefinition.length}%`,
                                                    minWidth: 250,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        background: "var(--secondaryBackgroundColor)",
                                                        borderRadius: 10,
                                                        display: "flex",
                                                        padding: "10px 14px",
                                                    }}
                                                >
                                                    <Tooltip title={column.name}>
                                                        <Typography
                                                            className="whiteText"
                                                            style={{
                                                                fontSize: 14,
                                                                textTransform: "uppercase",
                                                            }}
                                                            noWrap
                                                        >
                                                            {column.name}
                                                        </Typography>
                                                    </Tooltip>
                                                </div>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody
                            style={{
                                height: "60vh" || height, // funciona como minHeight
                            }}
                        >
                            <tr>
                                {
                                    columnDefinition.map((column) => {
                                        const columnItems = data.filter((item) => item.status === column.code);

                                        return (
                                            <td
                                                key={column.code}
                                                style={{
                                                    width: `${100 / columnDefinition.length}%`,
                                                    minWidth: 250,
                                                    verticalAlign: "top",
                                                    background: "var(--secondaryBackgroundColor)",
                                                    padding: "10px 14px",
                                                    borderRadius: 10,
                                                }}
                                            >
                                                <Column
                                                    id={column.code}
                                                    disabled={columnsDisabled.includes(column.code)}
                                                    data={columnItems}
                                                />
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </DragDropContext>
        </KanbanContext.Provider>
    );
}

export default Kanban;