import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  historyData: [],
  reasonsList: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        historyData: action.payload.historyData,
        reasonsList: action.payload.reasonsList,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
}
