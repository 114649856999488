import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Loader from "@icarius-common/loader";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import useVisitPage from "@icarius-utils/hooks/useVisitPage";
import paths from "@icarius-localization/paths";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "./confirmationDialog";
import useHandleMyDocuments from "./useHandleMyDocument";
import useHandleForm from "./useHandleForm";

const MyCertificatesAndReceiptsDialog = (props) => {

  const {
    open,
    isCertificate,
    isHistorical,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const {
    documentList,
    receiverList,
    isLoading,
    selectedDocument,
    selectedProcess,
    confirmDialogIsOpen,
    handleGenerate,
  } = useHandleMyDocuments(isCertificate, isHistorical, handleClose);

  const handleSubmit = (dataToGenerate) => {
    handleGenerate(dataToGenerate.document, dataToGenerate.process, dataToGenerate.receiver);
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    requiresReceiverSelection,
    setFormValue,
    submit,
  } = useHandleForm(isCertificate, isHistorical, handleSubmit, openValidationError, documentList);

  useVisitPage(isCertificate ? paths.myCertificates : paths.myReceipts);

  return (
    <>
      <Dialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}
        maxWidth={"sm"}
        fullWidth={true}>
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {getLocalizedString(isCertificate ? "myCertificateFullTitle" : isHistorical ? "myHistoricalReceiptsFullTitle" : "myReceiptsFullTitle")}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent>
            <Loader open={isLoading} />
            <Grid container justify="center" alignItems="center" spacing={2}>
              {
                isHistorical &&
                <Grid item xs={12}>
                  <Typography align="justify" className="whiteText">
                    {'Los procesos que se visualizan corresponden únicamente a aquellos en los cuales usted ya tiene aceptado el comprobante de pago.'}
                  </Typography>
                  <Typography align="justify" className="whiteText">
                    {'Si no visualiza un proceso en específico es porque aún no se encuentra disponible, o bien porque usted aún no acepta el comprobante de pago de dicho proceso. Si este fuera el caso, acceda a "Mis Recibos" para aceptar los documentos pendientes.'}
                  </Typography>
                </Grid>
              }
              <Grid item xs={12}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-typeOfDocument`}>
                    {"Tipo de documento"}
                  </InputLabel>
                  <Select
                    value={formData.document}
                    labelId={`label-typeOfDocument`}
                    id={`typeOfDocument`}
                    onChange={(e) => setFormValue(e.target.value, "document")}
                    margin={"none"}
                  >
                    {
                      documentList?.map((item, index) => (
                        <MenuItem
                          className={"whiteText"}
                          key={index}
                          value={isCertificate ? item.code : item} // si no es certificados, se setea el objeto entero porque el code no es unico
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              {
                Boolean(isCertificate && formData.document && requiresReceiverSelection) &&
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel required id={`label-receiver`}>
                      {"Destinatario"}
                    </InputLabel>
                    <Select
                      value={formData.receiver}
                      labelId={`label-receiver`}
                      id={`receiver`}
                      onChange={(e) => setFormValue(e.target.value, "receiver")}
                      margin={"none"}
                    >
                      {
                        receiverList?.map((item) => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}
                          >
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              }
              {
                Boolean(!isCertificate && formData.document) &&
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel required id={`label-selectCalcMethod`}>
                      {getLocalizedString("selectCalcMethod")}
                    </InputLabel>
                    <Select
                      value={formData.process}
                      labelId={`label-selectCalcMethod`}
                      id={`selectCalcMethod`}
                      onChange={(e) => setFormValue(e.target.value, "process")}
                      multiple={isHistorical}
                      margin={"none"}
                    >
                      {
                        formData.document?.calculation_processes?.map((item, index) => (
                          <MenuItem
                            className={"whiteText"}
                            key={index}
                            value={item.reference}
                          >
                            {item.name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
            <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("generate")} />
          </DialogActions>
        </div>
      </Dialog>
      {
        confirmDialogIsOpen &&
        <ConfirmationDialog
          open={confirmDialogIsOpen && !isLoading}
          isCertificate={isCertificate}
          document={selectedDocument}
          process={selectedProcess}
          handleClose={handleClose}
        />
      }
    </>
  );
}

export default MyCertificatesAndReceiptsDialog;