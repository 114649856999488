import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { CheckBoxIcon } from "@icarius-icons";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";

const ImageGrid = (props) => {

  const { images, indexSelected, handleImageClick } = props;
  const color = useSelector(getAppColor);

  return (
    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
    {
      images.map((image, index) =>
        <div
          key={index}
          style={{
            width: 230,
            height: 150,
            position: "relative",
            cursor: "pointer",
            border: index === indexSelected ? `solid 2px ${color}` : "",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          { 
            index === indexSelected
            ? <CheckBoxIcon style={{ fill: color, position: "absolute", bottom: 20, right: 20 }} />
            : null
          }
          <img
            style={{ margin: 5, maxWidth: 220, maxHeight: 140 }} 
            onClick={() => handleImageClick(index)}
            src={IMAGES_ENDPOINT + image.img}
            alt={"imageNotFound"}
          />
        </div>
      )
    }
    </div>
  )
}

ImageGrid.propTypes = {  
  indexSelected: PropTypes.number,
  images: PropTypes.array.isRequired,
  handleImageClick: PropTypes.func.isRequired,
};

export default ImageGrid;
