import NAME from "./constants";

export const GET_IMAGES = NAME + "/GET_IMAGES";
export const GET_IMAGES_FULFILLED = NAME + "/GET_IMAGES_FULFILLED";
export const GET_IMAGES_REJECTED = NAME + "/GET_IMAGES_REJECTED";

export const UPLOAD_IMAGE = NAME + "/UPLOAD_IMAGE";
export const UPLOAD_IMAGE_FULFILLED = NAME + "/UPLOAD_IMAGE_FULFILLED";
export const UPLOAD_IMAGE_REJECTED = NAME + "/UPLOAD_IMAGE_REJECTED";

export const DELETE_IMAGE = NAME + "/DELETE_IMAGE";
export const DELETE_IMAGE_FULFILLED = NAME + "/DELETE_IMAGE_FULFILLED";
export const DELETE_IMAGE_REJECTED = NAME + "/DELETE_IMAGE_REJECTED";

export const RESET_GALLERY = NAME + "/RESET_GALLERY";