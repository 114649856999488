import { useMemo, useState, useEffect } from "react";
import BoardLogic from "./index";

const useBoardLogic = (initialData, onChange) => {

	const boardLogic = useMemo(() => new BoardLogic(initialData), [initialData]);

	const [pieces, setPieces] = useState(boardLogic.positions);

	useEffect(() => {
		boardLogic.observe((args) => {
			setPieces(args);
			onChange && onChange(args);
		})
	}, [boardLogic, onChange]);

	return {
		boardLogic,
		pieces,
	}
}

export default useBoardLogic;

