import React from "react";
import { Divider } from "@material-ui/core";

const SectionDivider = () => (
  <div style={{ width: "100%" }}>
    <Divider style={{ height: 1, background: '#b0b0b0' }} />
  </div>
)

export default SectionDivider;
