import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Loader from "@icarius-common/loader";
import { Progress } from "@icarius-common/circularProgress";
import { getGridResultDialogIsLoading } from "@icarius-common/gridProcessResultDialog/selectors";
import SetParamDialog from "@icarius-common/setParamDialog";
import SelectProcessByPathButton from "@icarius-common/processesByPath/components/selectProcessByPathButton";
import useProcessesByPath from "@icarius-common/processesByPath/useProcessesByPath";
import AgGridContainer from "./agGridContainer";
import Toolbar from "./toolbar";
import useForceRender from "@icarius-utils/hooks/useForceRender";

const CommonPage = (props) => {

  const [amountOfGroups, setAmountOfGroups] = useState(0);
  const localAgGridRef = useRef(null);

  const gridResultDialogIsLoading = useSelector(getGridResultDialogIsLoading); // el loading de la grilla de resultados que está en el Drawer

  const {
    /* Generales */
    title, // titulo de la Pagina
    audioName, // nombre del audio para el playbutton del titulo
    subtitle, // indica que hay subtitulo
    isLoading, // para el Loading
    hideLoading, // no muestra el loading aunque isLoading este en true
    children, // Contenido de la pagina, si necesita recibir la refToUse, debe ser function
    customHeader, // si tiene algun componente propio que va entre el titulo y el GridMenu, SIEMPRE debe mandarse como function

    // Relacionado con el agGrid 
    dateFormat, // dateFormat para el agGrid
    locale, // locale para el agGrid
    hideGrid, // para esconder la grilla pero que siga existiendo
    isNotGridPage, // para no utilizar todo lo relativo al agGrid
    rowData, // data del agGrid
    handleRowClick, // metodo a ejecutar por el agGrid, si no se manda la prop, al tocar en la grilla no pasa nada
    ownColumnDef, // en caso de que la grilla no use filterBySocietyAndPage, y haya definiciones propias    
    columnDefPage, // nombre de la pagina para buscar la definicion de columnas de filterBySocietyAndPage para el agGrid
    gridTitle, // titulo de la grilla al exportarla
    frameworkComponents,
    gridHeight, // opcional, setear altura de la grilla
    onFilterChanged, // accion a ejecutar cuando cambia filtro del aggrid
    onRowDataChanged, //accion a ejecutar cuando cambia la data
    onFirstDataRendered, //accion a ejecutar cuando se renderiza primera vez
    taskControl, // valor para el setTaskControl
    hiddenHeader, // valor para el setHiddenHeader

    // Para GridMenu
    helpItems, // objeto o array con items para el boton de Ayuda
    menuItems, // array con componentes propios para insertar en el GridMenu. TODOS los items deben mandarse como function
    hasExpand, // si tiene Expandir/Contraer los grupos del agGrid
    hasSelectAll, // si tiene para resaltar todas las filas del agGrid
    hasHelp, // si tiene el menu de ayuda
    hasSwitch, // tiene switch para alternar contenido
    handleSwitchClick, // en caso de que se use un handle puntual. Si no, se usara handleGenericSwitchClick, que es el utilizado por MyPeople
    switchLabelOff, // string cuando el switch esta apagado (Si hasSwitch es true y no se manda esta prop, hay string por defecto)
    switchLabelOn, // string cuando el switch esta prendido (Si hasSwitch es true y no se manda esta prop, hay string por defecto)
    switchValue, // estado en que se encuentra el Switch (true o false)   

    ownGridRef, // ref por si necesito acceso a la grila por fuera de los children

    // Para processByPath
    addCodemps, // bool para ver si mandar los codemps en processByPath
    codeFieldName, // nombre del campo code que tenga la grilla (se usa para la ejecución de procesos segun el path)
    ignoreProcessesbyPath, // para que no ejecute el hook de useProcessesByPath
    pathToUseForProcess, // para override y no usar el path de la pantalla
    processByPathParameter, // para override el parameter que se manda en el processByPath si este no trae param
  } = props;

  const {
    processesByPathData,
    processesByPathFunctions,
  } = useProcessesByPath(ignoreProcessesbyPath || isNotGridPage, pathToUseForProcess, processByPathParameter, addCodemps);

  useForceRender(rowData); // para garantizar que el gridRef este asociado a la grilla desde el primer render

  const agGridRefToUse = ownGridRef || localAgGridRef;

  const processByPathButton = (gridRef) => {
    return (
      <SelectProcessByPathButton
        processList={processesByPathData.processList}
        onClick={(process) => processesByPathFunctions.handleSelectCodesAndProcess(gridRef, process, codeFieldName)}
      />
    )
  }

  return (
    <section id={"my-people-page"} style={{ width: '100%' }}>
      {
        !hideLoading &&
        <Loader open={isLoading || processesByPathData.isLoading || gridResultDialogIsLoading} />
      }
      <Progress />
      <section
        id={"people-table-wrapper"}
        style={{
          backgroundColor: isNotGridPage ? "var(--mainBackgroundColor)" : "",
          overflow: isNotGridPage ? "hidden" : "auto",
        }}
      >
        <Toolbar
          title={title}
          audioName={audioName}
          subtitle={subtitle}
          customHeader={customHeader}
          menuItems={menuItems}
          processByPathButton={processByPathButton}
          helpItems={helpItems}
          amountOfGroups={amountOfGroups}
          hasExpand={hasExpand}
          hasSelectAll={hasSelectAll}
          hasHelp={hasHelp}
          hasSwitch={hasSwitch}
          handleSwitchClick={handleSwitchClick}
          switchLabelOff={switchLabelOff}
          switchLabelOn={switchLabelOn}
          switchValue={switchValue}
          agGridRef={agGridRefToUse}
        />
        {
          !isNotGridPage &&
          <div style={{ display: hideGrid ? "none" : "block" }}>
            <AgGridContainer
              ref={agGridRefToUse}
              columnDefPage={columnDefPage}
              rowData={rowData}
              ownColumnDef={ownColumnDef}
              gridTitle={gridTitle}
              dateFormat={dateFormat}
              locale={locale}
              frameworkComponents={frameworkComponents}
              height={gridHeight}
              onFilterChanged={onFilterChanged}
              onRowDataChanged={onRowDataChanged}
              onFirstDataRendered={onFirstDataRendered}
              handleRowClick={handleRowClick}
              setAmountOfGroups={setAmountOfGroups}
              taskControl={taskControl}
              hiddenHeader={hiddenHeader}
            />
          </div>
        }
        {typeof children === 'function' ? children(agGridRefToUse.current) : children}
        {
          processesByPathData.paramDialogIsOpen &&
          <SetParamDialog
            open={processesByPathData.paramDialogIsOpen}
            params={processesByPathData.paramsToUse}
            handleClose={processesByPathFunctions.handleCloseParamDialog}
            handleAgree={processesByPathFunctions.executeProcess}
          />
        }
      </section>
    </section>
  );
}

export default CommonPage;