import React, { useState } from "react";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import CustomTextField from "@icarius-common/input/textField";
import { changePasswordAction } from "src/app/actions";
import { valueIsOfType, valueTypes } from "@icarius-utils/types";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { MAX_PIN_LENGTH, MIN_PIN_LENGTH } from "@icarius-utils/constants";
import { CloseIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@material-ui/core";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const initialState = {
  currentPin: "",
  newPin: "",
  newPinConfirmation: "",
  error: "",
};

const ChangePinDialog = (props) => {

  const {
    open,
    onClose,
    hideClose,
  } = props;

  const [state, setState] = useState(initialState);

  const {
    currentPin,
    newPin,
    newPinConfirmation,
    error,
  } = state;

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const handleTextChange = (name, value) => setState(prev => ({ ...prev, [name]: value, error: "" }));

  const handleConfirm = () => {
    try {
      validateInputs();
      dispatch(changePasswordAction(currentPin, newPin))
        .then(handleClose)
        .catch((e) => {
          setState(prev => ({ ...prev, error: getErrorStringFromError(e) }));
        });
    } catch (e) {
      setState(prev => ({ ...prev, error: e }));
    }
  };

  const validateInputs = () => {
    if (!currentPin) throw getLocalizedErrorString("noCurrentPin");
    if (!newPin) throw getLocalizedErrorString("noNewPin");
    if (!newPinConfirmation) throw getLocalizedErrorString("noNewPinConfirm");

    if (newPin !== newPinConfirmation) {
      setState(prev => ({ ...prev, newPinConfirmation: "" }));
      throw getLocalizedErrorString("pinsMustBeEqual");
    }

    if (currentPin === newPin) throw getLocalizedErrorString("pinsMustBeDifferent");
    if (newPin.length < MIN_PIN_LENGTH) throw getLocalizedErrorString("pinMustHaveMinCharacters");
    if (newPin.length > MAX_PIN_LENGTH) throw getLocalizedErrorString("pinMustHaveMaxCharacters");
    if (!valueIsOfType(newPin, valueTypes.pin)) throw getLocalizedErrorString("pinMustBeAlphanumeric");
  };

  const handleClose = () => {
    setState(initialState);
    onClose && onClose();
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={"xs"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            {getLocalizedString("changingPin")}
            <PlayButton audioName={"Cambiar mi PIN"} title={"Cambiar mi PIN"} />
          </div>
          <DialogTitleDivider />
        </DialogTitle>
        {
          !hideClose &&
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        }
        <DialogContent>
          <div>
            <TextField
              fullWidth
              required
              type="text"
              label="user"
              style={{ opacity: "0%", width: 1, height: 1, position: "absolute", left: 0, top: 0 }}
            />
            <CustomTextField
              label={getLocalizedString("currentPin")}
              value={currentPin}
              type={"password"}
              showState
              color={color}
              isValid={valueIsOfType(currentPin, valueTypes.pin)}
              onChange={value => handleTextChange("currentPin", value)}
              maxWidth={true}
              noAutoComplete
            />
          </div>
          <div className={"center-input"}>
            <CustomTextField
              label={getLocalizedString("newPin")}
              value={newPin}
              type={"password"}
              showState
              color={color}
              isValid={valueIsOfType(newPin, valueTypes.pin) && newPin !== currentPin}
              onChange={value => handleTextChange("newPin", value)}
              maxWidth={true}
              noAutoComplete
            />
          </div>
          <div>
            <CustomTextField
              label={getLocalizedString("newPinConfirmation")}
              value={newPinConfirmation}
              type={"password"}
              showState
              color={color}
              isValid={valueIsOfType(newPinConfirmation, valueTypes.pin) && newPinConfirmation === newPin}
              onChange={value => handleTextChange("newPinConfirmation", value)}
              maxWidth={true}
              noAutoComplete
            />
          </div>
          {
            Boolean(error) &&
            <Typography variant={"subtitle1"} className={"error-text"}>
              {error}
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          {
            !hideClose &&
            <ButtonDialogAction
              onClick={handleClose}
              text={getLocalizedString("disagree")}
            />
          }
          <ButtonDialogAction
            onClick={handleConfirm}
            isAccept
            text={getLocalizedString("agree")}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ChangePinDialog;