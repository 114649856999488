import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  DialogContent,
  Dialog,
  DialogTitle,
  Tooltip,
  IconButton,
  TextField,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { RoomIcon, LinkIcon, AttachFileIcon, CloseIcon, DeleteIcon } from "@icarius-icons";
import { getAppColor } from "src/app/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getFileExtension, getFileName, makeFile } from "@icarius-utils/fileUpload";
import DialogTransition from "@icarius-common/dialogTransition";
import { checkIfUrl } from "@icarius-utils/types";
import { sendMailAction } from "@icarius-pages/myPeople/actions";
import TextEditor from "@icarius-common/textEditor";
import UsersImageArray from "./usersImageArray";

const SendMailDialog = (props) => {

  const {
    open,
    employees,
    groupName,
    handleClose,
    origin,
    customSentToLabel,
  } = props;

  const [text, setText] = useState(""); // se usa solamente para validar que no esté vacio (el htmlText siempre tiene contenido)
  const [htmlText, setHtmlText] = useState("");
  const [link, setLink] = useState("");
  const [coordinates, setCoordinates] = useState("");

  const [linkIsHidden, setLinkIsHidden] = useState(true);
  const [coordIsHidden, setCoordIsHidden] = useState(true);
  const [attachment, setAttachment] = useState("");
  const [attachmentName, setAttachmentName] = useState("");

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const toggleLinkIsHidden = () => {
    setLinkIsHidden(prev => !prev);
    setLink('');
  }

  const toggleCoordIsHidden = () => {
    setCoordIsHidden(prev => !prev);
    setCoordinates('');
  }

  const coordIsValid = () => {
    if (coordIsHidden) return true;
    const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
    return regex.test(coordinates);
  }

  const linkIsValid = () => {
    if (linkIsHidden) return true;
    return link && link !== "" && checkIfUrl(link);
  }

  const handleSubmit = () => {
    if (!text || !coordIsValid() || !linkIsValid()) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error" }));
    } else {
      const dataToSend = {
        message: htmlText,
        users: employees.map((user) => user.key),
        attachmentName: attachment ? getFileName(attachmentName) : "",
        attachment,
        link,
        coordinates,
        origin,
        groupName,
      };

      dispatch(sendMailAction(dataToSend))
        .then((res) => {
          if (res?.status === 200) {
            handleClose();
          }
        });
    }
  }

  const handleUploadFile = (e) => {
    let originalFile = e.target.files[0];
    if (!originalFile) {
      setAttachment("");
      return;
    }

    let fileExtension = getFileExtension(originalFile.name);

    if (originalFile.size > 5 * 1024 * 1024) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("mailInvalidFileSize"), severity: "error" }));
      return;
    }

    if (fileExtension === "exe") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("mailInvalidFileType"), severity: "error" }));
      return;
    }

    makeFile(originalFile, (base64) => {
      setAttachment(base64);
    })

    setAttachmentName(originalFile.name);
  }

  const handleDeleteFile = () => {
    setAttachment("");
    setAttachmentName("");
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("sendMessage")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingBottom: 0 }}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <TextEditor
              initialValue={""}
              placeholder={getLocalizedString("mailTextPlaceholder")}
              suggestionOptions={employees.map((item) => ({ ...item, img: item.image }))}
              handleChange={(value, htmlValue) => {
                setText(value);
                setHtmlText(htmlValue);
              }}
            />
          </Grid>
          {
            !linkIsHidden &&
            <Grid item xs={12} style={{ paddingTop: 15 }}>
              <TextField
                fullWidth
                required
                margin={"none"}
                label={"Link"}
                value={link}
                onChange={(e) => setLink(e.target.value)}
                helperText={'Debe ser una url válida.'}
              />
            </Grid>
          }
          {
            !coordIsHidden &&
            <Grid item xs={12} style={{ paddingTop: 15 }}>
              <TextField
                fullWidth
                required
                margin={"none"}
                label={"Coordenadas"}
                value={coordinates}
                onChange={(e) => setCoordinates(e.target.value)}
                helperText={getLocalizedString("mailCoordInvalid")}
              />
            </Grid>
          }
          {
            attachmentName &&
            <Grid container item xs={12} alignItems="center" style={{ paddingTop: 25 }}>
              <Tooltip title={getLocalizedString("delete")} placement="bottom">
                <IconButton
                  style={{ maxHeight: 20, maxWidth: 20, margin: 10 }}
                  onClick={handleDeleteFile}
                  component={"span"}
                  className="w-32 h-32 mx-4 p-0">
                  <DeleteIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                </IconButton>
              </Tooltip>
              <Typography style={{ fontSize: "0.9rem " }} className="whiteText" variant="caption">
                {attachmentName}
              </Typography>
            </Grid>
          }
          <Grid container item xs={12} style={{ paddingTop: 15 }}>
            <input
              type="file"
              name="attachment"
              id="fileInputUploadMailMyPeople"
              style={{ display: "none" }}
              onChange={(e) => handleUploadFile(e)}
            />
            <Tooltip title={getLocalizedString("mailUploadFile")}>
              <label htmlFor="fileInputUploadMailMyPeople">
                <IconButton
                  component={"span"} // sin esto no anda el click del input de archivo
                >
                  <AttachFileIcon fontSize="small" style={{ fill: "var(--mainText)" }} />
                </IconButton>
              </label>
            </Tooltip>
            <Tooltip title={getLocalizedString(linkIsHidden ? "mailLinkText" : "mailUndoLinkText")}>
              <IconButton onClick={toggleLinkIsHidden}>
                <LinkIcon fontSize="small" style={{ fill: !linkIsHidden ? color : "var(--mainText)" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={getLocalizedString(coordIsHidden ? "mailCoordText" : "mailUndoCoordText")}>
              <IconButton onClick={toggleCoordIsHidden}>
                <RoomIcon fontSize="small" style={{ fill: !coordIsHidden ? color : "var(--mainText)" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid container style={{ padding: "5px 24px" }} alignItems="center" justify="space-between">
        <Grid container item direction="column" sm={6} style={{ paddingTop: "15px", paddingBottom: 10 }}>
          <Typography className="whiteText" style={{ marginBottom: 5 }}>
            {
              customSentToLabel ||
              getLocalizedString("mailMyPeopleWillBeSent").replace("{amount}", employees.length)
            }
          </Typography>
          <UsersImageArray users={employees} />
        </Grid>
        <Grid container item sm={6} justify="flex-end" style={{ paddingTop: "15px" }}>
          <div style={{ marginRight: 10 }}>
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          </div>
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("mailSend")} />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default SendMailDialog;