import React from "react";
import { Typography } from "@material-ui/core";
import { PRIZE_TYPES } from "@icarius-pages/recognitionPlans/constants";

const Award = ({ icon, value }) => {
    return (
        <div style={{ margin: 10 }}>
            {React.cloneElement(icon(), { height: 40 })}
            <Typography className="whiteText" align="center">
                {value}
            </Typography>
        </div>
    )
}

const Won = ({ data }) => {

    const hasNone = Object.keys(data).every((item) => !Boolean(data[item]));

    return (
        <div style={{ margin: '10px 0px' }}>
            <Typography className="whiteText">
                Ganados:
            </Typography>
            {
                hasNone ?
                    <Typography className="whiteText" style={{ marginTop: 10, fontWeight: 600 }}>
                        ¡Vamos por tu primer medalla!
                    </Typography>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                        {
                            Boolean(data.goldShield) && // si tiene escudo de oro, va primero
                            <Award
                                icon={PRIZE_TYPES.goldShield.icon}
                                value={data.goldShield}
                            />
                        }
                        {
                            Object.keys(data)
                                .map((item, index) => {
                                    if (!data[item] || item === 'goldShield') return null;
                                    return (
                                        <Award
                                            key={index}
                                            icon={PRIZE_TYPES[item].icon}
                                            value={data[item]}
                                        />
                                    )
                                })
                        }
                    </div>
            }
        </div>
    )
}

export default Won;
