import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { getFavouriteFunctionsAPI, modifyFavouriteFunctionActionAPI } from "@icarius-connection/api";

export const getFavouriteFunctionsAction = (type) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    const response = await getFavouriteFunctionsAPI(`?type=${type}`);
    const favouriteList = response.data.data;

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { favouriteList, type },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const modifyFavouriteFunctionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyFavouriteFunctionActionAPI(dataToSend);
    let favouriteList = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { favouriteList, type: dataToSend.type },
    });

    dispatch(openSnackbarAction({ msg: 'Modificación exitosa', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};