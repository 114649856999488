import React, { useState } from "react";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { formatDate, formatDateYYYYMM } from "@icarius-utils/date";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { getFileExtension } from "@icarius-utils/fileUpload";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "./snackbar/actions";
import DialogTransition from "./dialogTransition";
import FileInput from "./fileUpload/fileInput";
import FileList from "./fileUpload/fileList";

const SetParamDialog = ({ open, handleClose, params, handleAgree }) => {

  const dispatch = useDispatch();

  const createInitialData = () => {
    const getInitialFieldData = (param) => {
      if (param.is_multiple) {
        return [];
      }

      if (param.type === 'F' || param.type === 'P') {
        return new Date();
      }

      return '';
    }

    return params.reduce((initialData, param) => {
      return ({
        ...initialData,
        [param.name]: getInitialFieldData(param),
      })
    }, {});
  }

  const [values, setValues] = useState(createInitialData());

  const getInputFields = (item) => {
    if (item.items) {
      return (
        <div style={{ marginTop: -10, width: "100%" }}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required={item.is_required} id={`${item.name}-label`}>{item.name}</InputLabel>
            <Select
              fullWidth
              labelId={`${item.name}-label`}
              id={item.name}
              multiple={item.is_multiple}
              value={values[item.name]}
              onChange={(e) => {
                e.persist();
                setValues(prev => ({ ...prev, [item.name]: e.target.value }))
              }}
            >
              {
                item.items.map((subItem) => (
                  <MenuItem
                    key={subItem.code}
                    className={"whiteText"}
                    style={values[item.name].indexOf(subItem.code) > -1 ? { fontWeight: 800 } : { fontWeight: 300 }}
                    value={subItem.code}
                  >
                    {subItem.name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
      );
    }

    switch (item.type) {
      case "I": //archivo
        return (
          <>
            <FileInput
              id={item.code}
              label={item.name}
              acceptedFormats={['.pdf', '.xls', '.xlsx', '.txt', '.dat', '.csv']}
              handleChange={(files) => setValues(prev => ({ ...prev, [item.name]: files[0] }))}
            />
            <FileList
              files={values[item.name] ? [values[item.name]] : []}
              handleClick={() => setValues(prev => ({ ...prev, [item.name]: null }))}
            />
          </>
        )
      case "N": //entero
        return (
          <TextField
            required={item.is_required}
            label={item.name}
            value={values[item.name]}
            onChange={(e) => {
              e.persist();
              setValues(prev => ({ ...prev, [item.name]: e.target.value }))
            }}
            margin={"none"}
            type="number"
            inputProps={{ min: "1", step: "1", onKeyPress: avoidDecimalSeparator }}
            fullWidth
          />
        )
      case "M": // moneda
        return (
          <TextField
            required={item.is_required}
            label={item.name}
            value={values[item.name]}
            onChange={(e) => {
              e.persist();
              setValues(prev => ({ ...prev, [item.name]: e.target.value }))
            }}
            margin={"none"}
            type="number"
            inputProps={{ min: "1", step: "0.1" }}
            fullWidth
          />
        )
      case "F": //fecha
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              required={item.is_required}
              label={item.name}
              clearable
              clearLabel={getLocalizedString("clear")}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=''
              format="dd/MM/yyyy"
              margin="normal"
              fullWidth
              value={values[item.name]}
              onChange={(e) => setValues(prev => ({ ...prev, [item.name]: e }))}
            />
          </MuiPickersUtilsProvider>
        );
      case "P": //periodo
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              required={item.is_required}
              label={item.name}
              clearable
              clearLabel={getLocalizedString("clear")}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              views={["month", "year"]}
              format="yyyy/MM"
              margin="normal"
              invalidDateMessage=''
              fullWidth
              value={values[item.name]}
              onChange={(e) => setValues(prev => ({ ...prev, [item.name]: e }))}
            />
          </MuiPickersUtilsProvider>
        );
      case "A": //alfanumerico
        return (
          <TextField
            required={item.is_required}
            label={item.name}
            margin={"none"}
            type="text"
            value={values[item.name]}
            onChange={(e) => {
              e.persist();
              setValues(prev => ({ ...prev, [item.name]: e.target.value }))
            }}
            fullWidth
          />
        )
      default:
        break;
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const validateAndCreate = async (e) => {
    e.preventDefault();
    if (getDataIsValid()) {
      let finalParams = [];

      //recorrer params, y buscar los tipo F, I y P, obtener el values[param.name] y convertirlos
      for (const param of params) {
        switch (param.type) {
          case "I":
            if (values[param.name]) {
              const base64 = await getBase64(values[param.name]).then(data => data);

              finalParams.push({
                "code": param.code,
                "value": "", //este se manda vacio
                "extension": getFileExtension(values[param.name].name),
                "file": base64,
              });
            }
            break;
          case "F":
            if (values[param.name])
              finalParams.push({ code: param.code, value: formatDate(values[param.name]) });
            break;
          case "P":
            if (values[param.name])
              finalParams.push({ code: param.code, value: formatDateYYYYMM(values[param.name]) });
            break;
          default:
            finalParams.push({ code: param.code, value: values[param.name] });
            break;
        }
      }

      handleAgree(finalParams);
      handleClose();
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
    }
  };

  const avoidDecimalSeparator = (event) => {
    // En caso de que el caracter ingresado sea punto o coma, se escapa el evento
    if (event.charCode === 46 || event.charCode === 44 || event.charCode === 42 || event.charCode === 45 || event.charCode === 43 || event.charCode === 69 || event.charCode === 101) {
      event.preventDefault();
    }
  }

  const getDataIsValid = () => {
    let isValid = true;
    params.forEach((param) => {
      if (param.is_required && !values[param.name])
        isValid = false;
      if (param.type === "M" && values[param.name] < 0)
        isValid = false;
      if (param.type === "F" && values[param.name] && !(values[param.name] instanceof Date && !isNaN(values[param.name])))
        isValid = false;
      if (param.type === "P" && values[param.name] && !(values[param.name] instanceof Date && !isNaN(values[param.name])))
        isValid = false;
      if (param.type === "I" && values[param.name] && !['pdf', 'txt', 'dat', 'csv', 'xls', 'xlsx'].includes(getFileExtension(values[param.name].name).toLowerCase()))
        isValid = false;
    })
    return isValid;
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("parameters")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <form style={{ marginTop: -25 }} onSubmit={validateAndCreate} noValidate>
        <DialogContent>
          {
            params.map((param) => (
              <Grid style={{ minHeight: 75 }} container direction="row" justify="center" key={param.code}>
                <Grid container alignItems="center" item xs={12}>
                  {getInputFields(param)}
                </Grid>
              </Grid>
            ))
          }
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction type="submit" isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default SetParamDialog;