import React from 'react';
import TagBox from 'devextreme-react/tag-box';

export default class EmployeeTagBoxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onValueChanged(e) {
        const newValues = [];
        e.value.forEach(el => newValues.push(this.props.data.column.lookup.dataSource.find(item => item.key === el)));

        this.props.data.setValue(newValues);
    }

    onSelectionChanged() {
        this.props.data.component.updateDimensions();
    }

    render() {
        const formattedData = this.props.data.value ? this.props.data.value.map(item => item.key ? item.key : item) : [];
        return <TagBox
            dataSource={this.props.data.column.lookup.dataSource}
            defaultValue={formattedData}
            valueExpr="key"
            displayExpr="value"
            showSelectionControls={true}
            // maxDisplayedTags={3}
            showMultiTagOnly={false}
            onValueChanged={this.onValueChanged}
            onSelectionChanged={this.onSelectionChanged} />;
    }
}
