import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  myCertificateList: null,
  myReceiptsList: null,
  receiverList: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    //My Certificates
    case actionTypes.GET_MY_CERTIFICATES_LIST_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_MY_CERTIFICATES_LIST_ROWS_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.GET_MY_CERTIFICATES_LIST_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        myCertificateList: action.payload.certificates,
        receiverList: action.payload.receiverList,
      };

    case actionTypes.DOWNLOAD_MY_CERTIFICATE:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_MY_CERTIFICATE_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_MY_CERTIFICATE_FULFILLED:
      return { ...state, isLoading: false };

    case actionTypes.CONFIRM_CERTIFICATE:
      return { ...state, isLoading: true };
    case actionTypes.CONFIRM_CERTIFICATE_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.CONFIRM_CERTIFICATE_FULFILLED:
      return { ...state, isLoading: false };

    //My RECEIPTS
    case actionTypes.GET_MY_RECEIPTS_LIST_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_MY_RECEIPTS_LIST_ROWS_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.GET_MY_RECEIPTS_LIST_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        myReceiptsList: action.payload.receipts,
      };
    case actionTypes.DOWNLOAD_MY_RECEIPT:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_MY_RECEIPT_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_MY_RECEIPT_FULFILLED:
      return { ...state, isLoading: false };

    case actionTypes.CONFIRM_RECEIPT:
      return { ...state, isLoading: true };
    case actionTypes.CONFIRM_RECEIPT_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.CONFIRM_RECEIPT_FULFILLED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
