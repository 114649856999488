import React from "react";
import { DialogContent } from "@material-ui/core";
import { createAggridColumn } from "@icarius-table/utils";
import RatingRenderer from "@icarius-table/renderersAndFilters/ratingRenderer";
import IndicatorRenderer from "@icarius-table/renderersAndFilters/indicatorRenderer";
import RichTextRenderer from "@icarius-table/renderersAndFilters/richTextRenderer";
import NineBoxImageRenderer from "@icarius-table/renderersAndFilters/nineBoxImageRenderer";
import ProgressRenderer from "@icarius-table/renderersAndFilters/progressRenderer";
import ProgressFilter from "@icarius-table/renderersAndFilters/progressFilter";
import CommonPage from "@icarius-common/commonPage";

const GridDialogContent = (props) => {
  const {
    data,
    name,
    filters,
    dateFormat,
    currencyLocalization,
  } = props;

  const createColumnDefs = () => {
    let columnDefs = [];
    for (let prop in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, prop)) {
        let item = createAggridColumn(prop, filters[prop]);
        columnDefs.push(item);
      }
    }
    return columnDefs;
  }

  return (
    <DialogContent>
      <CommonPage
        gridTitle={name}
        ownColumnDef={createColumnDefs()}
        rowData={data}
        dateFormat={dateFormat}
        locale={currencyLocalization}
        gridHeight={'70vh'}
        frameworkComponents={{ RatingRenderer, IndicatorRenderer, RichTextRenderer, NineBoxImageRenderer, ProgressRenderer, ProgressFilter }}
        hiddenHeader={true}
        hasExpand
        hasHelp
        ignoreProcessesbyPath
      />
    </DialogContent>
  );
}

export default GridDialogContent;