import React, { useRef, useState } from "react";
import { DialogTitle, Dialog, DialogContent, Grid } from "@material-ui/core";
import { CloseIcon, PrintIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import paths from "@icarius-localization/paths";
import GridMenu from "@icarius-common/gridMenu";
import PaperDraggable from "@icarius-common/paperDraggable";
import {
  getResult,
  getDateFormat,
  getLocale,
} from "../selectors";
import DialogTransition from "@icarius-common/dialogTransition";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ExtraData from "@icarius-pages/employeesProcesses/components/dialogs/extraData";
import TabMenu from "@icarius-common/tabMenu";
import { useSelector } from "react-redux";
import CommonPage from "@icarius-common/commonPage";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "95%",
  },
})(Dialog);

const ResultGridDialog = (props) => {

  const {
    open,
    process,
    employeeName,
    templates,
    handleGenerateDocumentsGivenTemplate,
    handleOpenPickTemplateDialog,
    handleClose,
  } = props;

  const [optionToShow, setOptionToShow] = useState(0);
  const ownGridRef = useRef();

  const result = useSelector(getResult);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);

  const handleGenerateDocuments = () => {
    if (templates.length === 1) {
      handleGenerateDocumentsGivenTemplate(templates[0].code)
    } else {
      // Tengo que elegir un template
      handleOpenPickTemplateDialog();
    }
  }

  const showGenerateDocumentsItem = () => {
    if (templates.length === 0) return null;

    return (
      <CustomIconButton
        title={"Generar documentos"}
        onClick={handleGenerateDocuments}
      >
        <PrintIcon />
      </CustomIconButton>
    )
  }

  const onRowDataChanged = (params) => {
    let allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    params.columnApi.autoSizeColumns(allColumnIds);
  }

  const data = [
    {
      path: paths.employeesProcessesResults,
      string: "employeesProcessResult",
      data: result.results,
    },
    {
      path: paths.employeesProcessesOthers,
      string: "employeesProcessOthers",
      data: result.others,
    },
    {
      path: paths.employeesProcessesDetails,
      string: "employeesProcessDetail",
      data: result.details,
    },
    {
      path: paths.employeesProcessesReferences,
      string: "employeesProcessReferences",
      data: result.references,
    }
  ][optionToShow];

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {`${getLocalizedString("employeesProcessDialogTitle")}: ${process["Descripción"]} - ${employeeName}`}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container direction="row" alignItems="center" style={{ paddingBottom: 10 }}>
          <TabMenu
            options={[
              getLocalizedString("employeesProcessResult"),
              getLocalizedString("employeesProcessOthers"),
              getLocalizedString("employeesProcessDetail"),
              getLocalizedString("employeesProcessReferences"),
            ]}
            optionSelected={optionToShow}
            onClick={(value) => setOptionToShow(value)}
          />
          <Grid container direction="row" item xs={12} style={{ marginTop: 10 }} justify="space-between" alignItems="center">
            <Grid container item md={12} lg={10}>
              <ExtraData
                shouldHide={optionToShow !== 0}
                locale={locale}
                haberesConAportes={result.total_haberes_con_aportes}
                haberesSinAportes={result.total_haberes_sin_aporte}
                descuentosLegales={result.total_descuentos_legales}
                descuentosVarios={result.total_descuentos_varios}
                totalResult={result.total_result}
              />
            </Grid>
            <Grid container item md={12} lg={2}>
              <GridMenu
                customItems={[showGenerateDocumentsItem]}
                hasHelp
                ref={ownGridRef.current}
              />
            </Grid>
          </Grid>
        </Grid>
        <CommonPage
          ownGridRef={ownGridRef}
          gridTitle={getLocalizedString(data.string)}
          ownColumnDef={getColumnDefByPage(data.path)}
          rowData={data.data}
          dateFormat={dateFormat}
          locale={locale}
          onRowDataChanged={onRowDataChanged}
          gridHeight={'70vh'}
          ignoreProcessesbyPath
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default ResultGridDialog;