import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const FavouriteButton = ({ useAbsolutePosition, type, code, isFavourite, handleChangeFavourite }) => (
  <div style={useAbsolutePosition && { position: 'absolute', top: 20, right: 5, zIndex: 2 }}>
    <CustomIconButton
      title={isFavourite ? 'Quitar de favoritos' : 'Añadir a favoritos'}
      onClick={() => handleChangeFavourite(type, code, !isFavourite)}
    >
      {isFavourite ? <StarIcon /> : <StarBorderIcon />}
    </CustomIconButton>
  </div>
)


export default FavouriteButton;