import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Loader from "@icarius-common/loader";

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const ConsentDialog = (props) => {

  const {
    open,
    data,
    acceptAction,
    previewAction,
    handleClose,
    isLoading,
    readOnly,
    classes,
  } = props;

  const [canAccept, setCanAccept] = useState(!Boolean(data?.file));
  const dispatch = useDispatch();

  const handleSubmit = () => {
    // o no tiene archivo o ya hizo la preview
    if (canAccept) {
      dispatch(acceptAction({ code: data.code }))
        .then((res) => res?.status === 200 && handleClose());
      return;
    }

    //no hizo la preview todavia
    dispatch(previewAction({ code: data.code }))
      .then(() => setCanAccept(true));
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {
            readOnly ? 'Visualización de consentimiento'
              : 'Visualización y aceptación de consentimiento'
          }
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          {
            Boolean(data.acceptDate) &&
            <Grid container item xs={12} style={{ marginBottom: 20 }}>
              <Typography className="whiteText">
                Fecha de aceptación: <b>{data.acceptDate}</b>
              </Typography>
            </Grid>
          }
          <Grid container item xs={12}>
            <TextField
              disabled
              fullWidth
              label="Texto del consentimiento"
              variant="outlined"
              multiline
              rows={20}
              margin={"none"}
              value={data.text}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            !readOnly &&
            <>
              <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
              <ButtonDialogAction onClick={handleSubmit} isAccept text={canAccept ? 'Aceptar' : 'Previsualizar'} />
            </>
          }
          {
            readOnly &&
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("close")} />
          }
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(ConsentDialog);