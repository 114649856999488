import * as actionTypes from "./actionTypes";

const initialState = {
  templates: [],
  isLoadingTemplates: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.GET_TEMPLATES:
      return { ...state, isLoadingTemplates: true };
    case actionTypes.GET_TEMPLATES_FULFILLED:
      return {
        ...state,
        isLoadingTemplates: false,
        templates: action.payload.templates || [],
      };
    case actionTypes.GET_TEMPLATES_REJECTED:
      return { ...state, isLoadingTemplates: false };

    case actionTypes.CREATE_TEMPLATE: {
      return { ...state, isLoadingTemplates: true };
    }
    case actionTypes.CREATE_TEMPLATE_FULFILLED: {
      let auxTemplates = state.templates.length > 0 ? [...state.templates] : [];
      auxTemplates.push(action.payload);
      return {
        ...state,
        templates: auxTemplates,
        isLoadingTemplates: false,
      };
    }
    case actionTypes.CREATE_TEMPLATE_REJECTED: {
      return { ...state, isLoadingTemplates: false };
    }

    case actionTypes.MODIFY_TEMPLATE: {
      return { ...state, isLoadingTemplates: true };
    }
    case actionTypes.MODIFY_TEMPLATE_FULFILLED: {
      let modifiedTemplates = [...state.templates].map((item) => {
        if (item.Code === action.payload.Code) {
          item.U_columnstate = JSON.stringify(action.payload.U_columnstate);
          item.U_filterstate = JSON.stringify(action.payload.U_filterstate);
          item.U_sortstate = JSON.stringify(action.payload.U_sortstate);
          item.U_groupstate = JSON.stringify(action.payload.U_groupstate);
          item.U_pivotstate = JSON.stringify(action.payload.U_pivotstate);
          item.U_selstate = JSON.stringify(action.payload.U_selstate);
          item.U_graphicstate = JSON.stringify(action.payload.U_graphicstate);
        }
        return item;
      });

      return {
        ...state,
        templates: modifiedTemplates,
        isLoadingTemplates: false,
      };
    }
    case actionTypes.MODIFY_TEMPLATE_REJECTED: {
      return { ...state, isLoadingTemplates: false };
    }

    case actionTypes.DELETE_TEMPLATE: {
      return { ...state, isLoadingTemplates: true };
    }
    case actionTypes.DELETE_TEMPLATE_FULFILLED: {
      let arrFinal = [...state.templates].filter(field => field.Code !== action.payload);
      return {
        ...state,
        templates: arrFinal,
        isLoadingTemplates: false,
      };
    }
    case actionTypes.DELETE_TEMPLATE_REJECTED:
      return { ...state, isLoadingTemplates: false };

    default:
      return state;
  }
}
