import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getAssistantVoiceTypeAPI } from "@icarius-connection/api";

export const openAudioPlayerAction = (audioName, title) => (dispatch) => {
  dispatch({
    type: actionTypes.OPEN_AUDIO_PLAYER,
    payload: { audioName, title },
  });
}

export const closeAudioPlayerAction = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE_AUDIO_PLAYER,
  });
}

export const getVoiceGenreAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.SET_VOICE_GENRE });
  try {
    const response = await getAssistantVoiceTypeAPI();
    const assistantVoiceType = response.data.assistantVoiceType;

    dispatch({
      type: actionTypes.SET_VOICE_GENRE_FULFILLED,
      payload: { assistantVoiceType },
    });
    
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SET_VOICE_GENRE_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};