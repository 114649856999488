import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessCodigoReferencia"),
            field: "Código de referencia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessValorNumerico"),
            field: "Valor numérico",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessValorAlfanumerico"),
            field: "Valor alfanumérico",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessValorFecha"),
            field: "Valor fecha",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessComentarios"),
            field: "Comentarios",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessTipoValor"),
            field: "Tipo de valor",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessCodigoAccion"),
            field: "Código de acción",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessObjetoAccion"),
            field: "Objeto de la acción",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessFechaAccion"),
            field: "Fecha de la acción",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.employeesProcessesReferences],
        config: {
            headerName: getLocalizedString("employeesProcessAccionesSQL"),
            field: "Acciones de SQL",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessCodigoConcepto"),
            field: "Código del concepto",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessNombreConcepto"),
            field: "Nombre del concepto",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessAmount"),
            field: "Cantidad",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessPorcentaje"),
            field: "Porcentaje",
            filter: "agTextColumnFilter",
            valueFormatter: numberFormatterMin2,
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessBaseCalculo"),
            field: "Base de cálculo",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessValor"),
            field: "Valor",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessFecha"),
            field: "Fecha",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessValueType"),
            field: "Tipo de valor",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessAnnexComment"),
            field: "Comentario anexo",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: 'Fecha de descuento',
            field: "Fecha de descuento",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessPeriodoImputacion"),
            field: "Período de imputación",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessCentroBeneficio"),
            field: "Centro de costo",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessProyecto"),
            field: "Proyecto",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessDimension2"),
            field: "Dimensión 2",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessDimension3"),
            field: "Dimensión 3",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessDimension4"),
            field: "Dimensión 4",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessDimension5"),
            field: "Dimensión 5",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessCodigoReferencia"),
            field: "Código de referencia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessReferencia1"),
            field: "Referencia 1",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessReferencia2"),
            field: "Referencia 2",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessCantidadConvertida"),
            field: "Cantidad convertida",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessPeriodoReliquidacion"),
            field: "Período de reliquidación",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessMesesReliquidar"),
            field: "Meses a reliquidar",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessEntidad"),
            field: "Entidad",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessTipoComprobante"),
            field: "Tipo de comprobante",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessSerieComprobante"),
            field: "Serie del comprobante",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessNumeroComprobante"),
            field: "Número del comprobante",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesDetails],
        config: {
            headerName: getLocalizedString("employeesProcessOrigen"),
            field: "Origen",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesResults],
        config: {
            headerName: getLocalizedString("employeesProcessConcepto"),
            field: "Concepto",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesResults],
        config: {
            headerName: getLocalizedString("employeesProcessName"),
            field: "Nombre",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesResults],
        config: {
            headerName: getLocalizedString("employeesProcessAmount"),
            field: "Cantidad",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesResults],
        config: {
            headerName: getLocalizedString("employeesProcessBaseCalculo"),
            field: "Base de cálculo",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesResults],
        config: {
            headerName: getLocalizedString("employeesProcessHaberesAportes"),
            field: "Haberes con aportes",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesResults],
        config: {
            headerName: getLocalizedString("employeesProcessDescuentosLegales"),
            field: "Descuentos legales",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesResults],
        config: {
            headerName: getLocalizedString("employeesProcessHaberesSinAporte"),
            field: "Haberes sin aporte",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesResults],
        config: {
            headerName: getLocalizedString("employeesProcessDescuentosVarios"),
            field: "Descuentos varios",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesOthers],
        config: {
            headerName: getLocalizedString("employeesProcessConcepto"),
            field: "Concepto",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesOthers],
        config: {
            headerName: getLocalizedString("employeesProcessName"),
            field: "Nombre",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcessesOthers],
        config: {
            headerName: getLocalizedString("employeesProcessAmount"),
            field: "Cantidad",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesOthers],
        config: {
            headerName: getLocalizedString("employeesProcessBaseCalculo"),
            field: "Base de cálculo",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.employeesProcessesOthers],
        config: {
            headerName: getLocalizedString("employeesProcessValor"),
            field: "Valor",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]