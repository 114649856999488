import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsLoading,
  getAutomaticProcessesIsEnabled,
  getLocale,
  getDateFormat,
  getResult,
  getTemplates,
  getProcess,
  getProcessList,
} from "./selectors";
import {
  getAutomaticProcessesAction,
  executeAutomaticProcessAction,
  viewAutomaticProcessAction
} from "./actions";
import {
  downloadDocumentsGeneratedInServer,
} from "@icarius-pages/employeesProcesses/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useAutomaticProcesses = (ignore) => {

  const [isExecution, setIsExecution] = useState(false);
  const [processSelectionDialogIsOpen, setProcessSelectionDialogIsOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedEmployeeCode, setSelectedEmployeeCode] = useState('');

  const [selectedEmployeeForResult, setSelectedEmployeeForResult] = useState(null);
  const [resultDialogIsOpen, setResultDialogIsOpen] = useState(false);
  const [pickTemplateDialogIsOpen, setPickTemplateDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const automaticProcessesIsEnabled = useSelector(getAutomaticProcessesIsEnabled);
  const processList = useSelector(getProcessList);
  const automaticProcessesIsLoading = useSelector(getIsLoading);
  const locale = useSelector(getLocale);
  const dateFormat = useSelector(getDateFormat);
  const result = useSelector(getResult);
  const templates = useSelector(getTemplates);
  const process = useSelector(getProcess);

  useEffect(() => {
    !ignore && dispatch(getAutomaticProcessesAction());
  }, [dispatch, ignore]);

  const executeProcess = (codes, processSelected) => {
    const employeeCodes = codes || selectedEmployees;
    dispatch(executeAutomaticProcessAction({ employeeCodes, process: processSelected }));
  }

  const handleExecute = (employeeCodes) => {
    if (!processList || !processList.length) {
      dispatch(openSnackbarAction({ msg: 'No se encontraron procesos para ejecutar', severity: "warning" }));
      return;
    }

    if (processList.length > 1) {
      setSelectedEmployees(employeeCodes);
      setProcessSelectionDialogIsOpen(true);
      setIsExecution(true);
      return;
    }

    // si hay uno solo, tomarlo por defecto
    executeProcess(employeeCodes, processList[0].key);
  }

  const handleCloseProcessDialogSelection = () => {
    setProcessSelectionDialogIsOpen(false);
    setSelectedEmployees([]);
    setIsExecution(false);
  }

  const viewResult = (employeeCode, process) => {
    dispatch(viewAutomaticProcessAction((employeeCode || selectedEmployeeCode), process))
      .then((resp) => {
        if (resp.data.status === 'NO_PROCESS_ONLINE') {
          setSelectedEmployeeForResult(null);
          return;
        }
        setResultDialogIsOpen(true);
      });
  }

  const handleViewResult = (employee, employeeCode) => {
    if (!processList || !processList.length) {
      dispatch(openSnackbarAction({ msg: 'No se encontraron procesos para ejecutar', severity: "warning" }));
      return;
    }

    setSelectedEmployeeForResult(employee)
    setSelectedEmployeeCode(employeeCode);
    
    if (processList.length > 1) {
      setProcessSelectionDialogIsOpen(true);
      setIsExecution(false);
      return;
    }

    viewResult(employeeCode, processList[0].key);
  }

  const handleCloseResultDialog = () => {
    setResultDialogIsOpen(false);
    setSelectedEmployeeForResult(null);
    setSelectedEmployeeCode('');
  }

  const resultData = {
    employee: selectedEmployeeForResult,
    locale,
    dateFormat,
    result,
    templates,
    process,
  }

  const handleOpenPickTemplateDialog = () => {
    setPickTemplateDialogIsOpen(true);
  }

  const handleClosePickTemplateDialog = () => {
    setPickTemplateDialogIsOpen(false);
    handleCloseResultDialog();
  }

  const handleGenerateDocumentsGivenTemplate = (template) => {
    const code = selectedEmployeeForResult["Código de empleado"];
    dispatch(downloadDocumentsGeneratedInServer(template, code, process["Código de proceso"]));
  }

  return {
    isExecution,
    automaticProcessesIsLoading,
    automaticProcessesIsEnabled,
    processList,
    resultData,
    resultDialogIsOpen,
    pickTemplateDialogIsOpen,
    processSelectionDialogIsOpen,
    executeProcess,
    handleExecute,
    viewResult,
    handleViewResult,
    handleCloseProcessDialogSelection,
    handleCloseResultDialog,
    handleOpenPickTemplateDialog,
    handleClosePickTemplateDialog,
    handleGenerateDocumentsGivenTemplate,
  };
}

export default useAutomaticProcesses;
