import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const AddRowButton = (props) => { //debe recibir como props los campos del current de la ref asociada a la grilla editable {...gridRef.current}
  const addRow = () => {
    props.refs.dataGrid._instance.addRow()
  }

  return (
    <CustomIconButton
      title={"Crear"}
      onClick={addRow}
      type={"add"}
    />
  );
};

export default AddRowButton;
