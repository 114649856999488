import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CloseIcon, DeleteIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { getGalleryImages } from "../selectors";
import ImageGrid from "./imageGrid";
import ImageInput from "./imageInput";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const Container = ({ children }) => (
  <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
    {children}
  </div>
)

const GalleryDialog = (props) => {

  const {
    open,
    imagePreselected,
    handleClose,
    handleSubmit,
    handleUploadImage,
    handleDeleteUserImage,
  } = props;

  const [indexSelected, setIndexSelected] = useState(imagePreselected);
  const images = useSelector(getGalleryImages);

  const submit = () => {
    handleSubmit(indexSelected);
    handleClose();
  }

  return (
    <Dialog open={open} TransitionComponent={DialogTransition} PaperComponent={PaperDraggable} fullWidth maxWidth={"lg"} >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("groupImagesTitle")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <ImageGrid
          images={images}
          indexSelected={indexSelected}
          handleImageClick={(index) => setIndexSelected(prev => prev !== index ? index : null)}
        />
      </DialogContent>
      <div style={{ margin: "40px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Container>
          <ImageInput handleUploadImage={handleUploadImage} />
          {
            Boolean(indexSelected !== null && images[indexSelected].canDelete) &&
            <IconButton onClick={() => handleDeleteUserImage(images[indexSelected].img)} >
              <DeleteIcon fontSize="small" />
            </IconButton>
          }
        </Container>
        <Container>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("save")} />
        </Container>
      </div>
    </Dialog>
  )
}

export default GalleryDialog;
