import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  gridIsOpen: false,
  gridData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.QUERY:
      return { ...state, isLoading: true };
    case actionTypes.QUERY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        gridIsOpen: true,
        gridData: action.payload.gridData,
      };
    case actionTypes.QUERY_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.CLEAR_QUERY:
      return {
        ...state,
        gridIsOpen: false,
        gridData: null,
      };

    default:
      return state;
  }
}
