import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  completed: false,
  open: false,
  showAll: false,
  isNPS: false,
  isAnonymous: false,
  title: '',
  type: '',
  code: '',
  file: '',
  questions: [],
  answers: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_POLL:
      return { ...initialState, isLoading: true };
    case actionTypes.GET_POLL_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.GET_POLL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        open: true,
        code: action.payload.code,
        title: action.payload.title,
        type: action.payload.type,
        completed: action.payload.completed,
        questions: action.payload.questions,
        showAll: action.payload.showAll,
        isNPS: action.payload.isNPS,
        isAnonymous: action.payload.isAnonymous,
        file: action.payload.file,
        answers: action.payload.answers,
      };

    case actionTypes.ANSWER_POLL:
      return { ...state, isLoading: true };
    case actionTypes.ANSWER_POLL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        answers: action.payload.answers,
      };
    case actionTypes.ANSWER_POLL_REJECTED:
      return initialState;

    case actionTypes.CLOSE_POLL:
      return { ...state, open: false };

    default:
      return state;
  }
}
