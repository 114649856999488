import * as actionTypes from "./actionTypes";
import {
  getMedalTableAPI,
  updateMedalTableAPI,
  updateMedalTableAdjustmentAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch, hideErrorMessage) => {
  if (hideErrorMessage) return;
  let errorString = "";
  switch (e.response?.data?.status) {
    case "NOT_ACTIVE":
      errorString = 'El colaborador se encuentra inactivo dentro del plan de reconocimientos, no es posible entregarle puntos.';
      break;
    case "NO_PLAN_MATCH":
      errorString = 'El colaborador no tiene asociado el plan indicado';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getMedalTableAction = (userCode, planCode, hideErrorMessage) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let params = new URLSearchParams();

    params.set("userCode", userCode);
    if (planCode) {
      params.set("planCode", planCode);
    }

    let response = await getMedalTableAPI(`?${params.toString()}`);

    let data = response.data && response.data.data;
    let reasonsList = response.data && response.data.reasonsList;
    let historyData = response.data && response.data.historyData;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        historyData,
        reasonsList,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch, hideErrorMessage);
  }
};

export const updateMedalTableAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateMedalTableAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: 'Puntos entregados con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateMedalTableAdjustmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateMedalTableAdjustmentAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: 'Ajuste realizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};