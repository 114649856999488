import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { CloseIcon } from "@icarius-icons/index";
import {
  getIsOpen,
  getMessage,
  getTitle,
  getIsAcceptOnly,
  getAcceptText,
  getOnConfirm,
  getOnCancel,
  getMaxWidth,
  getFullWidth,
  getChildren,
} from "../selectors";
import { closeDialogAction } from "../actions";

const CustomDialog = () => {

  const dispatch = useDispatch();

  const open = useSelector(getIsOpen);
  const title = useSelector(getTitle);
  const msg = useSelector(getMessage);
  const children = useSelector(getChildren);
  const acceptOnly = useSelector(getIsAcceptOnly);
  const onConfirm = useSelector(getOnConfirm);
  const onCancel = useSelector(getOnCancel);
  const acceptText = useSelector(getAcceptText);
  const maxWidth = useSelector(getMaxWidth);
  const fullWidth = useSelector(getFullWidth);

  const handleCancel = () => {
    dispatch(closeDialogAction);
    if (onCancel) {
      onCancel();
    }
  }

  const handleConfirm = () => {
    dispatch(closeDialogAction);
    if (onConfirm) {
      onConfirm();
    }
  }

  return (
    <Dialog
      open={open}
      scroll={"paper"}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleCancel} />
        <DialogContent>
          {
            Boolean(msg) &&
            <Typography className="whiteText" variant="h6" style={{ whiteSpace: 'pre-line' }}>
              {msg}
            </Typography>
          }
          {children}
        </DialogContent>
        <DialogActions>
          {
            !Boolean(acceptOnly) &&
            <ButtonDialogAction
              text={getLocalizedString("disagree")}
              onClick={handleCancel}
            />
          }
          {
            (Boolean(onConfirm) || Boolean(acceptOnly)) &&
            <ButtonDialogAction
              text={acceptText || getLocalizedString("agree")}
              onClick={handleConfirm}
              isAccept
            />
          }
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default CustomDialog;