import React from "react";
import NumberFormat from 'react-number-format';

const CustomNumberFormat = (props) => {

    const {
        inputRef,
        onChange,
        decimals,
        ...other
    } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            isNumericString
            fixedDecimalScale
            decimalScale={decimals || 0}
        />
    );
}

export default CustomNumberFormat;