import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';

const DotsMenu = (props) => {

    const {
        icon,
        children,
        noPadding,
        disabled,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (e) => {
        if (disabled) return;
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null)
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleOpen}
                style={{
                    padding: noPadding ? 0 : "",
                    cursor: disabled ? "auto" : "pointer",
                }}
            >
                {icon || <MoreVertIcon style={{ fill: "var(--icons)" }} />}
            </IconButton>
            <Menu
                id="long-menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                onClick={handleClose}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: { minWidth: '20ch' }
                }}
            >
                {children}
            </Menu>
        </div>
    );
}

export default DotsMenu;