import { CLOSE_DIALOG, OPEN_DIALOG } from "./actionTypes";

const initialState = {
  open: false,
  title: '',
  msg: '',
  children: null,
  onConfirm: null,
  onCancel: null,
  acceptOnly: false,
  acceptText: '',
  maxWidth: 'sm',
  fullWidth: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        open: true,
        title: action.payload.title,
        msg: action.payload.msg,
        children: action.payload.children,
        onConfirm: action.payload.onConfirm,
        onCancel: action.payload.onCancel,
        acceptOnly: action.payload.acceptOnly,
        acceptText: action.payload.acceptText,
        maxWidth: action.payload.maxWidth || initialState.maxWidth,
        fullWidth: action.payload.fullWidth || initialState.fullWidth,
      };
    case CLOSE_DIALOG:
      return initialState;
    default:
      return state;
  }
}
