import React from "react";

const VerticalLabel = ({ size }) => {

  const verticalLabelStyle = {
    transform: "rotate(-180deg)",
    writingMode: "vertical-lr",
    color: "white",
    textAlign: "center",
    height: size,
    width: 25,
    marginRight: 10,
  };

  return (
    <div style={{ display: 'flex' }}>
      <div
        className="whiteText"
        style={{
          ...verticalLabelStyle,
          height: `calc(${size} * 3)`,
        }}
      >
        Potencial
      </div>
      <div>
        <div className="ninebox-high-performance" style={verticalLabelStyle}>
          Alto
        </div>
        <div className="ninebox-medium-performance" style={verticalLabelStyle}>
          Medio
        </div>
        <div className="ninebox-low-performance" style={verticalLabelStyle}>
          Bajo
        </div>
      </div>
    </div>
  )
}

export default VerticalLabel;