import { OPEN_PROGRESS, UPDATE_PROGRESS, CLOSE_PROGRESS } from "./actionTypes";

const initialState = {
  value: 0,
  open: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_PROGRESS:
      return {
        ...state,
        open: true,
        value: action.payload.value,
      };
    case UPDATE_PROGRESS:
      return {
        ...state,
        value: action.payload.value,
      };
    case CLOSE_PROGRESS:
      return { ...state, open: false, value: 0 };
    default:
      return state;
  }
}
