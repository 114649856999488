import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { getAppColor } from "src/app/selectors";
import { createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import 'react-vertical-timeline-component/style.min.css';
import {
    DialogActions,
    Grid,
    Typography,
    MenuItem,
    Select,
    FormControl,
} from "@material-ui/core";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { PRIZE_TYPES } from "@icarius-pages/recognitionPlans/constants";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getReasonsList } from "../selectors";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const titleOptions = {
    E: 'Entrega de puntos',
    A: 'Asignación de puntos',
    D: 'Descuento de puntos',
}

const Timeline = ({ data, handleClose }) => {

    const [year, setYear] = useState("");
    const color = useSelector(getAppColor);
    const reasonsList = useSelector(getReasonsList);

    const years = useMemo(() => {
        const yearsArrayAux = [];

        data.forEach((item) => {
            const itemYear = createDateFromDDMMYYYY(item.date).getFullYear();

            if (!Boolean(yearsArrayAux.find(year => year.key === itemYear))) {
                yearsArrayAux.push({ key: itemYear, value: itemYear });
            }
        })

        return yearsArrayAux;
    }, [data])

    const filteredData = useMemo(() => {
        let auxData = [...data];

        //filtrar segun año seleccionado
        if (year !== "") {
            auxData = auxData.filter((item) => {
                const itemYear = createDateFromDDMMYYYY(item.date).getFullYear();
                const formattedSelectedYear = parseInt(year);
                return itemYear === formattedSelectedYear;
            })
        };

        return auxData;
    }, [data, year])

    if (!data?.length) {
        return (
            <Grid container item direction="column" xs={12} justify={"center"} alignItems="center" style={{ padding: 10 }}>
                <Typography className="whiteText" align="center" style={{ fontSize: 26 }}>
                    {'No se encontraron datos'}
                </Typography>
                <DialogActions>
                    <ButtonDialogAction
                        isAccept
                        onClick={handleClose}
                        text={'Volver'}
                    />
                </DialogActions>
            </Grid>
        )
    }

    return (
        <>
            <Grid container alignItems="center" justify="space-between" style={{ padding: 10 }}>
                <CustomIconButton
                    type={'goBack'}
                    title={'Volver'}
                    onClick={handleClose}
                />
                <FormControl style={{ width: 200, marginLeft: 5 }}>
                    <Select
                        value={year}
                        labelId={`label`}
                        id={`select`}
                        onChange={(e) => setYear(e.target.value)}
                        margin={"none"}
                        displayEmpty
                    >
                        <MenuItem value="">{'Todo el historial'}</MenuItem>
                        {
                            years?.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}>
                                    {item.value}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <VerticalTimeline lineColor={color} layout={'1-column-left'}>
                {
                    filteredData.map((item, index) => {
                        const date = createDateFromDDMMYYYY(item.date);
                        const month = date.getMonth();

                        const formattedValue = formatNumberExactDecimals(item.value, 0);
                        const formattedPointsEarned = formatNumberExactDecimals(item.pointsEarned, 0);

                        const reasonString = reasonsList.find((reasonItem) => reasonItem.key === item.reason)?.value;

                        return (
                            <VerticalTimelineElement
                                key={index}
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: 'var(--medalTableHeader)', color: 'var(--mainText)', boxShadow: 'none' }}
                                contentArrowStyle={{ borderRight: '7px solid  var(--medalTableHeader)' }}
                                date={`${monthNames[month]} - ${formatDate(date)}`}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3 className="vertical-timeline-element-title">
                                        {titleOptions[item.action]}
                                    </h3>
                                    {
                                        Boolean(item.award) &&
                                        <div>
                                            {React.cloneElement(PRIZE_TYPES[item.award].icon(), { height: 40 })}
                                        </div>
                                    }
                                </div>
                                <h3 className="vertical-timeline-element-title" style={{ color: color }}>
                                    {`${formattedValue} puntos`}
                                </h3>
                                <p style={{ margin: 0, fontWeight: 300 }}>
                                    {item.responsibleName}
                                </p>
                                <p style={{ margin: 0, fontWeight: 500, fontStyle: 'italic' }}>
                                    {`Puntos acumulados a la fecha: ${formattedPointsEarned}`}
                                </p>
                                {
                                    Boolean(reasonString) &&
                                    <p style={{ margin: 0, fontWeight: 300 }}>
                                        {`Motivo de la entrega: ${reasonString}`}
                                    </p>
                                }
                            </VerticalTimelineElement>
                        )
                    })
                }
            </VerticalTimeline>
        </>
    );
}

export default Timeline;