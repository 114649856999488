import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Loader from "@icarius-common/loader";
import SectionDivider from "./sections/sectionDivider";
import Timeline from "./timeline";
import { useSelector } from "react-redux";
import { getHistoryData } from "../selectors";

const DialogVariant = (props) => {

    const {
        section1,
        section2,
        section3,
        section4,
        section5,
        isLoading,
        handleClose,
        timelineIsOpen,
        handleCloseTimeline,
    } = props;

    const historyData = useSelector(getHistoryData);

    return (
        <Dialog
            open
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            fullWidth
        >
            <DialogTitle style={{ cursor: 'move', padding: 0 }} id="draggable-dialog-title">
                {section1}
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose(false)} />
            <DialogContent style={{ padding: 0 }}>
                <Loader open={isLoading} />
                {
                    timelineIsOpen ?
                        <Timeline
                            data={historyData}
                            handleClose={handleCloseTimeline}
                        />
                        :
                        <div style={{ margin: "0px 40px" }}>
                            {section2}
                            <SectionDivider />
                            {section3}
                            <SectionDivider />
                            {section4}
                            {section5}
                        </div>
                }
            </DialogContent>
        </Dialog >
    )
}

export default DialogVariant;
