import React from "react";

const withAnimatedMount = Component =>
  (class AnimatedMount extends React.Component {
    render() {
      return (
        <div className="mount-animation">
          <Component {...this.props} />
        </div>
      );
    }
  });

export default withAnimatedMount;
