import React from "react";
import { likeTypes } from "@icarius-utils/constants";
import { LikeIcon } from "@icarius-icons";
import { Typography, IconButton } from "@material-ui/core";
import { useSelector } from 'react-redux'
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";

const Likes = (props) => {

  const {
    value,
    active,
    handleLike,
  } = props;

  const { theme } = useSelector(getTheme);
  const color = useSelector(getAppColor);

  const likes = parseInt(value?.Likes || 0, 10);
  const liked = value?.Opinion === likeTypes.like || false;

  const inactiveColor = theme === "dark" ? "grey" : "#000000";

  const handleClick = () => {
    if (!active || !handleLike) return;
    handleLike(likeTypes.like, liked);
  }

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      <IconButton
        onClick={handleClick}
        style={{
          padding: 0,
          cursor: active ? "pointer" : "default",
        }}
      >
        <LikeIcon style={{ color: liked ? color : inactiveColor }} />
      </IconButton>
      <Typography variant={"subtitle1"} className={"whiteText"}>
        {likes}
      </Typography>
    </div>
  );
}
export default Likes;
