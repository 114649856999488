import { useState, useMemo } from "react";

/*
  hook que recibe array de users, array de keys preseleccionadas, y si es (o no) seleccion simple
  devuelve:
    - array de keys seleccionadas
    - array de objeto de los users de las key seleccionadas
    - array con todos los users con prop isChecked true si matchea con key seleccionada
    - funcion que recibe keys para seleccionar, y si ya esta seleccionada, la quita del array
*/
const useHandleUserSelection = (users, initialData, singleSelection) => {

  const [keysSelected, setKeysSelected] = useState(initialData || []);

  const checkedList = useMemo(() => { // array de usuarios con la prop isChecked (si esta incluido en keysSelected)
    return users.map((user) => {
      return {
        ...user,
        isChecked: keysSelected.includes(user.key),
      }
    });
  }, [users, keysSelected]);

  const handleSelection = (key) => {
    let auxKeysSelected = [...keysSelected];

    //si ya esta en el array borrarlo
    if (auxKeysSelected.includes(key)) {
      auxKeysSelected = auxKeysSelected.filter((item) => item !== key);
    } else { //sino, agregarlo al final del array, o dejar ese elemento solo (si es seleccion simple)
      if (singleSelection) {
        auxKeysSelected = [key];
      } else {
        auxKeysSelected.push(key);
      }
    }

    setKeysSelected(auxKeysSelected);
  }

  return {
    keysSelected,
    checkedList,
    handleSelection,
    resetUsersSelected: () => setKeysSelected([]),
  };
}

export default useHandleUserSelection;
