import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { connect } from "react-redux";
import { confirmDigitalDocumentToServer } from "../actions";
import { withStyles } from "@material-ui/core/styles";
import QR from "@icarius-common/qr";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import SignatureCanvas from 'react-signature-canvas'
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationForm from "./confirmationForm";

const CustomButtonYes = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#16520b",
    },
    backgroundColor: "#268a15",
    marginRight: 10,
    color: "white",
  },
})(Button);

const CustomButtonNo = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#871313",
    },
    backgroundColor: "#df1b1b",
    color: "white",
    marginRight: 10,
  },
})(Button);

const initialState = {
  rejects: false,
  checkedTerms: false,
  drawSignature: false,
  pin: '',
  text: '',
  alreadyPreviewed: false,
};

class ConfirmationDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.sigCanvas = React.createRef();
  }

  clearSignature = () => {
    this.sigCanvas.clear();
    this.setState({ drawSignature: false });
  }

  onBeginDrawing = () => {
    this.setState({ drawSignature: true });
  }

  textIsInvalid = () => {
    if (this.state.rejects && !this.state.text) return true;
    return this.state.text.length > 250;
  };

  validateAndConfirm = () => {
    if (this.textIsInvalid() || !this.state.pin) {
      this.props.dispatch(openSnackbarAction({ msg: "Debe ingresar observaciones para rechazar el documento", severity: "error" }));
      return;
    }

    if (!this.state.alreadyPreviewed) {
      this.props.dispatch(openSnackbarAction({ msg: 'Previsualice el documento antes de proceder a firmarlo', severity: 'warning', duration: 10000 }))
      return;
    }

    this.handleConfirm();
  };

  handleConfirm = () => {
    let answer = {
      docCode: this.props.document,
      accepted: !this.state.rejects,
      password: this.state.pin,
      text: this.state.text,
    }

    if (!this.state.rejects) { //si se acepta, agregar metodo
      answer.method = this.props.approvationMethod.approvationMethod;
    }

    if (answer.accepted && answer.method === "Touch") {
      answer.sign = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');
      answer.method = "Touch";
    }

    this.props.dispatch(confirmDigitalDocumentToServer(answer)).then((res) => this.handleClose(res));
  };

  handleClose = (res) => {
    if (res?.data?.status !== "UNAUTHORIZED") {
      if (!this.state.rejects) {
        this.props.handleDownload(this.props.document);
      }
      this.setState({ ...initialState });
      this.props.handleCloseDialog();
    }
  };

  getCanRefuse = () => {
    const docFound = this.props.myList.find(e => e.docCode === this.props.document)
    return !(docFound && docFound.canRefuse === false);
  }

  getDialogMaxWidth = () => {
    if (this.props.approvationMethod?.approvationMethod === "QR") return "md";
    if (this.props.approvationMethod?.approvationMethod === "Touch") return "sm";
    return "xs";
  }

  getDialogTitle = () => {
    if (this.props.approvationMethod?.approvationMethod === "QR") return getLocalizedString("qrExampleDigitalSignCertificatesReceipt");
    return getLocalizedString("digitalDocumentConfirmationTitle");
  }

  getAcceptRejectButtonIsDisabled = () => {
    if (!this.state.pin || !this.state.checkedTerms) return true;
    return this.props.approvationMethod?.approvationMethod === "Touch" && !this.state.drawSignature;
  }

  render() {
    const { open, approvationMethod, handlePreview } = this.props;
    let canRefuse = this.getCanRefuse();

    return (
      <Dialog
        open={open}
        PaperComponent={PaperDraggable}
        maxWidth={this.getDialogMaxWidth()}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {this.getDialogTitle()}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={this.props.handleCloseDialog} />
          <DialogContent>
            <Grid container justify="center" alignItems="center">
              <Grid container justify="center" alignItems="center" item xs={12} direction={"row"}>
                {
                  approvationMethod?.approvationMethod === "QR" &&
                  <Grid container justify="center" alignItems="center" item xs={12} direction={"row"}>
                    <Grid container justify="center" alignItems="center" item xs={12} direction={"row"}>
                      <Grid item container justify="center" alignItems="center" xs={12} direction={"row"}>
                        <QR value={approvationMethod.nombreFirma + "\n" + approvationMethod.fechaFirma} hideLabel />
                      </Grid>
                      <Grid item container justify="center" alignItems="center" xs={12} direction={"row"}>
                        <Typography style={{ paddingTop: 5 }} className={"whiteText"} gutterBottom>
                          {approvationMethod.nombreFirma}
                        </Typography>
                      </Grid>
                      <Grid item container justify="center" alignItems="center" xs={12} direction={"row"}>
                        <Typography style={{ paddingTop: 5 }} className={"whiteText"} gutterBottom>
                          {approvationMethod.fechaFirma}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container justify="center" alignItems="center" xs={12} direction={"row"}>
                      <Typography
                        style={{ paddingTop: 5, maxHeight: "102px", overflow: "auto", textAlign: "justify" }}
                        className={"whiteText"}
                        gutterBottom
                      >
                        {approvationMethod.disclaimer}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                {
                  approvationMethod?.approvationMethod === "Touch" &&
                  <Grid container justify="center" alignItems="center" item xs={12} direction={"row"}>
                    <Grid item xs={12}>
                      <SignatureCanvas
                        ref={(ref) => { this.sigCanvas = ref }}
                        backgroundColor="#FFFFFF"
                        onBegin={this.onBeginDrawing}
                        canvasProps={{ height: 200, className: 'sigCanvas' }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Button disableRipple={true} variant={"outlined"} onClick={() => this.clearSignature()}>
                        {getLocalizedString("clear")}
                      </Button>
                    </Grid>
                  </Grid>
                }
                <ConfirmationForm
                  handleCheckboxChange={() => this.setState({ checkedTerms: !this.state.checkedTerms })}
                  handleTextChange={(value) => this.setState({ text: value })}
                  handlePinChange={(value) => this.setState({ pin: value })}
                  checked={this.state.checkedTerms}
                  text={this.state.text}
                  pin={this.state.pin}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomButtonYes
              disabled={this.getAcceptRejectButtonIsDisabled()}
              variant="contained"
              onClick={() => this.setState({ rejects: false }, () => this.validateAndConfirm())}
            >
              {'Aceptar y firmar'}
            </CustomButtonYes>
            {
              canRefuse &&
              <CustomButtonNo
                disabled={this.getAcceptRejectButtonIsDisabled()}
                variant="contained"
                onClick={() => this.setState({ rejects: true }, () => this.validateAndConfirm())}
              >
                {'Rechazar'}
              </CustomButtonNo>
            }
            <Button
              className="whiteText"
              variant="contained"
              onClick={() => this.setState({ alreadyPreviewed: true }, handlePreview)}>
              {'Previsualizar'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default connect()(ConfirmationDialog);
