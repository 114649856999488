import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import { hexToRgb } from "@icarius-utils/colors";
import { HOVER_OPACITY, HOVER_OPACITY_LIGHT } from "@icarius-utils/constants";

const useStyles = makeStyles({
  root: (props) => ({
    backgroundColor: props.isAccept ? "var(--buttonAccept)" : "var(--buttonCancel)",
    border: props.isAccept ? "2px solid transparent" : "2px solid #f5f5f5",
    borderRadius: "6px",
    boxShadow: props.isAccept ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : null,
    "&:hover": {
      backgroundColor: hexToRgb(props.color, props.theme === "dark" ? HOVER_OPACITY : HOVER_OPACITY_LIGHT) + "!important",
    },
  }),
});

const ButtonDialogAction = (props) => {

  const {
    fullWidth,
    text,
    onClick,
    isAccept,
    type,
    disabled,
    startIcon,
  } = props;

  const { theme } = useSelector(getTheme);
  const color = useSelector(getAppColor);
  const classes = useStyles({ isAccept, theme, color });

  return (
    <Button
      onClick={onClick}
      fullWidth={fullWidth}
      classes={{
        root: classes.root,
      }}
      disabled={disabled}
      type={type}
      startIcon={startIcon}
      className="whiteText"
      variant={"outlined"}
      disableRipple={true}
    >
      {text}
    </Button>
  );
}

export default ButtonDialogAction;