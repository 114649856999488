import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import ConfirmationDialog from "./confirmationDialog";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@material-ui/core";
import Loader from "@icarius-common/loader";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import useHandleMyDocuments from "./useHandleMyDocument";
import DialogTransition from "@icarius-common/dialogTransition";
import useVisitPage from "@icarius-utils/hooks/useVisitPage";
import paths from "@icarius-localization/paths";

const MyDigitalDocumentsDialog = ({ open, handleCloseDialog }) => {

  const [documentSelected, setDocumentSelected] = useState('');

  const {
    digitalDocuments,
    isLoading,
    approvationMethod,
    document,
    confirmDialogIsOpen,
    handleSubmit,
    handleClose,
    handleDownload,
    handlePreview
  } = useHandleMyDocuments(handleCloseDialog)

  useVisitPage(paths.digitalDocuments);

  return (
    <>
      <Loader open={isLoading} />
      {
        Boolean(digitalDocuments?.length) &&
        <Dialog
          TransitionComponent={DialogTransition}
          PaperComponent={PaperDraggable}
          open={open}
          maxWidth={"sm"}
          fullWidth={true}>
          <div className={"dialog-container"}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              {getLocalizedString("myDigitalDocuments")}
              <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
              <Loader open={isLoading} />
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label`}>{getLocalizedString("selectTypeOfDocument")}</InputLabel>
                    <Select
                      value={documentSelected}
                      labelId={`label`}
                      id={`select`}
                      onChange={(e) => setDocumentSelected(e.target.value)}
                      margin={"none"}
                    >
                      {
                        digitalDocuments?.map((subitem, index) => (
                          <MenuItem
                            className={"whiteText"}
                            key={index}
                            value={subitem.docCode}>
                            {subitem.docListName}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
              <ButtonDialogAction onClick={() => handleSubmit(documentSelected)} isAccept text={"Seleccionar"} />
            </DialogActions>
          </div>
        </Dialog>
      }
      {
        confirmDialogIsOpen &&
        <ConfirmationDialog
          open={confirmDialogIsOpen || !isLoading}
          document={document}
          approvationMethod={approvationMethod}
          handleDownload={handleDownload}
          handlePreview={handlePreview}
          handleCloseDialog={handleClose}
          myList={digitalDocuments}
        />
      }
    </>
  );
}

export default MyDigitalDocumentsDialog;
