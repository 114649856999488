import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import {
  QuestionIcon,
  DeleteIcon,
  MailIcon,
  PersonAdd,
  AddIcon,
  SearchIcon,
  EditIcon,
  SaveIcon,
  FilterButtonIcon,
  FileCopyIcon as DuplicateIcon,
  KeyboardBackspaceIcon as GoBackIcon,
  PieChartIcon as GraphicsIcon,
  GetAppIcon as DownloadIcon,
  PublishIcon as UploadIcon,
  ExcelIcon,
  VisibilityIcon,
  AccountTreeIcon,
  InsertDriveFileIcon,
  TreeOrgChartIcon,
  FilterNoneIcon,
  TodayIcon,
  FunctionsIcon,
  GroupIcon,
  AssessmentIcon,
  PerformanceManagementIcon,
} from "@icarius-icons";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { hexToRgb } from "@icarius-utils/colors";
import { HOVER_OPACITY } from "@icarius-utils/constants";
import CodeIcon from '@material-ui/icons/Code';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { MedalTableIcon } from "@icarius-icons/index";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

const CustomIconButton = (props) => {

  const {
    title,
    onClick,
    type,
    disabled,
    isSelected,
    noBorder,
    children,
  } = props

  const color = useSelector(getAppColor);
  const height = "25px";
  const iconProps = { htmlColor: color, height: height };

  const getContent = () => {
    if (!type && !children) return title;

    if (!type) {
      return React.cloneElement(children, iconProps)
    }

    switch (type) {
      case "calibratePerformance":
        return <PerformanceManagementIcon {...iconProps} />;
      case "assessment":
        return <AssessmentIcon {...iconProps} />;
      case "filter":
        return <FilterButtonIcon color={color} height={height} />;
      case "execute":
        return <FunctionsIcon {...iconProps} />;
      case "delete":
        return <DeleteIcon {...iconProps} />;
      case "duplicate":
        return <DuplicateIcon {...iconProps} />;
      case "bulkDuplicate":
        return <FilterNoneIcon {...iconProps} />;
      case "add":
        return <AddIcon {...iconProps} />;
      case "edit":
        return <EditIcon {...iconProps} />;
      case "save":
        return <SaveIcon {...iconProps} />;
      case "goBack":
        return <GoBackIcon {...iconProps} />;
      case "graphics":
        return <GraphicsIcon {...iconProps} />;
      case "mail":
        return <MailIcon {...iconProps} />;
      case "questionsAnswer":
        return <QuestionAnswerIcon {...iconProps} />;
      case "feedback":
        return <FeedbackIcon {...iconProps} />;
      case "download":
        return <DownloadIcon {...iconProps} />;
      case "upload":
        return <UploadIcon {...iconProps} />;
      case "addPerson":
        return <PersonAdd {...iconProps} />;
      case "search":
        return <SearchIcon {...iconProps} />;
      case "excel":
        return <ExcelIcon {...iconProps} />;
      case "preview":
        return <VisibilityIcon {...iconProps} />;
      case "camera":
        return <CameraAltIcon {...iconProps} />;
      case "hide":
        return <VisibilityOffIcon {...iconProps} />;
      case "analytics":
        return <InsertDriveFileIcon {...iconProps} />;
      case "orgchart":
        return <AccountTreeIcon {...iconProps} />;
      case "treeOrgChart":
        return <TreeOrgChartIcon {...iconProps} />;
      case "calendar":
        return <TodayIcon {...iconProps} />;
      case "processByPath":
        return <CodeIcon {...iconProps} />;
      case "medalTable":
        return <MedalTableIcon {...iconProps} />;
      case "icariusSmart":
        return <AllInclusiveIcon  {...iconProps} />;
      case "group":
        return <GroupIcon color={color} height={height} />;
      case "annotations":
        return <InsertDriveFileIcon color={color} height={height} />;
      case "help":
      default:
        return <QuestionIcon color={color} height={height} />; //usa color, no htmlColor...
    }
  }

  const buttonStyle = {
    minWidth: 56,
    marginRight: 5,
    fill: color,
    backgroundColor: isSelected ? hexToRgb(color, HOVER_OPACITY) : '',
    border: noBorder && '0px',
  };

  return (
    <Tooltip title={title}>
      <Button
        style={buttonStyle}
        variant={"outlined"}
        disabled={disabled}
        onClick={onClick}
      >
        {getContent()}
      </Button>
    </Tooltip>
  )
}

export default CustomIconButton;