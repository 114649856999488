import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { getFilters } from "../selectors";
import { applyFiltersAction } from "../actions";
import { RESET } from "../actionTypes"
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "10px 0px" };

const FiltersDialog = (props) => {

  const {
    open,
    data,
    type,
    code,
    isDisabled,
    handleFetchUpdatedData,
    handleClose,
  } = props;

  const [formData, setFormData] = useState(data);

  const filters = useSelector(getFilters);
  const dispatch = useDispatch();

  const handleCloseAndReset = () => {
    dispatch({ type: RESET });
    handleClose();
  }

  const validateAndCreate = () => {
    const dataToSend = {
      type,
      code,
      filters: formData,
    };

    dispatch(applyFiltersAction(dataToSend))
      .then((resp) => {
        if (resp.status === 200) {
          handleFetchUpdatedData();
          handleCloseAndReset();
        }
      })
  }

  const getLabel = (name) => getLocalizedString(`filter${name}`);

  const handleChange = (fieldName, newValues) => {
    //Si deselecciono todo, setear el valor "-"
    if (newValues.length === 0) {
      setFormData(prev => ({
        ...prev,
        [fieldName]: ["-"],
      }));
      return;
    }

    const hasOnlyNullOption = formData[fieldName].length === 1 && formData[fieldName].includes("-");

    //Si tenia solo "-", y ahora tiene "-" y otros, setear los otros.
    if (hasOnlyNullOption && newValues.length > 1 && newValues.includes("-")) {
      setFormData(prev => ({
        ...prev,
        [fieldName]: newValues.filter((item) => item !== "-"),
      }));
      return;
    }

    //Si no tenia "-" pero ahora si, setear solo el "-".    
    if (!formData[fieldName].includes("-") && newValues.includes("-")) {
      setFormData(prev => ({
        ...prev,
        [fieldName]: ["-"],
      }));
      return;
    }

    //Sino, setear todo.
    setFormData(prev => ({
      ...prev,
      [fieldName]: newValues,
    }));
  }

  if (!filters || (filters && Object.keys(filters).length === 0)) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Filtros"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleCloseAndReset} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="column" style={{ maxHeight: 500, flexWrap: "nowrap" }}>
          {
            filters && Object.values(filters).map((filter, index) => {
              const name = Object.keys(filters)[index];
              return (
                <Grid key={index} container direction="row" justify="center" item style={gridStyle}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-${name}`}>{getLabel(name)}</InputLabel>
                    <Select
                      disabled={isDisabled}
                      multiple
                      value={formData[name]}
                      labelId={`label-${name}`}
                      id={`select-${name}`}
                      onChange={(e) => handleChange(name, e.target.value)}
                      margin={"none"}
                    >
                      {
                        filter?.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              )
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleCloseAndReset} text={getLocalizedString(isDisabled ? "close" : "disagree")} />
        {
          !isDisabled &&
          <ButtonDialogAction onClick={validateAndCreate} isAccept text={getLocalizedString("agree")} />
        }
      </DialogActions>
    </Dialog>
  )
}

FiltersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleFetchUpdatedData: PropTypes.func,
};

export default FiltersDialog;
