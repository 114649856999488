import { useState } from "react";

const useHandleForm = (isCertificate, isHistorical, callback, invalidDataCallback, documentList) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "process" && isHistorical) return [];
      return "";
    }

    const fieldNames = ["document", "receiver", "process"];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  // si es recibo, nunca requiere seleccion
  const requiresReceiverSelection = isCertificate ? Boolean(documentList?.find((item) => item.code === formData.document)?.requiresReceiverSelection) : false;

  const dataIsValid = () => {
    const { document, process, receiver } = formData;

    if (isCertificate) {
      if (!document) return false;

      if (requiresReceiverSelection && !receiver) return false;
    }

    if (!isCertificate && (!document || !process)) return false;

    return true;
  }

  const submit = () => {
    if (dataIsValid()) {
      callback(formData);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "document") {
      setFormData({
        ...formData,
        [fieldName]: value,
        receiver: "",
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    formData,
    requiresReceiverSelection,
    setFormValue,
    submit,
  };
}

export default useHandleForm;