import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  processList: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROCESSES:
      return { ...state, isLoading: true };
    case actionTypes.GET_PROCESSES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        processList: {
          ...state.processList,
          [action.payload.path]: action.payload.processList,
        },
      };
    case actionTypes.GET_PROCESSES_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.EXECUTE_PROCESS:
      return { ...state, isLoading: true };
    case actionTypes.EXECUTE_PROCESS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.EXECUTE_PROCESS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CLEAR_PROCESSES_BY_PATH:
      if(!action.payload.path) return state; // si no se aclara el path no se hace nada
      return {
        ...state,
        processList: {
          ...state.processList,
          [action.payload.path]: [],
        },
      };
    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
