import * as actionTypes from "./actionTypes";
import { UPDATE_POLLS } from "@icarius-pages/home/actionTypes";
import { answerPollAPI, getPollAPI } from "@icarius-connection/api";
import { getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "POLL_ALREADY_COMPLETED":
      errorString = getLocalizedString("alreadyAnswered");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}


export const closePollAction = (dispatch) => dispatch({ type: actionTypes.CLOSE_POLL });

export const openPollAction = (code, title, type) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_POLL });
  try {
    let response = await getPollAPI(`?code=${code}`);

    let questions = response.data.poll && response.data.poll.questions;
    let completed = response.data.poll && response.data.poll.completed;
    let showAll = response.data.poll && response.data.poll.showAll;
    let isNPS = response.data.poll && response.data.poll.isNPS;
    let isAnonymous = response.data.poll && response.data.poll.isAnonymous;
    let file = response.data.poll && response.data.poll.file;
    let answers = response.data.poll && response.data.poll.answers;

    if (!response.data.poll) {
      dispatch({ type: actionTypes.GET_POLL_REJECTED });
      dispatch(openSnackbarAction({ msg: 'No se encontró la encuesta', severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_POLL_FULFILLED,
      payload: {
        code,
        title,
        type,
        questions,
        completed,
        showAll,
        isNPS,
        isAnonymous,
        file,
        answers,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_POLL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const answerPollAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.ANSWER_POLL });
  try {
    let response = await answerPollAPI(data);
    let homeData = response.data && response.data.data;
    let answers = response.data && response.data.answers;

    if (response.data.status === "OK") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("gratitudMessage"), severity: "info" }));
      dispatch({
        type: actionTypes.ANSWER_POLL_FULFILLED,
        payload: { answers },
      });
      dispatch({
        type: UPDATE_POLLS,
        payload: { polls: homeData.polls },
      });
    }

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ANSWER_POLL_REJECTED, error: e });
    errorHandler(e, dispatch);
  }
};
