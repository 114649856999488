import { OPEN_PROGRESS, UPDATE_PROGRESS, CLOSE_PROGRESS } from "./actionTypes";

  export const openProgressAction = () => (dispatch) => {
    dispatch({
      type: OPEN_PROGRESS,
      payload: { value: 0 },
    });
  }

  export const updateProgressAction = (value) => (dispatch) => {
    
    dispatch({
      type: UPDATE_PROGRESS,
      payload: { value: value },
    })

    value === 100 && 
    setTimeout(() => {
      dispatch({ type: CLOSE_PROGRESS })
    }, 500);
  }
  