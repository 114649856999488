import React from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";

const OptionsInput = (props) => {
    const {
        fieldConfig,
        label,
        value,
        handleChange,
        required,
        error,
    } = props;

    return (
        <FormControl
            error={error}
            style={{ width: "100%" }}
        >
            <InputLabel
                id={`label-${fieldConfig.name}`}
                required={required}
            >
                {label}
            </InputLabel>
            <Select
                labelId={`label-${fieldConfig.name}`}
                id={`select-${fieldConfig.name}`}
                value={value}
                onChange={(e) => handleChange(e.target.value, fieldConfig.name)}
                margin={"none"}
            >
                {
                    (fieldConfig?.options || []).map((item) => (
                        <MenuItem
                            className={"whiteText"}
                            key={item.id}
                            value={item.id}
                        >
                            {item.value}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default OptionsInput;