import NAME from "./constants";

export const GET_FILTERS = NAME + "/GET_FILTERS";
export const GET_FILTERS_FULFILLED = NAME + "/GET_FILTERS_FULFILLED";
export const GET_FILTERS_REJECTED = NAME + "/GET_FILTERS_REJECTED";

export const APPLY_FILTERS = NAME + "/APPLY_FILTERS";
export const APPLY_FILTERS_FULFILLED = NAME + "/APPLY_FILTERS_FULFILLED";
export const APPLY_FILTERS_REJECTED = NAME + "/APPLY_FILTERS_REJECTED";

export const RESET = NAME + "/RESET";