import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import { getMedalTableAction } from "../actions";
import {
  getData,
  getIsLoading,
  getReasonsList,
} from "../selectors";
import { RESET } from "../actionTypes";
import User from "./sections/user";
import Plan from "./sections/plan";
import CurrentRecognition from "./sections/currentRecognition";
import Won from "./sections/won";
import Form from "./sections/form";
import DialogVariant from "./dialogVariant";
import CardVariant from "./cardVariant";

const MedalTable = (props) => {

  const {
    userCode,
    planCode,
    dialog,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userCode) {
      dispatch(getMedalTableAction(userCode, planCode, !dialog));
    }

    return () => {
      dispatch({ type: RESET })
    };
  }, [dispatch, userCode, planCode, dialog])

  const [timelineIsOpen, setTimelineIsOpen] = useState(false);

  const data = useSelector(getData);
  const reasonsList = useSelector(getReasonsList);
  const isLoading = useSelector(getIsLoading);
  const userData = useSelector(getUserData);

  if (!data) return null;

  const section1 = (
    <User
      name={data.user.name}
      image={data.user.image}
    />
  );

  const section2 = (
    <Plan
      name={data.planName}
      level={data.level}
      status={data.status}
      pointsToExchange={data.pointsToExchange}
      showPointsToExchange={data.showPointsToExchange}
      dialog={dialog}
    />
  );

  const section3 = (
    <CurrentRecognition
      pointsEarned={data.currentRecognition.pointsEarned}
      pointsRemaining={data.currentRecognition.pointsRemaining}
      type={data.currentRecognition.type}
      handleOpenTimeline={() => setTimelineIsOpen(true)}
      dialog={dialog}
    />
  );

  const section4 = (
    <Won
      data={data.won}
    />
  )

  const section5 = ['M', 'E'].includes(userData.level) && userData.code !== userCode && (
    <Form
      status={data.status}
      userCode={userCode}
      userLevel={userData.level}
      userName={data.user.name}
      pointValue={data.pointValue}
      maxPointsToDiscount={data.currentRecognition.pointsToDiscount}
      maxPointsToAssign={data.currentRecognition.pointsRemaining}
      reasonsList={reasonsList}
      handleCloseDialog={handleClose}
    />
  )

  if (dialog) {
    return (
      <DialogVariant
        section1={section1}
        section2={section2}
        section3={section3}
        section4={section4}
        section5={section5}
        isLoading={isLoading}
        handleClose={handleClose}
        timelineIsOpen={timelineIsOpen}
        handleCloseTimeline={() => setTimelineIsOpen(false)}
      />
    )
  }

  return (
    <CardVariant
      section1={section1}
      section2={section2}
      section3={section3}
      section4={section4}
    />
  )
}

export default MedalTable;
