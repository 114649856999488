import React from "react";
import {
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { MapIcon } from "@icarius-icons/index";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const AddressAdornment = ({ href }) => {

    const color = useSelector(getAppColor);

    return (
        <InputAdornment style={{ cursor: "pointer" }}>
            <span className={"value"}>
                <a target="_blank" rel="noopener noreferrer" href={href}>
                    <IconButton>
                        <MapIcon className="whiteText" fontSize="small" style={{ fill: color }} />
                    </IconButton>
                </a>
            </span>
        </InputAdornment>
    )
}

export default AddressAdornment;