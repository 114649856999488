import React from "react";
import { Menu } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { themeColors } from "@icarius-utils/colors";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";

const StyledMenu = (props) => {

    const {
        isOpen,
        children,
        anchorEl,
        onClose,
    } = props;

    const { theme } = useSelector(getTheme);

    const CustomMenu = withStyles({
        paper: {
            border: theme === "dark" ? `1px solid ${themeColors.borderTransparentDark}` : `1px solid ${themeColors.borderTransparentLight}`,
        }
    })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    ));

    return (
        <CustomMenu
            keepMounted
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onClose}>
            {children}
        </CustomMenu>
    );
}

export default StyledMenu;