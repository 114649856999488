import React from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const DateInput = (props) => {
    const {
        fieldConfig,
        label,
        value,
        handleChange,
        required,
        error,
    } = props;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
                required={required}
                fullWidth
                clearable
                cancelLabel={'Cancelar'}
                okLabel={'Aceptar'}
                clearLabel={'Limpiar'}
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage=''
                format="dd/MM/yyyy"
                margin="none"
                label={label}
                value={value}
                error={error}
                onChange={(e) => handleChange(e, fieldConfig.name)}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DateInput;