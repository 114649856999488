import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const SelectProcessDialog = (props) => {

  const { open, title, processList, handleClose, handleExecute } = props;
  const [process, setProcess] = useState('');

  const dispatch = useDispatch();

  const submit = () => {
    if (!process) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un proceso', severity: 'error' }));
      return;
    }

    handleExecute(null, process);
    handleClose();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label`}>{"Proceso"}</InputLabel>
                <Select
                  value={process}
                  labelId={`label`}
                  id={`select`}
                  onChange={(e) => setProcess(e.target.value)}
                  margin={"none"}
                >
                  {
                    processList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default SelectProcessDialog;
