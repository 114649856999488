// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".buttonStyles_buttonWrapper__3iSf- {\n    display: inline-block;\n}\n\n.buttonStyles_button__3kKVg {\n    background: transparent;\n    color: var(--mainText);\n    font-size: 18px;\n    border: 0;\n    padding-top: 5px;\n    vertical-align: bottom;\n    height: 34px;\n    width: 36px;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.buttonStyles_button__3kKVg svg {\n    fill: var(--mainText);\n}\n\n.buttonStyles_button__3kKVg:hover,\n.buttonStyles_button__3kKVg:focus {\n    outline: 0;\n}\n\n.buttonStyles_active__1ynS5 {\n    color: var(--corporate-color);\n}\n\n.buttonStyles_active__1ynS5 svg {\n    fill: var(--corporate-color);\n}", ""]);
// Exports
exports.locals = {
	"buttonWrapper": "buttonStyles_buttonWrapper__3iSf-",
	"button": "buttonStyles_button__3kKVg",
	"active": "buttonStyles_active__1ynS5"
};
module.exports = exports;
