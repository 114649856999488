import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import KanbanContext from "./context";

const Item = (props) => {

    const {
        data,
        index,
    } = props;

    const {
        renderPieceContent,
        getIsDisabled,
    } = useContext(KanbanContext);

    return (
        <Draggable
            draggableId={`${data.code}`}
            key={data.code}
            index={index}
            isDragDisabled={getIsDisabled(data)}
        >
            {
                (provided) => {
                    return (
                        <div // no ponerle style a este div o rompe todo
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <div style={{ marginBottom: 10 }}>
                                {renderPieceContent({ data })}
                                {provided.placeholder}
                            </div>
                        </div>
                    )
                }
            }
        </Draggable>
    );
}

export default Item;