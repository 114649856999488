import React, { useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import UserSection from "./userSection";

const SelectedUsersColumn = (props) => {

  const {
    employees,
    title,
    showAmount,
    handleClick,
    loggedUserIsDisabled,
  } = props;

  const checkedEmployees = useMemo(() => [...employees].filter((item) => item.isChecked), [employees])

  return (
    <Grid container direction="row" item xs={12} md={6}>
      <Grid item xs={12} style={{ paddingBottom: 20 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 10, height: 32 }}>
          <Typography variant="h6" className="whiteText" style={{ fontWeight: 600, fontSize: 18 }}>
            {title}
          </Typography>
          {
            showAmount &&
            <Typography variant="h6" className="whiteText" style={{ fontWeight: 300, fontSize: 18 }}>
              {checkedEmployees.length}
            </Typography>
          }
        </div>
      </Grid>
      <Grid item xs={12}>
        <UserSection
          employees={checkedEmployees}
          isSelection={false}
          handleClick={handleClick}
          loggedUserIsDisabled={loggedUserIsDisabled}
        />
      </Grid>
    </Grid>
  );
}

export default SelectedUsersColumn;
