// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".toolbarStyles_toolbar__2DoIP {\n    border: 1px solid var(--icons);\n    background: transparent;\n    border-radius: 4px;\n    z-index: 2;\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.toolbarStyles_toolbar__2DoIP:after {\n    border-color: rgba(255, 255, 255, 0);\n    border-top-color: var(--secondaryBackgroundColor);\n    border-width: 4px;\n    margin-left: -4px;\n}\n\n.toolbarStyles_toolbar__2DoIP:before {\n    border-color: rgba(221, 221, 221, 0);\n    border-top-color: #111;\n    border-width: 6px;\n    margin-left: -6px;\n}", ""]);
// Exports
exports.locals = {
	"toolbar": "toolbarStyles_toolbar__2DoIP"
};
module.exports = exports;
