import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getProcessesbyPathApi,
  executeProcessesbyPathApi,
} from "@icarius-connection/api";

export const getProcessesByPathAction = (path) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PROCESSES });
  try {
    let response = await getProcessesbyPathApi(`?path=${path}`);
    let processList = response.data && response.data.data;

    dispatch({
      type: actionTypes.GET_PROCESSES_FULFILLED,
      payload: {
        processList,
        path,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_PROCESSES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const executeProcessesByPathAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.EXECUTE_PROCESS });
  try {
    let response = await executeProcessesbyPathApi(dataToSend);

    dispatch({
      type: actionTypes.EXECUTE_PROCESS_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: "El proceso se ha iniciado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EXECUTE_PROCESS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

