import NAME from "./constants";

export const pollIsOpen = store => store[NAME].open;
export const getPollTitle = store => store[NAME].title;
export const getPollQuestions = store => store[NAME].questions;
export const getPollCompleted = store => store[NAME].completed;
export const getPollCode = store => store[NAME].code;
export const getPollType = store => store[NAME].type;
export const getPollShowAll = store => store[NAME].showAll;
export const getIsNPS = store => store[NAME].isNPS;
export const getIsAnonymous = store => store[NAME].isAnonymous;
export const getFile = store => store[NAME].file;
export const getAnswers = store => store[NAME].answers;
