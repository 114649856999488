import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  favouriteList: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
    case actionTypes.MODIFY:
      return { ...state, isLoading: true };

    case actionTypes.GET_FULFILLED:
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        favouriteList: { ...state.favouriteList, [action.payload.type]: action.payload.favouriteList }
      };

    case actionTypes.GET_REJECTED:
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
