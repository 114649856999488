import React from "react";

const getNineBoxIcon = (coordinates, size) => {
    let content;
    
    switch (coordinates) {
        case '1,1':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="6.02148" cy="25.9277" r="3" fill="red" />
                </>
            );
            break;
        case '1,2':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="16.0078" cy="25.9053" r="3" fill="red" />
                </>
            );
            break;
        case '1,3':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="25.9434" cy="26.085" r="3" fill="red" />
                </>
            );
            break;
        case '2,1':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="6.02148" cy="15.8843" r="3" fill="red" />
                </>
            );
            break;
        case '2,2':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="16.0078" cy="15.8843" r="3" fill="red" />
                </>
            );
            break;
        case '2,3':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="25.8496" cy="15.8843" r="3" fill="red" />
                </>
            );
            break;
        case '3,1':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="6.02148" cy="5.75684" r="3" fill="red" />
                </>
            );
            break;
        case '3,2':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="15.8398" cy="5.75684" r="3" fill="red" />
                </>
            );
            break;
        case '3,3':
            content = (
                <>
                    <path d="M30.8398 29.2883L30.8398 2.48C30.8398 1.59871 30.1254 0.884277 29.2441 0.884277L2.43557 0.884276C1.55427 0.884276 0.839846 1.5987 0.839846 2.48L0.839844 29.2883C0.839844 30.1696 1.55427 30.8841 2.43556 30.8841L29.2441 30.8841C30.1254 30.8841 30.8398 30.1696 30.8398 29.2883Z" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" />
                    <path d="M1.17578 20.8843L30.8392 20.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M1.17578 10.8843L30.8392 10.8843" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M11.0645 30.8843L11.0645 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M20.9512 30.8843L20.9512 0.884456" stroke="var(--svgColor)" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" />
                    <circle cx="26.0215" cy="5.75684" r="3" fill="red" />
                </>
            );
            break;
        default: content = <></>;
    }

    return (
        <svg width={size || "32"} height={size || "32"} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            {content}
        </svg>
    )
}

export default getNineBoxIcon;