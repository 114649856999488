import { getLocalizedErrorString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { CLOSE_DIALOG, OPEN_DIALOG } from "./actionTypes";

export const openErrorDialogAction = (e) => (dispatch) =>
  dispatch({
    type: OPEN_DIALOG,
    payload: {
      title: getLocalizedErrorString("defaultErrorDialogTitle"),
      msg: getErrorStringFromError(e),
    },
  });

export const openDialogAction = ({
  title,
  msg,
  children,
  onConfirm,
  onCancel,
  acceptOnly,
  acceptText,
  maxWidth,
  fullWidth,
}) => (dispatch) => {
  dispatch({
    type: OPEN_DIALOG,
    payload: {
      title,
      msg,
      children,
      onConfirm,
      onCancel,
      acceptOnly,
      acceptText,
      maxWidth,
      fullWidth,
    },
  });
}

export const closeDialogAction = (dispatch) => dispatch({ type: CLOSE_DIALOG });