import NAME from "./constants";

export const getIsOpen = store => store[NAME].open;
export const getTitle = store => store[NAME].title;
export const getMessage = store => store[NAME].msg;
export const getChildren = store => store[NAME].children;
export const getOnConfirm = store => store[NAME].onConfirm;
export const getOnCancel = store => store[NAME].onCancel;
export const getIsAcceptOnly = store => store[NAME].acceptOnly;
export const getAcceptText = store => store[NAME].acceptText;
export const getMaxWidth = store => store[NAME].maxWidth;
export const getFullWidth = store => store[NAME].fullWidth;
