import React, { useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { CloseIcon, FlightTakeoffIcon } from "@icarius-icons";
import EmployeeVacationsDialogContent from "@icarius-pages/vacations/components/employeeVacationsDialog/content";
import PaperDraggable from "@icarius-common/paperDraggable";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";
import { getUserData } from "src/app/selectors";
import { useSelector } from "react-redux";
import useVisitPage from "@icarius-utils/hooks/useVisitPage";
import paths from "@icarius-localization/paths";
import {
    getEmployeeMiscData,
    getIsLoading,
    getDateFormat,
    getEmployeeDebitData,
    getEmployeeCreditData,
} from "@icarius-pages/vacations/selectors";
import Loader from "@icarius-common/loader";
import { useHistory } from "react-router-dom";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { requestsIds } from "@icarius-utils/requestsIds";
import { loading as loadingRequests } from "@icarius-pages/myRequests/selectors";
import DialogTransition from "@icarius-common/dialogTransition";
import { getEmployeeVacationsAction } from "@icarius-pages/vacations/actions";
import { useDispatch } from "react-redux";

const MyVacationsDialog = ({ open, handleClose }) => {

    const dispatch = useDispatch();

    const requestsIsLoading = useSelector(loadingRequests);
    const isLoading = useSelector(getIsLoading);
    const dateFormat = useSelector(getDateFormat);
    const debitData = useSelector(getEmployeeDebitData);
    const creditData = useSelector(getEmployeeCreditData);
    const miscData = useSelector(getEmployeeMiscData);

    const playAudioTitle = <PlayButton audioName="Mi cuenta corriente de vacaciones" title="Mi cuenta corriente de vacaciones" />
    const userData = useSelector(getUserData);

    const history = useHistory();

    useEffect(() => {
        if (userData.level === "C") {
            dispatch(getEmployeeVacationsAction(userData.level));
        }
    }, [dispatch, userData])

    useVisitPage(paths.myVacations);

    const handleVacationsClick = () => {
        history.push({
            pathname: paths.myRequests,
            search: `?type=${requestsIds.id_type.VAC}`,
        });
    }

    const vacationRequestButton = () =>
        <CustomIconButton
            title={'Solicitar vacaciones'}
            onClick={handleVacationsClick}
        >
            <FlightTakeoffIcon />
        </CustomIconButton>

    if (isLoading) {
        return (
            <Loader open={isLoading} />
        )
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open}
            scroll={"paper"}
            fullWidth={true}
            maxWidth={"xl"}
        >
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <Loader open={requestsIsLoading} />
            <EmployeeVacationsDialogContent
                dateFormat={dateFormat}
                creditData={creditData}
                debitData={debitData}
                miscData={miscData}
                playAudioTitle={playAudioTitle}
                employeeCode={userData.code}
                vacationRequestButton={vacationRequestButton}
            />
        </Dialog>
    );
}

export default MyVacationsDialog;
