import React from "react";
import { Typography, Checkbox } from "@material-ui/core";

const CheckInput = (props) => {

    const {
        fieldConfig,
        value,
        label,
        handleChange,
    } = props;

    const handleClick = () => {
        handleChange(!value, fieldConfig.name);
    }

    return (
        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", height: 48 }}>
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }} onClick={handleClick}>
                {label}
            </Typography>
            <Checkbox checked={value} onChange={handleClick} />
        </div>
    )
}

export default CheckInput;