import { useState, useEffect, useRef } from "react";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getTemplatesAction,
  deleteTemplateAction,
  updateTemplateAction,
  createTemplateWithJSONAction,
  createTemplateAction,
} from "../actions";
import { tooltipOptions, setChartType } from "@icarius-table/utils";
import { templatesOptions } from "@icarius-utils/constants";
import { getTemplatesRows, getIsLoadingTemplates } from "../selectors";
import { exportIcariusFile, importIcariusFile } from "@icarius-utils/exportImportIcarius";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useTemplates = (templateType, switchStatus = false, onTemplateSelectionCallback) => {

  const dispatch = useDispatch();
  const templates = useSelector(getTemplatesRows);
  const isLoadingTemplates = useSelector(getIsLoadingTemplates);

  useEffect(() => {
    dispatch(getTemplatesAction(templateType));
  }, [dispatch, templateType])

  const currentChartRef = useRef();
  const user = useSelector(getUserData);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [createTemplateDialogIsOpen, setCreateTemplateDialogIsOpen] = useState(false);

  const handleOpenCreateTemplateDialog = () => setCreateTemplateDialogIsOpen(true);
  const handleCloseCreateTemplateDialog = () => setCreateTemplateDialogIsOpen(false);

  const handleOpenUpdateTemplateDialog = (gridRef) => {
    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: getLocalizedString("confirmUpdate"),
      onConfirm: () => handleUpdateTemplate(gridRef),
    }));
  };

  const handleOpenDeleteTemplateDialog = (gridRef) => {
    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: getLocalizedString("confirmDelete"),
      onConfirm: () => handleDeleteTemplate(gridRef),
    }));
  };

  const handleCreateTemplate = (gridRef, role, name) => {
    const dataToSend = {
      U_funcion: templateType,
      U_template: name,
      U_rol: role,
      U_columnstate: gridRef.columnApi.getColumnState(),
      U_groupstate: gridRef.columnApi.getColumnGroupState(),
      U_filterstate: gridRef.api.getFilterModel(),
      U_pivotstate: gridRef.columnApi.isPivotMode(),
      U_selstate: switchStatus,
      U_graphicstate: gridRef.api.getChartModels().length > 0 ? gridRef.api.getChartModels()[0] : undefined,
      overwrite: false
    }

    if (dataToSend.U_graphicstate) {
      delete dataToSend.U_graphicstate.chart;
    }

    dispatch(createTemplateAction(dataToSend))
      .then((response) => {
        if (response?.data?.status === "ERROR_DUPLICADO") {
          dispatch(openSnackbarAction({ msg: getLocalizedErrorString("templateAlreadyExists"), severity: "error" }));
        } else {
          setSelectedTemplate(response.data.result);
          handleCloseCreateTemplateDialog();
        }
      });
  }

  const handleUpdateTemplate = (gridRef) => {
    let updatedTemplate = { ...selectedTemplate };
    updatedTemplate.U_selstate = switchStatus;
    updatedTemplate.U_columnstate = gridRef.columnApi.getColumnState();
    updatedTemplate.U_groupstate = gridRef.columnApi.getColumnGroupState();
    updatedTemplate.U_filterstate = gridRef.api.getFilterModel();
    updatedTemplate.U_pivotstate = gridRef.columnApi.isPivotMode();
    updatedTemplate.U_graphicstate = gridRef.api.getChartModels().length > 0 ? gridRef.api.getChartModels()[0] : undefined;

    if (updatedTemplate.U_graphicstate) {
      delete updatedTemplate.U_graphicstate.chart;
    }

    dispatch(updateTemplateAction(updatedTemplate));
  }

  const handleDeleteTemplate = (gridRef) => {
    setSelectedTemplate(null);
    dispatch(deleteTemplateAction(selectedTemplate.Code))
      .then((response) => {
        if (response.data && response.data.status === "OK") {
          gridRef.columnApi.resetColumnState();
          gridRef.columnApi.resetColumnGroupState();
          gridRef.columnApi.setPivotMode(false);
          gridRef.api.setFilterModel(null);
          gridRef.columnApi.applyColumnState({
            defaultState: {
              state: null,
              sort: null,
              rowGroup: null,
              pivot: null,
              pinned: null
            }
          });
          clearChart(gridRef);
          gridRef.api.onFilterChanged();
          gridRef.api.onSortChanged();

          dispatch(openSnackbarAction({ msg: getLocalizedString("templateDelete"), severity: "success" }));
        }
      });
  };

  const handleImportTemplates = (gridRef) => {
    importIcariusFile(dispatch, (parsedObject) => {
      const dataToSend = {
        U_funcion: templateType,
        U_template: parsedObject["U_template"],
        U_rol: parsedObject["U_rol"],
        U_columnstate: parsedObject["U_columnstate"] !== "" ? JSON.parse(parsedObject["U_columnstate"]) : "",
        U_groupstate: parsedObject["U_groupstate"] !== "" ? JSON.parse(parsedObject["U_groupstate"]) : "",
        U_filterstate: parsedObject["U_filterstate"] !== "" ? JSON.parse(parsedObject["U_filterstate"]) : "",
        U_pivotstate: parsedObject["U_pivotstate"],
        U_selstate: parsedObject["U_selstate"],
        U_graphicstate: parsedObject["U_graphicstate"] !== "" ? JSON.parse(parsedObject["U_graphicstate"]) : "",
        overwrite: true,
      }

      dispatch(createTemplateWithJSONAction(dataToSend))
        .then((response) => {
          if (response.data.status === "ERROR_DUPLICADO") {
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("templateAlreadyExists"), severity: "error" }));
          } else {
            dispatch(getTemplatesAction(templateType));
            handleSelectTemplateAndUpdateGrid(response.data.result.Code, gridRef);
            dispatch(openSnackbarAction({ msg: getLocalizedString("templateImport"), severity: "success" }));
          }
        })
        .catch(() => {
          dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
        });
    });
  }

  const handleExportTemplates = () => {
    exportIcariusFile(dispatch, selectedTemplate, selectedTemplate["U_template"]);
  }

  const getRole = (role) => {
    const foundItem = templatesOptions.find((item) => item.code === role);
    if (foundItem) return foundItem.label;
    return "";
  }

  const handleSelectTemplateAndUpdateGrid = (code, gridRef) => {
    const templateFound = templates?.find((item) => item.Code === code);
    clearChart(gridRef);

    if (templateFound) {
      setSelectedTemplate(templateFound);

      if (Boolean(onTemplateSelectionCallback)) {
        onTemplateSelectionCallback();
      }

      gridRef.columnApi.resetColumnState();
      gridRef.columnApi.setColumnState(JSON.parse(templateFound.U_columnstate));
      gridRef.columnApi.setColumnGroupState(JSON.parse(templateFound.U_groupstate));
      gridRef.columnApi.applyColumnState({
        state: JSON.parse(templateFound.U_columnstate),
        applyOrder: true,
      });
      gridRef.api.setFilterModel(JSON.parse(templateFound.U_filterstate));
      gridRef.columnApi.setPivotMode(JSON.parse(templateFound.U_pivotstate));
      gridRef.api.onFilterChanged();
      gridRef.api.onSortChanged();

      if (templateFound.U_graphicstate) {
        let chartModel = JSON.parse(templateFound.U_graphicstate);
        chartModel.cellRange.rowEndIndex = 0;
        chartModel.cellRange.rowEndIndex = gridRef.api.getDisplayedRowCount() || chartModel.cellRange.rowEndIndex;

        let optionsFromDB = chartModel.chartOptions;
        let createRangeChartParams = {
          cellRange: chartModel.cellRange,
          chartType: chartModel.chartType,
          chartPalette: chartModel.chartPalette,
          processChartOptions: function (params) {
            let options = optionsFromDB;
            setChartType(params.type);
            options.seriesDefaults.tooltip.renderer = tooltipOptions;

            return options;
          }
        };
        currentChartRef.current = gridRef.api.createRangeChart(createRangeChartParams);
      }
    } else {
      setSelectedTemplate(null);
      gridRef.columnApi.resetColumnState();
      gridRef.columnApi.resetColumnGroupState();
      gridRef.columnApi.setPivotMode(false);
      gridRef.api.setFilterModel(null);
      gridRef.columnApi.applyColumnState({
        defaultState: {
          sort: null,
          rowGroup: null,
          pivot: null,
          pinned: null
        }
      });
      if (currentChartRef) {
        currentChartRef.current = null;
      }
      gridRef.api.onFilterChanged();
      gridRef.api.onSortChanged();
    }
  };

  const clearChart = (gridRef) => {
    if (gridRef) {
      if (currentChartRef) {
        currentChartRef.current = null;
      }

      gridRef.api.chartService.activeCharts.forEach(item => item.destroyChart());
      gridRef.columnApi.resetColumnState();
      gridRef.columnApi.resetColumnGroupState();
      gridRef.columnApi.setPivotMode(false);
      gridRef.api.setFilterModel(null);
      gridRef.columnApi.applyColumnState({
        defaultState: {
          sort: null,
          rowGroup: null,
          pivot: null,
          pinned: null
        }
      });
    }
  }

  return {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  }
}

export default useTemplates;