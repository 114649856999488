import React from "react";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { EditIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, Fab } from "@material-ui/core";
import { shadeHexColor } from "@icarius-utils/colors";

const ProfileImg = (props) => {

    const {
        image,
        size,
        isSquare,
        editable,
        handleChange,
    } = props;

    const color = useSelector(getAppColor);

    const generalStyle = {
        height: size,
        width: size,
        margin: 0,
        borderRadius: isSquare ? 0 : "50%",
    };

    const CustomFab = withStyles({
        root: {
            color: "white",
            backgroundColor: color,
            "&:hover": {
                backgroundColor: color ? shadeHexColor(color, -0.5) : "#000",
            },
        },
    })(Fab);

    return (
        <div style={{ display: "flex", alignItems: "baseline" }}>
            {
                Boolean(image && image !== "-") ?
                    <img
                        src={IMAGES_ENDPOINT + image}
                        alt={""}
                        onError={(event) => event.target.src = DefaultImage}
                        style={generalStyle}
                    />
                    :
                    <div
                        style={{
                            ...generalStyle,
                            background: color,
                            boxShadow: "-1px 0px 2px rgba(0, 0, 0, 0.25)"
                        }}
                    />
            }
            {
                editable &&
                <CustomFab
                    size={"small"}
                    aria-label="add"
                    style={{
                        cursor: "pointer",
                        position: "relative",
                        verticalAlign: "bottom",
                        left: "-20px",
                    }}
                    onClick={handleChange}
                >
                    <Tooltip title={"Modificar foto de perfil"}>
                        <EditIcon />
                    </Tooltip>
                </CustomFab>
            }
        </div>
    )
}

export default ProfileImg;
