import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  isEnabled: false,
  processList: [],
  locale: 'da',
  dateFormat: 'dd/mm/yyyy',
  rows: null,
  templates: null,
  process: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_AUTOMATIC_PROCESSES:
      return { ...state, isLoading: true };
    case actionTypes.GET_AUTOMATIC_PROCESSES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isEnabled: action.payload.isEnabled,
        processList: action.payload.processList || [],
      };
    case actionTypes.GET_AUTOMATIC_PROCESSES_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.EXECUTE:
      return { ...state, isLoading: true };
    case actionTypes.EXECUTE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.EXECUTE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.VIEW:
      return { ...state, isLoading: true };
    case actionTypes.VIEW_FULFILLED:
      return {
        ...state,
        isLoading: false,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
        result: action.payload.result,
        templates: action.payload.templates,
        process: action.payload.process,
      };
    case actionTypes.VIEW_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
