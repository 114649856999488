import React from "react";
import { Grid, Card, useMediaQuery } from "@material-ui/core";
import SectionDivider from "./sections/sectionDivider";

const CardVariant = (props) => {

    const {
        section1,
        section2,
        section3,
        section4,
    } = props;

    const showHorizontalDividers = !useMediaQuery('(min-width: 960px)')

    return (
        <Card style={{ margin: 10 }}>
            {section1}
            <Grid container style={{ padding: showHorizontalDividers && "0px 40px", borderTop: '1px solid var(--medalTableBorder)' }}>
                <Grid container item xs={12} md={4} alignItems="center" justify="center">
                    {section2}
                </Grid>
                {showHorizontalDividers && <SectionDivider />}
                <Grid container item xs={12} md={4} direction="column" alignItems="center" justify="center">
                    {!showHorizontalDividers && <div style={{ height: '70%', borderLeft: '1px solid #b0b0b0' }} />}
                    {section3}
                    {!showHorizontalDividers && <div style={{ height: '70%', borderLeft: '1px solid #b0b0b0' }} />}
                </Grid>
                {showHorizontalDividers && <SectionDivider />}
                <Grid container item xs={12} md={4} alignItems="center" justify="center" style={{ padding: '0px 20px'}}>
                    {section4}
                </Grid>
            </Grid>
        </Card>
    )
}

export default CardVariant;
