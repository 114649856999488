import * as actionTypes from "./actionTypes";
import {
  deleteImageFromGalleryEndpoint,
  getImagesFromGallery,
  uploadImageToGalleryEndpoint
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getGalleryImagesAction = (module) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_IMAGES });
  try {
    let response = await getImagesFromGallery(`?module=${module}`);

    let images = response.data && response.data.images;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_IMAGES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_IMAGES_FULFILLED,
      payload: { images }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_IMAGES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const uploadImageAction = (file, module) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_IMAGE });
  try {
    let formData = new FormData();
    formData.append('module', module);
    formData.append('file', file);

    let response = await uploadImageToGalleryEndpoint(formData);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPLOAD_IMAGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_IMAGE_FULFILLED,
      payload: { images: response.data.images },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_IMAGE_REJECTED });
  }
};

export const deleteImageAction = (filename, module) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_IMAGE });
  try {
    let response = await deleteImageFromGalleryEndpoint({ filename, module });

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_IMAGE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_IMAGE_FULFILLED,
      payload: { images: response.data.images },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_IMAGE_REJECTED });
  }
};
