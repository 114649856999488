import React from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
    cssOutlinedInput: {
        color: "var(--mainText) !important",
        '&$cssFocused $notchedOutline': {
            color: "var(--mainText) !important",
        }
    },

    cssFocused: {
        color: "var(--mainText) !important",
    },

    notchedOutline: {
        borderColor: 'var(--icons) !important',
        color: "var(--mainText) !important",
    },
});

const MultilineInput = (props) => {
    const {
        fieldConfig,
        label,
        value,
        handleChange,
        required,
        error,
        classes,
    } = props;

    return (
        <TextField
            required={required}
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            margin={"none"}
            label={label}
            value={value}
            onChange={(e) => handleChange(e.target.value, fieldConfig.name)}
            InputLabelProps={{
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                },
            }}
            InputProps={{
                classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                }
            }}
            error={error}
            inputProps={{ spellCheck: 'false' }}
        />
    )
}

export default withStyles(styles)(MultilineInput);