import { useState, useMemo, useEffect } from 'react';
import {
    EditorState,
    Modifier,
    convertFromRaw,
    convertToRaw,
} from 'draft-js';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import { stateToHTML } from 'draft-js-export-html';
import buttonStyles from './toolbarStyles/buttonStyles.module.css';
import toolbarStyles from './toolbarStyles/toolbarStyles.module.css';
import { Mention } from './mentions';

const useMentionEditor = (initialValue, options, handleChange, maxLength) => {

    const [editorState, setEditorState] = useState(() => {
        if (initialValue) return EditorState.createWithContent(convertFromRaw(initialValue))
        return EditorState.createEmpty();
    });
    const [filteredMentionSuggestions, setFilteredMentionSuggestions] = useState(options || []);
    const [mentionSuggestionsIsOpen, setMentionSuggestionsIsOpen] = useState(false);

    useEffect(() => {
        if (options?.length) setFilteredMentionSuggestions(options);
    }, [options])

    const { plugins, MentionSuggestionsSelector, Toolbar } = useMemo(() => {
        const toolbarPlugin = createToolbarPlugin({
            theme: { buttonStyles, toolbarStyles },
        });
        const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
        const mentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            mentionPrefix: '@',
            supportWhitespace: true,
            mentionComponent: Mention,
        });

        return {
            plugins: [mentionPlugin, linkifyPlugin, toolbarPlugin],
            MentionSuggestionsSelector: mentionPlugin.MentionSuggestions,
            Toolbar: toolbarPlugin.Toolbar,
        };
    }, []);

    const onEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        if (handleChange) {
            const html = stateToHTML(newEditorState.getCurrentContent(), {
                inlineStyles: {
                    BOLD: { element: "b" },
                    ITALIC: { element: "i" },
                },
            });

            const raw = convertToRaw(newEditorState.getCurrentContent());

            // si hay muchas lineas en blanco, dejar de a una (no afecta al editor, sino a lo que se envia al handleChange)
            raw.blocks = raw.blocks.reduce((newArr, item, index, ogArr) => {
                if (index === 0) {
                    newArr.push(item);
                    return newArr;
                }

                if (!item.text && !ogArr[index - 1].text) {
                    return newArr;
                }

                newArr.push(item);
                return newArr;
            }, []);

            // si esta vacio, guardar vacio (el editor por defecto crea un block vacio pero ocupa espacio)
            if (raw.blocks.length === 1 && !raw.blocks[0].text) {
                handleChange("", html);
                return;
            }

            handleChange(raw, html);
        }
    };

    const onSuggestionsIsOpenChange = (newOpen) => {
        setMentionSuggestionsIsOpen(newOpen);
    };

    const onSuggestionsSearchChange = ({ value }) => {
        const getFilteredSuggestions = (searchValue, suggestions, size) => {
            if (value) {
                const value = searchValue.toLowerCase();
                const filteredSuggestions = suggestions.filter((suggestion) => suggestion.name.toLowerCase().indexOf(value) > -1);
                const length = filteredSuggestions.length < size ? filteredSuggestions.length : size;
                return filteredSuggestions.slice(0, length);
            }

            return suggestions;
        };

        setFilteredMentionSuggestions(getFilteredSuggestions(value, options, 15));
    };

    const insertText = (text) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        let nextEditorState = EditorState.createEmpty();
        let nextContentState;

        if (selection.isCollapsed()) {
            nextContentState = Modifier.insertText(contentState, selection, text);
        } else {
            nextContentState = Modifier.replaceText(contentState, selection, text);
        }

        nextEditorState = EditorState.push(editorState, nextContentState, 'insert-characters');
        onEditorChange(nextEditorState);
    }

    const getLengthOfSelectedText = () => {
        const currentSelection = editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);

            if (isStartAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                };
            }
        }

        return length;
    }

    const handleBeforeInput = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText().length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength - selectedTextLength > maxLength - 1) {
            return 'handled';
        }
    }

    const handlePastedText = (pastedText) => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
            return 'handled';
        }
    }

    return {
        editorState,
        filteredMentionSuggestions,
        mentionSuggestionsIsOpen,
        plugins,
        onEditorChange,
        onSuggestionsIsOpenChange,
        onSuggestionsSearchChange,
        insertText,
        handleBeforeInput,
        handlePastedText,
        MentionSuggestionsSelector,
        Toolbar,
    };
}

export default useMentionEditor;