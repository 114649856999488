import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Typography, Grid } from "@material-ui/core";
import GridMenu from "@icarius-common/gridMenu";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";

const MENU_CONTAINER_ITEM_HEIGHT = 60;

const Toolbar = (props) => {

  const [localSwitchValue, setLocalSwitchValue] = useState(true);

  const {
    title,
    audioName,
    subtitle,

    customHeader,
    helpItems,
    menuItems,
    processByPathButton,

    amountOfGroups,
    hasExpand,
    hasSelectAll,
    hasHelp,

    hasSwitch,
    handleSwitchClick,
    switchValue,
    switchLabelOff,
    switchLabelOn,

    agGridRef,
  } = props;

  const hasPageMenu = (
    customHeader ||
    helpItems ||
    menuItems ||
    hasExpand ||
    hasSelectAll ||
    hasHelp ||
    hasSwitch
  );

  // handle generico para Switch
  const handleGenericSwitchClick = (event) => {
    if (event.target.checked === true) {
      setLocalSwitchValue(true);

      let filterInstance = agGridRef.current.api.getFilterInstance("PERSONAL DE PLANTA");
      if (filterInstance) {
        filterInstance.setModel({
          type: "set",
          values: ["Si"],
        });
      }

      let filterInstanceVigencia = agGridRef.current.api.getFilterInstance("ESTADO");
      if (filterInstanceVigencia) {
        filterInstanceVigencia.setModel({
          type: "set",
          values: ["Vigente"],
        });
      }

      agGridRef.current.api.onFilterChanged();
    } else {
      setLocalSwitchValue(false);
      let filterInstance = agGridRef.current.api.getFilterInstance("PERSONAL DE PLANTA");
      if (filterInstance) {
        filterInstance.setModel(null);
      }

      let filterInstanceVigencia = agGridRef.current.api.getFilterInstance("ESTADO");
      if (filterInstanceVigencia) {
        filterInstanceVigencia.setModel(null);
      }

      agGridRef.current.api.onFilterChanged();
    }
  };

  return (
    <div style={{ margin: "0px 24px" }}>
      <Grid container direction="row" alignItems="center" justify="space-between">
        {
          Boolean(title || subtitle) &&
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '16px 0px',
            }}
          >
            {
              title &&
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <Typography style={{ fontSize: "1rem", fontWeight: 700 }} variant="h6" id="toolbar-title">
                  {title}
                </Typography>
                {
                  audioName &&
                  <PlayButton audioName={audioName} title={audioName} />
                }
              </div>
            }
            {
              subtitle &&
              <Typography style={{ fontSize: "1rem", fontWeight: 700 }} variant="h6" id="toolbar-title">
                {subtitle}
              </Typography>
            }
          </div>
        }
        {
          hasPageMenu &&
          <div className="pageMenuContainer" style={{ height: MENU_CONTAINER_ITEM_HEIGHT }}>
            {customHeader && customHeader(agGridRef.current)}
            <GridMenu
              helpItems={helpItems}
              customItems={[...(menuItems || []), processByPathButton]}
              hasSwitch={hasSwitch}
              switchValue={switchValue !== undefined ? switchValue : localSwitchValue}
              handleSwitchClick={(handleSwitchClick && ((e) => handleSwitchClick(e, agGridRef.current))) || handleGenericSwitchClick}
              switchLabelOn={switchLabelOn || getLocalizedString("activeAndPlantPersonnel")}
              switchLabelOff={switchLabelOff || getLocalizedString("everyone")}
              hasSelectAll={hasSelectAll}
              hasExpand={hasExpand}
              amountOfGroups={amountOfGroups}
              hasHelp={hasHelp}
              ref={agGridRef.current}
            />
          </div>
        }
      </Grid>
    </div>
  );
}

export default Toolbar;