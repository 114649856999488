import { useEffect, useState } from "react";

/*
  Hook que maneja el index de la imagen seleccionada. Cada vez que cambia el array de imagenes, busca la seteada y devuelve ese indice.
  Tambien devuelve metodo para setear el indice a mano.
*/
const useSetImageIndex = (data, images, imageFieldName = 'image') => {
  const [imageIndex, setImageIndex] = useState(null);

  useEffect(() => {
    if (!data || !data[imageFieldName] || !images) return;

    const indexToSet = images.findIndex(image => image.img === data[imageFieldName]);
    if (indexToSet > -1) {
      setImageIndex(indexToSet);
    }
  }, [data, images, imageFieldName])

  return [imageIndex, setImageIndex];
}

export default useSetImageIndex;
