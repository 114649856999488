import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import GridDialogContent from "./gridDialogContent";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { getIsOpen, getData } from "../selectors";
import { CLEAR_QUERY } from "../actionTypes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const GridProcessResultDialog = () => {

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const open = useSelector(getIsOpen);

  const handleClose = () => {
    dispatch({ type: CLEAR_QUERY });
  }

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {data?.name}
      </DialogTitle>
      <GridDialogContent
        data={data?.result}
        filters={data?.filters}
        currencyLocalization={data?.currencyLocalization}
        name={data?.name}
        dateFormat={data?.dateFormat}
      />
    </StyledDialog>
  );
}

export default GridProcessResultDialog;