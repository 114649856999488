import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getTemplatesAPI,
  createTemplateAPI,
  deleteTemplateAPI,
  updateTemplateAPI,
} from "@icarius-connection/api";
import {
  getLocalizedString,
} from "@icarius-localization/strings";

export const getTemplatesAction = (templateType) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TEMPLATES });
  try {
    let response = await getTemplatesAPI(`?grid=${templateType}`);
    dispatch({
      type: actionTypes.GET_TEMPLATES_FULFILLED,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_TEMPLATES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createTemplateAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_TEMPLATE });
  try {
    let response = await createTemplateAPI(dataToSend);

    if (response.data.status === "OK") {
      dispatch({
        type: actionTypes.CREATE_TEMPLATE_FULFILLED,
        payload: response.data.result,
      });
      dispatch(openSnackbarAction({ msg: getLocalizedString("templateCreate"), severity: "success" }));
      return response;
    }

    dispatch({ type: actionTypes.CREATE_TEMPLATE_REJECTED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_TEMPLATE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createTemplateWithJSONAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_TEMPLATE });
  try {
    let response = await createTemplateAPI(dataToSend);

    if (response.data && (response.data.status === "OK" || response.data.status !== "ERROR_DUPLICADO")) {
      dispatch({
        type: actionTypes.CREATE_TEMPLATE_FULFILLED,
        payload: response.data.result,
      });
      return response;
    }

    dispatch({ type: actionTypes.CREATE_TEMPLATE_REJECTED });
    return;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_TEMPLATE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteTemplateAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_TEMPLATE });
  try {
    let response = await deleteTemplateAPI({ code });
    dispatch({ type: actionTypes.DELETE_TEMPLATE_FULFILLED, payload: code });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_TEMPLATE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const updateTemplateAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY_TEMPLATE });
  try {
    let response = await updateTemplateAPI(data);

    if (response.data && response.data.status === "OK") {
      dispatch({ type: actionTypes.MODIFY_TEMPLATE_FULFILLED, payload: data });
      dispatch(openSnackbarAction({ msg: getLocalizedString("templateUpdate"), severity: "success" }));
      return response;
    }
    
    dispatch({ type: actionTypes.MODIFY_TEMPLATE_REJECTED });
    return;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_TEMPLATE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};