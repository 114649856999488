import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  images: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_IMAGES:
      return {...state, loading: true};
    case actionTypes.GET_IMAGES_FULFILLED:
      return {...state, loading: false, images: action.payload.images };
    case actionTypes.GET_IMAGES_REJECTED:
      return {...state, loading: false};

    case actionTypes.UPLOAD_IMAGE:
      return {...state, loading: true};
    case actionTypes.UPLOAD_IMAGE_FULFILLED:
      return {...state, loading: false, images: action.payload.images };
    case actionTypes.UPLOAD_IMAGE_REJECTED:
      return {...state, loading: false};

    case actionTypes.DELETE_IMAGE:
      return {...state, loading: true};
    case actionTypes.DELETE_IMAGE_FULFILLED:
      return {...state, loading: false, images: action.payload.images };
    case actionTypes.DELETE_IMAGE_REJECTED:
      return {...state, loading: false};

    case actionTypes.RESET_GALLERY:
      return initialState;
    default:
      return state;
  }
}
