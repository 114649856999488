import React, { useEffect } from "react";
import { getLanguage } from "@icarius-localization/strings";
import { AgGridReact } from "@ag-grid-community/react";
import {
  decimalStatusBar,
  getContextMenuItems,
  setGridName,
  setLocale,
  setCtx,
  agGridLanguage,
  groupColumn,
  processChartOptions,
  defaultColDef,
  sideBar,
  statusBar,
  onGridReady,
  getChartTheme,
  setTaskControl,
  setHiddenHeader,
} from "@icarius-table/utils";
import { excelStyles } from "@icarius-table/excelStyles";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { shadeHexColor } from "@icarius-utils/colors";
import paths from "@icarius-localization/paths";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import "@icarius-assets/css/grid-dark.scss";
import "@icarius-assets/css/grid-light.scss";

const PAGES_TO_APPLY_FILTERS = [
  paths.certificates,
  paths.digitalCertificates,
  paths.digitalFolderAnalysis,
  paths.digitalFolderAnalysisNotAvailable,
  paths.myPeople,
  paths.vacations,
]

const AgGridContainer = (props) => {

  const {
    agGridRef,
    dateFormat,
    locale,
    rowData,
    handleRowClick, //metodo a ejecutar por el agGrid, si no se manda la prop, al tocar en la grilla no pasa nada
    ownColumnDef, //en caso de que la grilla no use filterBySocietyAndPage, y haya definiciones propias    
    gridTitle, //titulo de la grilla al exportarla
    columnDefPage, //nombre de la pagina para buscar la definicion de columnas de filterBySocietyAndPage para el agGrid
    onFilterChanged, //accion a ejecutar cuando cambia filtro del aggrid
    onRowDataChanged, //accion a ejecutar cuando cambia la data
    onFirstDataRendered, //accion a ejecutar cuando se renderiza primera vez
    frameworkComponents,
    height, // opcional, altura de la grilla
    setAmountOfGroups,
    taskControl,
    hiddenHeader,
  } = props;

  const color = useSelector(getAppColor);
  const { theme } = useSelector(getTheme);

  useEffect(() => {
    // este useEffect limpia los listeners del agGrid
    let localRefValue = agGridRef?.current; // esto es para que el useEffect no pierda el valor de la ref para la funcion del return

    return () => {
      if (localRefValue) {
        localRefValue.api.removeEventListener("rangeSelectionChanged", decimalStatusBar);
        localRefValue.api.removeEventListener("filterChanged", decimalStatusBar);
        localRefValue.api.removeEventListener("filterModified", decimalStatusBar);
        localRefValue.api.removeEventListener("sortChanged", decimalStatusBar);
        localRefValue.api.removeEventListener("displayedColumnsChanged", decimalStatusBar);
      }
    }
  }, [agGridRef])

  useEffect(() => {
    // este useEffect setea los colores segun el corporativo y el theme
    let darkerColor;
    let lighterColor;

    if (color) {
      if (theme === "light") {
        darkerColor = shadeHexColor(color, 0.35);
        lighterColor = shadeHexColor(color, 0.5);
      } else {
        darkerColor = shadeHexColor(color, -0.3);
        lighterColor = shadeHexColor(color, -0.5);
      }
    }

    // seteo los colores corporativos
    document.documentElement.style.setProperty("--main-bg-color", color);
    if (color) {
      document.documentElement.style.setProperty("--grid-bg-color", darkerColor);
      document.documentElement.style.setProperty("--grid-light-color", lighterColor);
    }
  }, [color, theme])

  // para determinar cuando se muestra el boton de Expandir y cuando el de Contraer (dependen de la cant de grupos de la grilla)
  const handleSetAmountOfGroups = () => {
    let amount = 0;
    agGridRef.current.api.forEachNode((node) => {
      if (node.group) amount++;
    });
    setAmountOfGroups(amount);
  }

  const onRowClick = (row) => {
    if (!row?.data || !handleRowClick) return;
    handleRowClick(row);
  };

  const genericOnFirstDataRendered = (params) => {
    let allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });

    if (PAGES_TO_APPLY_FILTERS.includes(columnDefPage)) {
      let filterInstance = params.api.getFilterInstance("PERSONAL DE PLANTA");

      if (filterInstance) {
        filterInstance.setModel({
          type: "set",
          values: ["Si"],
        });
      }

      let filterInstanceVigencia = params.api.getFilterInstance("ESTADO");
      if (filterInstanceVigencia) {
        filterInstanceVigencia.setModel({
          type: "set",
          values: ["Vigente"],
        });
      }

      params.api.onFilterChanged();
    }

    params.columnApi.autoSizeColumns(allColumnIds);

    params.api.addEventListener("rangeSelectionChanged", decimalStatusBar);
    params.api.addEventListener("filterChanged", decimalStatusBar);
    params.api.addEventListener("filterModified", decimalStatusBar);
    params.api.addEventListener("sortChanged", decimalStatusBar);
    params.api.addEventListener("displayedColumnsChanged", decimalStatusBar);
  }

  setGridName(gridTitle);
  setLocale(locale);
  setCtx(agGridRef);
  setTaskControl(Boolean(taskControl));
  setHiddenHeader(Boolean(hiddenHeader));

  return (
    <div
      className={theme === "dark" ? "ag-theme-balham-dark" : "ag-theme-balham"}
      style={{
        minHeight: 300,
        height: height || 'calc(100vh - 195px)',
      }}
    >
      <AgGridReact
        ref={agGridRef}
        rowData={rowData}
        columnDefs={ownColumnDef ? ownColumnDef : getColumnDefByPage(columnDefPage)}
        defaultColDef={defaultColDef}
        sideBar={sideBar}
        statusBar={statusBar}
        chartThemes={getChartTheme(theme)}
        localeText={getLanguage() !== "es" ? undefined : agGridLanguage.es}
        rowSelection={"multiple"}
        rowGroupPanelShow={"always"}
        getContextMenuItems={getContextMenuItems}
        excelStyles={excelStyles(color, dateFormat || "dd/mm/yyyy")}
        processChartOptions={processChartOptions}
        autoGroupColumnDef={groupColumn}
        modules={AllModules}
        frameworkComponents={frameworkComponents}
        onFilterChanged={onFilterChanged}
        onColumnRowGroupChanged={handleSetAmountOfGroups}
        onRowClicked={onRowClick}
        onFirstDataRendered={onFirstDataRendered || genericOnFirstDataRendered}
        onGridReady={onGridReady}
        onRowDataChanged={onRowDataChanged || onGridReady}
        paginationPageSize={100}
        pagination={true}
        accentedSort={true}
        suppressFieldDotNotation={true}
        animateRows={true}
        applyColumnDefOrder={true}
        groupSelectsChildren={true}
        enableCharts={true}
        enableRangeSelection={true}
        singleClickEdit={true}
        suppressFilterSearch={true}
        suppressRowClickSelection={true}
      />
    </div>
  );
}

export default React.forwardRef((props, ref) => <AgGridContainer agGridRef={ref} {...props} />);