import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import EmojiPicker from '@icarius-common/emojiPicker';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

const EmojiEditor = ({ handleChange }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <button
            style={{
                width: 36,
                height: 34,
                background: "transparent",
                border: 0,
                outline: 0,
            }}
        >
            <Tooltip title="Abrir selector de emojis">
                <InsertEmoticonIcon
                    style={{ cursor: 'pointer', fill: "var(--mainText)" }}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                />
            </Tooltip>
            <EmojiPicker
                anchorEl={anchorEl}
                handleChange={handleChange}
                handleClose={() => setAnchorEl(null)}
            />
        </button>
    );
}

export default EmojiEditor;