import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import CommonPage from "@icarius-common/commonPage";
import Loader from "@icarius-common/loader";
import AvatarRenderer from "@icarius-table/renderersAndFilters/avatarRenderer";
import AbsenceRenderer from "@icarius-table/renderersAndFilters/absenceRenderer";
import { createAggridColumn } from "@icarius-table/utils";
import { createDateFromYYYYMMDD } from "@icarius-utils/date";
import { formatFirstLetterUppercase } from "@icarius-utils/format";
import { getAbsentCollaboratorsAction } from "../actions";
import { getData, getIsLoading } from "../selectors";
import { RESET_STATE } from "../actionTypes";

const AbsentCollaboratorsDialog = ({ open, handleClose }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAbsentCollaboratorsAction());

    return () => {
      dispatch({ type: RESET_STATE });
    }
  }, [dispatch])

  const isLoading = useSelector(getIsLoading);
  const data = useSelector(getData);

  const ownColumnDef = useMemo(() => {
    let columnDefinitions = [
      createAggridColumn("image", "AvatarRenderer", "Foto"),
      createAggridColumn("name", "Alfa", "Apellido y Nombres"),
    ];

    if (data?.length > 0) {
      data[0].days.forEach((day, index) => {
        let dateObject = createDateFromYYYYMMDD(day.date);
        const dateWeekday = dateObject.toLocaleDateString("es", { weekday: "long", });
        const dateNumber = dateObject.toLocaleDateString("es", { day: "numeric" });
        const dateMonth = dateObject.toLocaleDateString("es", { month: "long", });

        const dateName = `${index === 0 ? "Hoy, " : ""}${formatFirstLetterUppercase(dateWeekday)} ${dateNumber} de ${formatFirstLetterUppercase(dateMonth)}`;

        columnDefinitions.push(createAggridColumn(day.date, "AbsenceRenderer", dateName));
      });
    }

    columnDefinitions.push({
      ...createAggridColumn("comment", "Alfa", "Comentarios"),
      width: 500,
    });

    return columnDefinitions;
  }, [data])

  const formattedData = useMemo(() => {
    return data.map((item) => {
      const aux = { ...item };

      item.days.forEach((day) => {
        aux[day.date] = day.type;
      });

      return aux;
    })
  }, [data])

  if (isLoading) return <Loader open={isLoading} />

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"lg"}
      fullWidth
    >
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Colaboradores ausentes"}
          <DialogTitleDivider />
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <CommonPage
            gridTitle={"Colaboradores ausentes"}
            ownColumnDef={ownColumnDef}
            rowData={formattedData}
            gridHeight={'70vh'}
            ignoreProcessesbyPath
            frameworkComponents={{ AvatarRenderer, AbsenceRenderer }}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default AbsentCollaboratorsDialog;