import React from "react";

const HorizontalLabel = ({ size }) => {

  const horizontalLabelStyle = {
    color: "white",
    textAlign: "center",
    width: size,
    height: 25,
    marginTop: 10,
    paddingTop: 5,
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', paddingLeft: 70 }}>
        <div className="ninebox-low-performance" style={horizontalLabelStyle}>
          Bajo
        </div>
        <div className="ninebox-medium-performance" style={horizontalLabelStyle}>
          Medio
        </div>
        <div className="ninebox-high-performance" style={horizontalLabelStyle}>
          Alto
        </div>
      </div>
      <div
        className="whiteText"
        style={{ textAlign: "center", paddingLeft: 70, marginTop: 10 }}
      >
        Desempeño
      </div>
    </div>
  )
}

export default HorizontalLabel;