import React from "react";
import { Typography, Tooltip } from "@material-ui/core";

const DateDisplay = ({ label, date }) => (
    <Tooltip title={date}>
        <div style={{ display: "flex" }}>
            <Typography variant="subtitle1">
                {label}:
            </Typography>
            <Typography variant="subtitle1" style={{ paddingLeft: 10, fontWeight: 600 }}>
                {date}
            </Typography>
        </div>
    </Tooltip>
)

export default DateDisplay;