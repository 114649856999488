import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import SelectedUsersColumn from "./components/selectedUsersColumn";
import UserSelectionColumn from "./components/userSelectionColumn";
import useHandleUserSelection from "./useHandleUserSelection";

const UserSelectionDialog = (props) => {

  const {
    open,
    initialData, // array de strings con key de los preseleccionados, si no hay, [] o [""]
    users, // array de objetos con key, name y FOTOGRAFIA o img o image
    title,
    selectedUsersTitle,
    filterPlaceholder, // texto opcional para el placeholder del input
    helperText, // texto opcional que va abajo de todo
    singleSelection, // bool indicando si es para seleccionar de a uno o varios
    loggedUserIsDisabled, // bool indicando si el usuario logueado esta desactivado
    required, // validar si hay seleccionados antes del submit
    handleClose,
    handleSubmit,
  } = props;

  const dispatch = useDispatch();

  const {
    keysSelected,
    checkedList,
    handleSelection,
  } = useHandleUserSelection(users, initialData, singleSelection);

  const handleSubmitUsers = () => {
    if (required && !keysSelected.length) {
      const message = singleSelection ? 'Debe seleccionar un usuario' : 'Debe seleccionar al menos un usuario';
      dispatch(openSnackbarAction({ msg: message, severity: 'error' }));
      return;
    }

    handleSubmit(keysSelected);
    handleClose();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <SelectedUsersColumn
            employees={checkedList}
            title={selectedUsersTitle}
            showAmount={!singleSelection}
            handleClick={handleSelection}
            loggedUserIsDisabled={loggedUserIsDisabled}
          />
          <UserSelectionColumn
            employees={checkedList}
            filterPlaceholder={filterPlaceholder}
            handleClick={handleSelection}
            loggedUserIsDisabled={loggedUserIsDisabled}
          />
        </Grid>
        {
          helperText &&
          <Typography className="whiteText" align="center" style={{ padding: 10 }}>
            {helperText}
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={handleSubmitUsers} isAccept text={getLocalizedString("save")} />
      </DialogActions>
    </Dialog>
  );
}

export default UserSelectionDialog;