import React from "react";
import { Typography } from "@material-ui/core";
import { fieldTypes } from "@icarius-utils/constants";
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const ReadOnlyInput = (props) => {
    const {
        label,
        value,
        type,
        addressHref,
    } = props;

    const userData = useSelector(getUserData);

    if (type === fieldTypes.email) {
        return (
            <div style={{ height: 48 }}>
                <Typography className="whiteText" variant="caption">{label}</Typography>
                <Typography className="whiteText">
                    {
                        (value && value !== "-") ?
                            <a
                                className="whiteText"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`mailto:${value}?subject=${getLocalizedString("messageFrom")}${userData.name}`}
                            >
                                {value}
                            </a>
                            :
                            <span>-</span>
                    }
                </Typography>
            </div>
        );
    }

    if (type === fieldTypes.phone) {
        return (
            <div style={{ height: 48 }}>
                <Typography className="whiteText" variant="caption">{label}</Typography>
                <Typography className="whiteText">
                    {
                        (value && value !== "-" && value !== "+") ?
                            <a
                                className="whiteText"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`tel: ${value}`}
                            >
                                {value}
                            </a>
                            :
                            <span>-</span>
                    }
                </Typography>
            </div>
        );
    }

    if (type === fieldTypes.address) {
        return (
            <div style={{ height: 48 }}>
                <Typography className="whiteText" variant="caption">{label}</Typography>
                <Typography className="whiteText">
                    {
                        (value && addressHref) ?
                            <a
                                className="whiteText"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={addressHref}
                            >
                                {value}
                            </a>
                            :
                            <span className={"value"}>-</span>
                    }
                </Typography>
            </div>
        );
    }

    if (type === fieldTypes.number) {
        return (
            <div style={{ height: 48 }}>
                <Typography className="whiteText" variant="caption">{label}</Typography>
                <Typography className="whiteText">{formatNumberExactDecimals(value) || "-"}</Typography>
            </div>
        );
    }

    if (type === fieldTypes.check) {
        return (
            <div style={{ height: 48 }}>
                <Typography className="whiteText" variant="caption">{label}</Typography>
                <Typography className="whiteText">{value ? "Si" : "No"}</Typography>
            </div>
        );
    }

    return (
        <div style={{ height: 48 }}>
            <Typography className="whiteText" variant="caption">{label}</Typography>
            <Typography className="whiteText">{value || "-"}</Typography>
        </div>
    );
}

export default ReadOnlyInput;