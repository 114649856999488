import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;
export const getAutomaticProcessesIsEnabled = store => store[NAME].isEnabled;
export const getProcessList = store => store[NAME].processList;
export const getLocale = store => store[NAME].locale;
export const getDateFormat = store => store[NAME].dateFormat;
export const getResult = store => store[NAME].result;
export const getTemplates = store => store[NAME].templates;
export const getProcess = store => store[NAME].process;
