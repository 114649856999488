import React from "react";
import { IconButton } from "@material-ui/core";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";

const ZoomButtons = ({ zoomIn, zoomOut }) => {

  return (
    <div style={{ position: 'absolute', right: 25 }}>
      <IconButton onClick={zoomOut}>
        <ZoomOutIcon style={{ fill: 'var(--icons)' }} />
      </IconButton>
      <IconButton onClick={zoomIn}>
        <ZoomInIcon style={{ fill: 'var(--icons)' }} />
      </IconButton>
    </div>
  )
};

export default ZoomButtons;