import React from 'react';
import { useSelector } from 'react-redux';
import { getAppColor } from 'src/app/selectors';

const Mention = ({ entityKey, mention, className, decoratedText }) => {

    const color = useSelector(getAppColor);
    return (
        <div style={{ padding: 4, background: color, color: 'white', borderRadius: 10, display: 'inline' }}>
            {decoratedText}
        </div>
    )
};

export default Mention;