import ProfileImg from '@icarius-common/profileImg';
import { MenuItem } from '@material-ui/core';
import React from 'react';

const MentionOption = (props) => {
    const {
        mention,
        onMouseDown,
        onMouseEnter,
        onMouseUp,
    } = props;

    return (
        <MenuItem
            id={mention.code}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseUp={onMouseUp}
        >
            <ProfileImg
                image={mention.img}
                size={30}
            />
            <div style={{ paddingLeft: 10 }}>
                {mention.name}
            </div>
        </MenuItem>
    );
}

export default MentionOption;