import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import {
    getMyReceiptsListFromServer,
    downloadMyReceiptFromServer,
    getMyCertificateListFromServer,
    downloadMyCertificateFromServer,
} from "../actions";
import {
    getIsLoading,
    getMyCertificateList,
    getMyReceiptsList,
    getReceiverList,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";

const useHandleMyDocuments = (isCertificate, isHistorical = false, handleClose) => {

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedProcess, setSelectedProcess] = useState(null);
    const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);

    const dispatch = useDispatch();

    const isLoading = useSelector(getIsLoading);
    const myCertificateList = useSelector(getMyCertificateList);
    const myReceiptList = useSelector(getMyReceiptsList);
    const receiverList = useSelector(getReceiverList);

    const documentList = useMemo(() => {
        return myCertificateList || myReceiptList;
    }, [myCertificateList, myReceiptList])

    useEffect(() => {
        dispatch(isCertificate ? getMyCertificateListFromServer() : getMyReceiptsListFromServer(isHistorical))
            .then((resp) => {
                const auxDocumentList = (resp?.data?.certificates || resp?.data?.receipts || []);

                if (!auxDocumentList?.length) {
                    dispatch(
                        openSnackbarAction({
                            msg: getLocalizedString(isCertificate ? "noCertificates" : "noReceipts"),
                            severity: "warning"
                        })
                    );
                    handleClose();
                }
            })

        return () => {
            dispatch({ type: RESET_STATE });
        };
    }, [dispatch, isCertificate, isHistorical, handleClose])

    const handleGenerate = (submittedDocument, submittedProcess, submittedReceiver) => {
        // si es recibos, submittedDocument es un objeto, si es certificados, es solo el code
        const documentObject = isCertificate ? documentList.find((document) => document.code === submittedDocument) : submittedDocument;
        const processReferences = [];

        // es recibo
        if (!isCertificate) {
            if (Array.isArray(submittedProcess)) {
                submittedProcess.forEach((process) => {
                    const processObject = documentObject?.calculation_processes?.find((calcProcess) => calcProcess.reference === process);
                    if (processObject) {
                        processReferences.push(processObject.reference);
                    }
                })
            } else {
                processReferences.push(documentObject?.calculation_processes?.find((calcProcess) => calcProcess.reference === submittedProcess));
            }
        }

        if (isHistorical) {
            Promise.all(processReferences.map((process) => {
                return dispatch(downloadMyReceiptFromServer(documentObject.code, process))
            })).then(() => handleClose());
        } else {
            // es certificado, o recibo no historico
            checkRequiresConfirmation(documentObject, processReferences[0]);
            dispatch(
                isCertificate
                    ? downloadMyCertificateFromServer({ code: documentObject.code, receiver: submittedReceiver })
                    : downloadMyReceiptFromServer(documentObject.code, processReferences[0].reference)
            );
        }
    };

    const checkRequiresConfirmation = (document, process) => {
        if (isCertificate) {
            if (document.requires_confirmation) {
                handleOpenConfirmDialog(document);
                return;
            }

            handleClose();
            return;
        }

        // es recibo pero ya fue confirmado antes
        if (process.accepted_date && process.accepted_time) {
            dispatch(openDialogAction({
                title: "Atención",
                msg: `${getLocalizedString("myreceiptsMessage").replace("{accepted_date}", process.accepted_date).replace("{accepted_time}", process.accepted_time)}.`,
                acceptOnly: true,
                acceptText: "Cerrar",
                onConfirm: () => handleClose(),
                maxWidth: "sm",
                fullWidth: true,
            }))
            return;
        }

        // es recibo y requiere confirmacion
        if (document.requires_confirmation || process.requires_confirmation) {
            handleOpenConfirmDialog(document, process);
            return;
        }

        handleClose();
    };

    const handleOpenConfirmDialog = (document, process) => {
        setSelectedDocument(document);
        setSelectedProcess(process);
        setConfirmDialogIsOpen(true);
    };

    return {
        documentList,
        receiverList,
        isLoading,
        selectedDocument,
        selectedProcess,
        confirmDialogIsOpen,
        handleGenerate,
    }
}


export default useHandleMyDocuments;
