import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import TreeItem from '@material-ui/lab/TreeItem';
import ProfileImg from "@icarius-common/profileImg";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const Collaborator = ({ data, index, classes }) => {

  const color = useSelector(getAppColor);

  return (
    <TreeItem
      nodeId={`collaborator-${data.code}-${index}`}
      label={
        <div className={classes.labelRoot}>
          <ProfileImg
            image={data.image}
            size={20}
          />
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Tooltip title={`Responsable: ${data?.responsibleName || "Sin asignar"}`} arrow>
              <Typography variant="body2" className={classes.labelText} style={{ color: data.status === "D" ? color : "" }}>
                {data.name} <b>({data.actionStatusName})</b>
              </Typography>
            </Tooltip>
          </div>
        </div>
      }
    />
  )
}

export default Collaborator;