import React, { useState, useEffect } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { FormControlLabel, Switch } from "@material-ui/core";
import { ShrinkIcon, ExpandIcon, BorderColorIcon } from "@icarius-icons";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const GridMenu = React.forwardRef((props, refs) => {

    const [showExpand, setShowExpand] = useState(true);
    const [gridIsSelected, setGridIsSelected] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const {
        customItems,

        hasSwitch,
        switchValue,
        handleSwitchClick,
        switchLabelOn,
        switchLabelOff,

        hasSelectAll,

        hasExpand,
        amountOfGroups,

        hasHelp,
        helpItems,
    } = props;

    const handleOpenHelpMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseHelpMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setShowExpand(true);
    }, [amountOfGroups])

    const expand = () => {
        expandGroups();
        setShowExpand(false);
    }

    const shrink = () => {
        shrinkGroups();
        setShowExpand(true);
    }

    const select = () => {
        selectAllRange();
    }

    const shrinkGroups = () => {
        refs.api.forEachNode(node => {
            if (node.group) node.setExpanded(false);
        });
    };

    const expandGroups = () => {
        refs.api.forEachNode(node => {
            if (node.group) node.setExpanded(true);
        });
    };

    const selectAllRange = () => {
        if (typeof refs?.columnApi.getAllDisplayedColumns !== "undefined") {
            if (gridIsSelected === false && refs.api.getCellRanges().length === 0) {
                refs.api.clearRangeSelection();
                refs.api.addCellRange({
                    rowStartIndex: 0,
                    rowEndIndex: refs.api.getDisplayedRowCount() - 1,
                    columns: refs.columnApi.getAllDisplayedColumns(),
                });
            } else {
                refs.api.clearRangeSelection();
            }
            setGridIsSelected(!gridIsSelected);
        }
    };

    return (
        <>
            <div style={{ marginLeft: "auto" }} /> {/* para que este todo para la derecha*/}
            {
                hasSwitch &&
                <FormControlLabel
                    control={
                        <Switch
                            checked={switchValue}
                            onChange={handleSwitchClick}
                            value={switchValue}
                            color="primary"
                        />
                    }
                    label={switchValue ? switchLabelOn : switchLabelOff}
                    labelPlacement="start"
                    style={{ marginRight: 5 }}
                />
            }
            {
                customItems && customItems.length > 0 &&
                customItems.map((item, index) => {
                    return <div key={index}>{item(refs)}</div>
                })
            }
            {
                hasSelectAll &&
                <CustomIconButton
                    title={getLocalizedString("sombrear")}
                    onClick={select}
                >
                    <BorderColorIcon />
                </CustomIconButton>
            }
            {
                hasExpand && amountOfGroups !== 0 &&
                <CustomIconButton
                    title={showExpand ? getLocalizedString("expand") : getLocalizedString("shrink")}
                    onClick={showExpand ? expand : shrink}
                >
                    {
                        showExpand ? <ExpandIcon /> : <ShrinkIcon />
                    }
                </CustomIconButton>
            }
            {
                hasHelp &&
                <>
                    <CustomIconButton
                        title={getLocalizedString("help")}
                        onClick={handleOpenHelpMenu}
                        type={"help"}
                    />
                    <StyledHelpMenu
                        anchorEl={anchorEl}
                        isOpen={Boolean(anchorEl)}
                        onClose={handleCloseHelpMenu}>
                        {
                            helpItems
                        }
                        <MenuItemWithIcon
                            value={"https://www.youtube.com/embed/vKBETFVm2jg"}
                            text={getLocalizedString("gridVideo").replace("{}", 1)}
                            type={"video"}
                        />
                        <MenuItemWithIcon
                            value={"https://www.youtube.com/embed/XQLa9TDwVtA"}
                            text={getLocalizedString("gridVideo").replace("{}", 2)}
                            type={"video"}
                        />
                        <MenuItemWithIcon
                            value={"https://www.youtube.com/embed/fmL5TxtN6Co"}
                            text={getLocalizedString("gridVideo").replace("{}", 3)}
                            type={"video"}
                        />
                    </StyledHelpMenu>
                </>
            }
        </>
    )
})

export default GridMenu;