import React from 'react';
import Editor from '@draft-js-plugins/editor';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
    createBlockStyleButton,
} from '@draft-js-plugins/buttons';
import EmojiEditor from './emojiEditor';
import {
    MentionOption,
    MentionSuggestionContainer,
} from './mentions';
import useTextEditor from './useTextEditor';
import '@draft-js-plugins/linkify/lib/plugin.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { Typography } from '@material-ui/core';

const H1Button = createBlockStyleButton({
    blockType: 'header-one',
    children: "T1",
});
const H2Button = createBlockStyleButton({
    blockType: 'header-two',
    children: "T2",
});
const H3Button = createBlockStyleButton({
    blockType: 'header-three',
    children: "T3",
});

const TextEditor = (props) => {

    const {
        initialValue,
        readOnly,
        placeholder,
        suggestionOptions,
        maxLength = 1000,
        fontSize,
        lineHeight, // tiene que venir como string con px al final
        handleChange,
        helperText,
    } = props;

    const {
        editorState,
        filteredMentionSuggestions,
        mentionSuggestionsIsOpen,
        plugins,
        onEditorChange,
        onSuggestionsIsOpenChange,
        onSuggestionsSearchChange,
        insertText,
        handleBeforeInput,
        handlePastedText,
        MentionSuggestionsSelector,
        Toolbar,
    } = useTextEditor(initialValue, suggestionOptions, handleChange, maxLength);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <div
                    style={{
                        flex: '1 1 auto',
                        fontSize: fontSize || 16,
                        lineHeight: lineHeight || '28px',
                        width: 1,
                        border: readOnly ? 0 : '1px solid var(--icons)',
                        borderRadius: 4,
                        padding: readOnly ? 0 : 10,
                    }}
                >
                    <Editor
                        readOnly={readOnly}
                        editorState={editorState}
                        plugins={plugins}
                        placeholder={placeholder}
                        onChange={onEditorChange}
                        handleBeforeInput={handleBeforeInput}
                        handlePastedText={handlePastedText}
                    />
                    {
                        !readOnly &&
                        <div style={{ marginTop: 20 }}>
                            <Toolbar>
                                {
                                    (toolbarProps) => (
                                        <>
                                            <EmojiEditor
                                                handleChange={(value) => insertText(value)}
                                                closeOnSelection
                                            />
                                            <BoldButton {...toolbarProps} />
                                            <ItalicButton {...toolbarProps} />
                                            <UnderlineButton {...toolbarProps} />
                                            <UnorderedListButton {...toolbarProps} />
                                            <OrderedListButton {...toolbarProps} />
                                            <H1Button {...toolbarProps} />
                                            <H2Button {...toolbarProps} />
                                            <H3Button {...toolbarProps} />
                                        </>
                                    )
                                }
                            </Toolbar>
                        </div>
                    }
                    {
                        Boolean(helperText) &&
                        <Typography className="whiteText" variant="caption" style={{ fontWeight: 500, display: "block", marginTop: 10 }}>
                            {helperText}
                        </Typography>
                    }
                </div>
            </div>
            <MentionSuggestionsSelector
                open={mentionSuggestionsIsOpen}
                suggestions={filteredMentionSuggestions}
                entryComponent={MentionOption}
                onOpenChange={onSuggestionsIsOpenChange}
                onSearchChange={onSuggestionsSearchChange}
                popoverContainer={MentionSuggestionContainer}
            />
        </>
    );
}

export default TextEditor;