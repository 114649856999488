import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Typography,
  Grid,
  Checkbox,
  TextField,
  FormControlLabel
} from "@material-ui/core";

const ConfirmationForm = (props) => {

  const {
    handleCheckboxChange,
    handleTextChange,
    handlePinChange,
    checked,
    text,
    pin
  } = props;

  return (
    <Grid container justify="center" alignItems="center" item xs={12} direction={"row"}>
      <Grid item container justify="center" alignItems="center" xs={12} direction={"row"}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleCheckboxChange}
              checked={checked}
            />
          }
          label="Acepto los terminos y condiciones" />
      </Grid>
      <Grid item container justify="flex-start" alignItems="center" xs={12}>
        <TextField
          value={text}
          onChange={(e) => handleTextChange(e.target.value)}
          margin={"none"}
          onInput={e => {
            e.target.value = e.target.value.toString().slice(0, 250);
          }}
          label={'Observaciones'}
          multiline={true}
          rows={1}
          rowsMax={3}
          fullWidth
          helperText={`${getLocalizedString("currentChar")} ${text ? text.length : 0}. ${getLocalizedString("maxCharAnnex")}.`}
        />
        <Typography variant="caption" className="whiteText">
          {'Las observaciones son obligatorias si decide rechazar el documento'}
        </Typography>
      </Grid>
      <Grid item container justify="center" alignItems="center" xs={12}>
        <TextField
          fullWidth
          required
          type="text"
          label="user"
          style={{ opacity: "0%", width: 1, height: 1, position: "absolute", left: 0, top: 0 }}
        />
        <TextField
          fullWidth
          required
          type="password"
          label="PIN"
          value={pin}
          onChange={(e) => handlePinChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

export default ConfirmationForm;
