import React from "react";
import ReactPhoneInput from 'react-phone-input-material-ui';
import phoneLocaleSpanish from '@icarius-utils/phoneLocaleSpanish.json'
import { TextField } from "@material-ui/core";
import "react-phone-input-material-ui/lib/style.css";

const PhoneInput = (props) => {
    const {
        fieldConfig,
        label,
        value,
        handleChange,
        error,
    } = props;

    return (
        <ReactPhoneInput
            value={value}
            onChange={(value) => handleChange(!value.includes("+") ? "+" + value : value, fieldConfig.name)}
            component={TextField}
            inputProps={{
                label: label,
                InputLabelProps: { shrink: true },
                maxLength: fieldConfig.max,
                error: error,
            }}
            style={{ width: '100%' }}
            margin={"none"}
            autoFormat={false}
            regions={['america', 'europe']}
            localization={phoneLocaleSpanish}
            placeholder=""
        />
    )
}

export default PhoneInput;