import React, { useState } from "react";
import { Card, DialogActions, Typography, } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";

const Wizard = (props) => {
  const {
    showAll,
    initialValues,
    isCompleted,
    onSubmit,
    onClose,
    children,
    isAnonymous,
    hasAnswers,
    handleViewAnswers,
  } = props;

  const [formData, setFormData] = useState(initialValues);
  const questionsLength = React.Children.count(children);

  const [questionIndex, setQuestionIndex] = useState(0);
  const [errors, setErrors] = useState(Array(questionsLength).fill(''));

  const questionsArray = React.Children.toArray(children);
  const activeQuestion = questionsArray[questionIndex];
  const isLastQuestion = questionIndex === questionsLength - 1;

  const next = () => setQuestionIndex(prev => Math.min(prev + 1, children.length - 1));

  const previous = () => {
    setQuestionIndex(prev => Math.max(prev - 1, 0));
    setErrors(Array(questionsLength).fill(''));
  };

  const validate = () => {
    let isValid = true;
    const errorsToSet = Array(questionsLength).fill('');

    if (showAll) {
      for (const [index, questionId] of Object.keys(formData).entries()) {
        if (!Boolean(formData[questionId])) {
          errorsToSet[index] = getLocalizedString("answerPollNotice");
          isValid = false;
        }
      }
    } else {
      if (!Boolean(formData[Object.keys(formData)[questionIndex]])) {
        errorsToSet[questionIndex] = getLocalizedString("answerPollNotice");
        isValid = false;
      }
    }

    setErrors(errorsToSet);
    return isValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      setErrors(Array(questionsLength).fill(''));

      if (isLastQuestion || showAll) return onSubmit(formData);
      next();
    }
  };

  const setFormValue = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <>
      {
        (showAll ? questionsArray : [activeQuestion]).map((item, showAllIndex) => {
          const indexToUse = showAll ? showAllIndex : questionIndex;

          return (
            <Card key={indexToUse} style={{ marginBottom: 20, padding: 20 }}>
              <Typography variant={"subtitle1"} className={"no-contact-selected-container-text whiteText"}>
                {getLocalizedString("pollInfo").replace("{actualNumber}", indexToUse + 1).replace("{totalNumber}", questionsLength)}
              </Typography>
              {
                Boolean(item) &&
                React.cloneElement(
                  item,
                  {
                    error: errors[indexToUse],
                    value: formData[item.props.id],
                    onChange: (value) => setFormValue(value, item.props.id),
                  }
                )
              }
            </Card>
          )
        })
      }
      {isAnonymous &&
        <Typography
          style={{ marginTop: "15px", textAlign: "center", fontWeight: 600 }}
          className={"whiteText"}>
          {getLocalizedString("anonymousPoll")}
        </Typography>
      }
      <DialogActions>
        {
          hasAnswers && isCompleted &&
          <ButtonDialogAction onClick={handleViewAnswers} text={'Ver respuestas'} />
        }
        {
          questionIndex > 0 && !showAll &&
          <ButtonDialogAction onClick={previous} text={getLocalizedString("previous")} />
        }
        {
          !isLastQuestion && !showAll &&
          <ButtonDialogAction isAccept type="submit" onClick={handleSubmit} text={getLocalizedString("next")} />
        }
        {
          ((isLastQuestion || showAll) && !isCompleted) &&
          <ButtonDialogAction isAccept type="submit" onClick={handleSubmit} text={getLocalizedString("submit")} />
        }
        {
          ((isLastQuestion && isCompleted) || showAll) &&
          <ButtonDialogAction isAccept onClick={onClose} text={getLocalizedString("close")} />
        }
      </DialogActions>
    </>
  );
}

export default Wizard;