import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import Answer from "./answer";

const ViewAnswersDialog = (props) => {

  const {
    open,
    questionText,
    answers,
    handleClose,
  } = props;

  const totalAmount = answers.reduce((acc, answer) => acc + answer.amount, 0);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {'Respuestas'}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Typography className="whiteText" style={{ fontSize: 20, marginBottom: 20 }}>
          {questionText}
        </Typography>
        {
          answers.map((item, index) => {
            return (
              <div key={index}>
                <Answer
                  option={item.option}
                  amount={item.amount}
                  total={totalAmount}
                />
              </div>
            )
          })
        }
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} isAccept text={'Cerrar'} />
      </DialogActions>
    </Dialog>
  );
}

export default ViewAnswersDialog;