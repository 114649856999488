import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
const onMediaFallback = event => event.target.src = DefaultImage;

const User = ({ name, image }) => {

    const color = useSelector(getAppColor);
    const style = { height: 65, width: 64, borderRadius: '100%', background: image ? '' : color }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                background: 'var(--medalTableHeader)',
                padding: 10,
            }}
        >
            {
                image ?
                    <img
                        src={IMAGES_ENDPOINT + image}
                        onError={onMediaFallback}
                        alt=""
                        style={style}
                    />
                    :
                    <div style={style} />
            }
            <Typography className="whiteText" style={{ fontSize: 20, marginLeft: 20 }}>
                {name}
            </Typography>
        </div>
    )
}

export default User;
