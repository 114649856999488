import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Typography } from "@material-ui/core";
import BlockIcon from '@material-ui/icons/Block';
import Item from "./item";

const Column = (props) => {

    const {
        id,
        disabled,
        data,
    } = props;

    return (
        <>
            {
                disabled &&
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 10,
                    }}
                >
                    <BlockIcon style={{ fill: "var(--mainText)" }} />
                    <Typography className="whiteText" align="center">
                        No puedes moverlo a esta columna.
                    </Typography>
                </div>
            }
            <Droppable
                droppableId={id}
                isDropDisabled={disabled}
            >
                {
                    (provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                                height: "100%",
                                minHeight: 100,
                                borderRadius: 10,
                                display: disabled ? "none" : "block",
                            }}
                        >
                            {
                                data.map((item, index) => (
                                    <Item
                                        key={index}
                                        index={index}
                                        data={item}
                                    />
                                ))
                            }
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        </>
    );
}

export default Column;