class BoardLogic {
  observers = [];
  positions = [];

  constructor(positions) {
    this.positions = positions;
  }

  observe(observer) {
    this.observers.push(observer);
    this.emitChange();

    return () => {
      this.observers = this.observers.filter((item) => item !== observer);
    };
  }

  movePiece(boardPiece, toX, toY, comment) {
    const pieceIndex = this.positions.findIndex(
      (el) =>
        el.position.x === boardPiece.item.position.x &&
        el.position.y === boardPiece.item.position.y &&
        el.name === boardPiece.item.name
    );

    let clonedArray = JSON.parse(JSON.stringify(this.positions));

    clonedArray[pieceIndex] = {
      ...clonedArray[pieceIndex],
      position: { x: toX, y: toY },
      calibrationComments: comment,
      moved: true,
    };

    this.positions = clonedArray;
    this.emitChange();
  }

  emitChange() {
    this.observers.forEach((observer) => {
      if (observer) {
        observer(this.positions);
      }
    });
  }
}

export default BoardLogic;