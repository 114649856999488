import React from "react";
import { Typography } from "@material-ui/core";
import TreeView from '@material-ui/lab/TreeView';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EventIcon from '@material-ui/icons/Event';
import FastForwardIcon from '@material-ui/icons/FastForward';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';
import Material from "./material";
import Collaborator from "./collaborator";

const useStyles = makeStyles({
    root: {
        minHeight: 350,
        flexGrow: 1,
        maxWidth: 850,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        minHeight: 28,
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
});

const OnboardingTree = ({ data, hideNoNecessary, hideStopped }) => {

    const classes = useStyles();

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={["root"]}
        >
            <TreeItem
                nodeId={"root"}
                label={
                    <div className={classes.labelRoot}>
                        <PersonAddIcon color="inherit" className={classes.labelIcon} />
                        <Typography variant="body2" className={classes.labelText}>
                            {data.name}
                        </Typography>
                    </div>
                }
            >
                {
                    data.moments?.map((moment, indexMoment) => {
                        return (
                            <TreeItem
                                key={`moment-${moment.code}-${indexMoment}`}
                                nodeId={`moment-${moment.code}-${indexMoment}`}
                                label={
                                    <div className={classes.labelRoot}>
                                        <EventIcon color="inherit" className={classes.labelIcon} />
                                        <Typography variant="body2" className={classes.labelText}>
                                            {`Momento: ${moment.name}`}
                                        </Typography>
                                    </div>
                                }
                            >
                                {
                                    moment.actions?.map((action, indexAction) => {

                                        const materials = (action?.materials || [])?.map((material, indexMaterial) => {
                                            return (
                                                <Material
                                                    key={`material-${material.code}-${indexMaterial}`}
                                                    data={material}
                                                    index={indexMaterial}
                                                    classes={classes}
                                                />
                                            )
                                        });

                                        const collaborators = (action?.collaborators || [])?.map((collaborator, indexCollaborator) => {
                                            if (collaborator.status === "D" && hideStopped) return null;
                                            if (collaborator.actionStatus === "N" && hideNoNecessary) return null;

                                            return (
                                                <Collaborator
                                                    key={`collaborator-${collaborator.code}-${indexCollaborator}`}
                                                    data={collaborator}
                                                    index={indexCollaborator}
                                                    classes={classes}
                                                />
                                            )
                                        });

                                        // hay que hacer esto porque sino muestra la flecha aunque no tenga nada
                                        const children = [...materials, ...collaborators];

                                        return (
                                            <TreeItem
                                                key={`action-${action.code}-${indexAction}`}
                                                nodeId={`action-${action.code}-${indexAction}`}
                                                label={
                                                    <div className={classes.labelRoot}>
                                                        <FastForwardIcon color="inherit" className={classes.labelIcon} />
                                                        <Typography variant="body2" className={classes.labelText}>
                                                            {action.name}
                                                        </Typography>
                                                    </div>
                                                }
                                            >
                                                {children}
                                            </TreeItem>
                                        )
                                    })
                                }
                            </TreeItem>
                        )
                    })
                }
            </TreeItem>
        </TreeView>
    );
}

export default OnboardingTree;