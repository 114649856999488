import React from "react";

const FreeUsersLabel = ({ size }) => {

  const freeUsersLabelStyle = {
    textAlign: "center",
    width: size,
    marginTop: 5,
    marginBottom: 5,
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className="whiteText" style={freeUsersLabelStyle}>
        Desempeño 1
      </div>
      <div className="whiteText" style={freeUsersLabelStyle}>
        Desempeño 2
      </div>
      <div className="whiteText" style={freeUsersLabelStyle}>
        Desempeño 3
      </div>
    </div>
  )
}

export default FreeUsersLabel;