import React from "react";
import { Typography, Grid, Tooltip } from "@material-ui/core";

const DataContainer = ({ text, children, xs, sm, md, lg }) => (
    <Grid container item xs={xs || 12} sm={sm || 4} md={md || 3} lg={lg || 2} justify="flex-start" alignItems="center">
        {
            text ?
            <Tooltip title={text}>
                <Typography noWrap variant="subtitle1" style={{ fontWeight: 600 }}>
                    {text}
                </Typography>
            </Tooltip>
            :
            children || null
        }
    </Grid>
)

export default DataContainer;