
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Grid,
  GridList,
  GridListTile,
  Typography,
  Button,
} from "@material-ui/core";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { getAppColor, getAvailableMenus } from "src/app/selectors";
import { ImagePlaceholder } from "@icarius-icons/index";
import CommonPage from "@icarius-common/commonPage";
import useGetScreenWidth from "@icarius-utils/hooks/useGetScreenWidth";

// props disponbiles para los objetos de data:
// - name
// - icon
// - image
// - path
// - amount
// - ignoreDecoration // para no utilizar el espacio para image/icon
// - ignorePathValidation // para no chequear si el path esta en availableMenus
// - onClick

const ScreenSelector = (props) => {

  const {
    title,
    data,
    small,
  } = props;

  const width = useGetScreenWidth();
  const history = useHistory();
  const availableMenus = useSelector(getAvailableMenus);
  const color = useSelector(getAppColor);

  const handleOnClick = (item) => {
    if (item.onClick) return item.onClick;
    return () => history.push(item.path);
  }

  const getColumSize = () => {
    if (width >= 1280) return 1;
    if (width >= 600) return 1.5;
    return 3;
  }

  const getItemDecoration = (item) => {
    if (item.ignoreDecoration) return null;
    if (item.icon) return item.icon;
    if (item.image) return (
      <img
        src={IMAGES_ENDPOINT + item.image}
        id={"logo"}
        alt={item.name}
        style={{ maxWidth: 200, maxHeight: 100 }}
      />
    );

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 200,
          height: 100,
          backgroundColor: color,
        }}
      >
        <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
      </div>
    );
  }

  return (
    <CommonPage
      title={title}
      isNotGridPage
    >
      <GridList
        cellHeight={260}
        cols={3}
        style={{ padding: 5 }}
      >
        {
          data?.map((item, index) => {
            if (!item.ignorePathValidation && !availableMenus.includes(item.path.replace('/', ''))) return null;

            return (
              <GridListTile
                key={index}
                style={{ maxHeight: small ? 180 : 260, padding: 5 }}
                cols={getColumSize()}
              >
                <Button
                  className="screenSelectorButton"
                  style={{ height: small ? 170 : 250 }}
                  onClick={handleOnClick(item)}
                >
                  <Grid container alignItems="center" justify="center">
                    {
                      typeof item.amount === 'number' &&
                      <div className="screenSelectorCounter">
                        <Typography style={{ color: 'white', fontSize: 18, fontWeight: "500" }}>
                          {item.amount > 999 ? '+999' : item.amount}
                        </Typography>
                      </div>
                    }
                    {
                      !item.ignoreDecoration &&
                      <Grid container item xs={12} justify="center" style={{ padding: 10 }}>
                        {getItemDecoration(item)}
                      </Grid>
                    }
                    <Grid item xs={12}>
                      <Typography className="whiteText" style={{ fontSize: small ? 16 : 20, fontWeight: "700" }}>
                        {item.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </GridListTile>
            )
          })
        }
      </GridList>
    </CommonPage>
  );
}

export default ScreenSelector;