import * as actionTypes from "./actionTypes";

const initialState = {
  digitalDocuments: [],
  dataSign: null,
  dateFormat: null,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_DIGITAL_DOCUMENTS_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_DIGITAL_DOCUMENTS_ROWS_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.GET_DIGITAL_DOCUMENTS_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        digitalDocuments: action.payload.digitalDocs,
        dataSign: action.payload.dataSign,
        dateFormat: action.payload.dateFormat
      };

    case actionTypes.START_DOWNLOAD_DIGITAL_DOCUMENT:
      return { ...state, isLoading: true };
    case actionTypes.START_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.START_DOWNLOAD_DIGITAL_DOCUMENT_FULFILLED:
      return { ...state, isLoading: false };

    case actionTypes.CONFIRM_DIGITAL_DOCUMENT:
      return { ...state, isLoading: true };
    case actionTypes.CONFIRM_DIGITAL_DOCUMENT_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.CONFIRM_DIGITAL_DOCUMENT_FULFILLED:
      return { ...state, isLoading: false };

    case actionTypes.DIRECT_DOWNLOAD_DIGITAL_DOCUMENT:
      return { ...state, isLoading: true };
    case actionTypes.DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_FULFILLED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
