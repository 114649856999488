import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

//Mis Certificados
export const GET_MY_CERTIFICATES_LIST_ROWS = NAME + "/GET_MY_CERTIFICATES_LIST_ROWS";
export const GET_MY_CERTIFICATES_LIST_ROWS_FULFILLED = NAME + "/GET_MY_CERTIFICATES_LIST_ROWS_FULFILLED";
export const GET_MY_CERTIFICATES_LIST_ROWS_REJECTED = NAME + "/GET_MY_CERTIFICATES_LIST_ROWS_REJECTED";

export const DOWNLOAD_MY_CERTIFICATE = NAME + "/DOWNLOAD_MY_CERTIFICATE";
export const DOWNLOAD_MY_CERTIFICATE_FULFILLED = NAME + "/DOWNLOAD_MY_CERTIFICATE_FULFILLED";
export const DOWNLOAD_MY_CERTIFICATE_REJECTED = NAME + "/DOWNLOAD_MY_CERTIFICATE_REJECTED";

export const CONFIRM_CERTIFICATE = NAME + "/CONFIRM_CERTIFICATE";
export const CONFIRM_CERTIFICATE_FULFILLED = NAME + "/CONFIRM_CERTIFICATE_FULFILLED";
export const CONFIRM_CERTIFICATE_REJECTED = NAME + "/CONFIRM_CERTIFICATE_REJECTED";

//Mis Recibos
export const GET_MY_RECEIPTS_LIST_ROWS = NAME + "/GET_MY_RECEIPTS_LIST_ROWS";
export const GET_MY_RECEIPTS_LIST_ROWS_FULFILLED = NAME + "/GET_MY_RECEIPTS_LIST_ROWS_FULFILLED";
export const GET_MY_RECEIPTS_LIST_ROWS_REJECTED = NAME + "/GET_MY_RECEIPTS_LIST_ROWS_REJECTED";

export const DOWNLOAD_MY_RECEIPT = NAME + "/DOWNLOAD_MY_RECEIPT";
export const DOWNLOAD_MY_RECEIPT_FULFILLED = NAME + "/DOWNLOAD_MY_RECEIPT_FULFILLED";
export const DOWNLOAD_MY_RECEIPT_REJECTED = NAME + "/DOWNLOAD_MY_RECEIPT_REJECTED";

export const CONFIRM_RECEIPT = NAME + "/CONFIRM_RECEIPT";
export const CONFIRM_RECEIPT_FULFILLED = NAME + "/CONFIRM_RECEIPT_FULFILLED";
export const CONFIRM_RECEIPT_REJECTED = NAME + "/CONFIRM_RECEIPT_REJECTED";