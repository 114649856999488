import React from 'react';
import { Popper } from '@material-ui/core';

const MentionSuggestionContainer = ({ store, children }) => {

    return (
        <Popper
            open
            anchorEl={store.getReferenceElement()}
            className='myDesktopShadow'
            placement="bottom-start"
            style={{
                zIndex: 99999,
                background: 'var(--secondaryBackgroundColor)',
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 4,
                maxHeight: 300,
                overflow: 'auto',
            }}
        >
            {children}
        </Popper>
    );
}

export default MentionSuggestionContainer;