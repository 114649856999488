import React from "react";
import {
  FormControlLabel,
  TextField,
  Typography,
  Grid,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { previewFromURL } from "@icarius-utils/download";
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import MoodIcon from '@material-ui/icons/Mood';
import { generalColors } from "@icarius-utils/colors";

const Question = (props) => {

  const {
    color,
    type,
    options,
    title,
    error,
    image,
    isNPS,
    isCompleted,
    onChange,
    value,
  } = props;

  const getNPSIcon = (index) => {
    if (index > 7) return <MoodIcon style={{ fill: generalColors.green }} />;
    if (index > 5) return <SentimentSatisfiedIcon style={{ fill: generalColors.yellow }} />;
    return <MoodBadIcon style={{ fill: generalColors.red }} />;
  }

  const renderQuestion = () => {
    if (isNPS && type === 'radio') {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {
            options.map((item, index) => {
              const isSelected = item === value;
              return (
                <div key={item} onClick={() => !isCompleted && onChange(item)} style={{ padding: 10, margin: 10, cursor: !isCompleted && 'pointer' }}>
                  {getNPSIcon(index)}
                  <Typography align="center" style={{ color: isSelected ? color : 'var(--mainText)', fontWeight: isSelected && 600 }}>
                    {item}
                  </Typography>
                </div>
              )
            })
          }
        </div>
      );
    }
    if (type === 'radio') {
      return (
        <RadioGroup value={value} onChange={(e) => onChange(e.target.value)} row>
          {
            options.map(item => {
              return (
                <FormControlLabel labelPlacement="start" key={item} value={item} control={<Radio />} label={item} disabled={isCompleted} />
              )
            })
          }
        </RadioGroup>
      );
    };
    if (type === 'text') {
      return (
        <TextField
          disabled={isCompleted}
          value={value}
          onChange={(e) => onChange(e.target.value.toString().slice(0, 254))}
          onInput={e => e.target.value = e.target.value.toString().slice(0, 254)}
          multiline={true}
          rows={3}
          rowsMax={6}
          fullWidth
          margin={"none"}
        />
      )
    };
    return null;
  }

  return (
    <section>
      <Typography variant={"h6"} className={"label whiteText"}>
        {title}
      </Typography>
      <Grid item xs={12}>
        {renderQuestion()}
      </Grid>
      {
        Boolean(image) &&
        <>
          <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
            <img
              style={{ maxWidth: '60%', cursor: 'pointer' }}
              src={IMAGES_ENDPOINT + image}
              alt={"imageNotFound"}
              onClick={() => previewFromURL(IMAGES_ENDPOINT + image)}
            />
          </div>
          <Typography variant={"caption"} align="center" style={{ display: 'block' }}>
            {'Haga clic en la imagen para ampliarla'}
          </Typography>
        </>
      }
      {
        Boolean(error) &&
        <Typography style={{ maxWidth: "unset" }} variant={"subtitle1"} className={"error-text"}>
          {error}
        </Typography>
      }
    </section>
  );
}

export default Question;