import React from "react";
import { Grid, Fab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AddIcon } from "@icarius-icons";
import { HOVER_OPACITY } from "@icarius-utils/constants";
import { hexToRgb } from "@icarius-utils/colors";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";

const FabButton = (props) => {

  const {
    title,
    onClick,
    useColorBackground,
    useWhiteTextAlways,
    ignoreIcon,
    ignoreBackground,
  } = props;

  const color = useSelector(getAppColor);
  const theme = useSelector(getTheme);

  const CustomFab = withStyles({
    root: {
      color: useWhiteTextAlways ? "white" : theme.theme === "dark" ? "white" : "black",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      backgroundColor: ignoreBackground ? "transparent" : useColorBackground ? color : theme.theme === "dark" ? "#484848" : "#fafafa",
      "&:hover": {
        backgroundColor: hexToRgb(color, HOVER_OPACITY),
      },
      margin: "5px 5px",
    },
  })(Fab);

  return (
    <CustomFab
      onClick={onClick}
      variant="extended"
      size={"large"}
      aria-label="add"
      style={{
        height: 42,
        cursor: "pointer",
      }}>

      <Grid container justify="center" alignItems="center">
        {
          !ignoreIcon &&
          <Grid item style={{ height: 24 }}>
            <AddIcon htmlColor={color} />
          </Grid>
        }
        <Grid item style={{ height: 22, paddingRight: "5px", paddingLeft: ignoreIcon ? "5px" : "" }}>
          {title}
        </Grid>
      </Grid>
    </CustomFab>
  );
}

export default FabButton;
