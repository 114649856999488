import NAME from "./constants";

export const CLOSE_POLL = NAME + "/CLOSE_POLL";

export const ANSWER_POLL = NAME + "/ANSWER_POLL";
export const ANSWER_POLL_FULFILLED = NAME + "/ANSWER_POLL_FULFILLED";
export const ANSWER_POLL_REJECTED = NAME + "/ANSWER_POLL_REJECTED";

export const GET_POLL = NAME + "/GET_POLL";
export const GET_POLL_REJECTED = NAME + "/GET_POLL_REJECTED";
export const GET_POLL_FULFILLED = NAME + "/GET_POLL_FULFILLED";
