import React from "react";
import { TextField } from "@material-ui/core";
import CustomNumberFormat from "./customNumberFormat";

const NumberInput = (props) => {
    const {
        fieldConfig,
        label,
        value,
        handleChange,
        required,
        error,
    } = props;

    return (
        <TextField
            required={required}
            label={label}
            value={value}
            onChange={(e) => handleChange(e.target.value, fieldConfig.name)}
            InputProps={{ inputComponent: CustomNumberFormat }}
            inputProps={{ decimals: fieldConfig.decimals || 0 }}
            error={error}
            margin="none"
            fullWidth
        />
    )
}

export default NumberInput;