import React from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import { LG_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";
import FabButton from "@icarius-common/fabButton";
import { getLocalizedString } from "@icarius-localization/strings";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";
import NoData from "@icarius-common/abmComponents/noData";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const gridStyleSmall = { margin: "auto", width: "95%" };
const gridStyleBig = { margin: "auto", width: "85%", minWidth: 1100 };

const ABMLayout = (props) => {

    const {
        children,

        isLoading,
        title,

        showCreateButton,
        createButtonTitle,
        createButtonOnClick,

        customItems,
        useCustomItemsEnd,

        showGoBack,
        handleGoBack,

        audioName,
        audioTitle,

        showNoData,
        noDataTitle,
        noDataCreateTitle,
        noDataOnClick,

        useFullWidth,
    } = props;

    const matchesMoreOrEqualThanLG = useMediaQuery(`(min-width:${LG_DEVICE_WIDTH_BREAKPOINT}px)`);

    const showFabButtonOrGoBack = showGoBack || showCreateButton || customItems?.length;
    const showNoDataToUse = !isLoading && showNoData && showCreateButton; //si no esta cargando, si se quiere mostrar el noData, y si no está abierto el form

    const getJustify = () => {
        if (customItems?.length && !useCustomItemsEnd) return 'space-between';
        if (!showCreateButton) return 'flex-end';
        return 'space-between';
    }

    return (
        <CommonPage
            isLoading={isLoading}
            isNotGridPage
        >
            <Grid container style={(matchesMoreOrEqualThanLG && !useFullWidth) ? gridStyleBig : gridStyleSmall}>
                <Grid container item xs={12} style={{ padding: "16px 0px" }} alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <Typography style={{ fontSize: "1rem", fontWeight: 700 }} variant="h6" id="toolbar-title">
                            {title}
                        </Typography>
                        {
                            audioName && audioTitle &&
                            <PlayButton audioName={audioName} title={audioTitle} />
                        }
                    </div>
                </Grid>
                {
                    showFabButtonOrGoBack &&
                    <Grid container item xs={12} style={{ minHeight: 52 }} alignItems={'center'} justify={getJustify()}>
                        {
                            showCreateButton &&
                            <FabButton title={createButtonTitle} onClick={createButtonOnClick} />
                        }
                        {
                            customItems && customItems.length > 0 &&
                            customItems.map((item, index) => {
                                return <div key={index}>{item}</div>
                            })
                        }
                        {
                            showGoBack &&
                            <CustomIconButton
                                title={getLocalizedString("goBack")}
                                onClick={handleGoBack}
                                type={"goBack"}
                            />
                        }
                    </Grid>
                }
                <Grid container item xs={12} style={{ marginBottom: 10 }}>
                    {children}
                    {
                        showNoDataToUse &&
                        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
                            <NoData
                                title={noDataTitle}
                                createTitle={noDataCreateTitle}
                                onClick={noDataOnClick}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </CommonPage>
    )
}

export default ABMLayout;