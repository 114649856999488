import React from "react";
import { TextField } from "@material-ui/core";
import AddressAdornment from "./addressAdornment";

const AddressInput = (props) => {
    const {
        fieldConfig,
        label,
        value,
        handleChange,
        required,
        addressHref,
        error,
    } = props;

    return (
        <TextField
            required={required}
            label={label}
            value={value}
            onChange={(e) => handleChange(e.target.value, fieldConfig.name)}
            InputProps={{
                endAdornment: value ? <AddressAdornment href={addressHref} /> : null,
            }}
            inputProps={{ maxLength: fieldConfig.max }}
            margin="none"
            error={error}
            fullWidth
        />
    )
}

export default AddressInput;