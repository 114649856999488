import { useState, useEffect, useCallback } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { useDispatch, useSelector } from "react-redux";
import {
    directDownloadFromServer,
    getDigitalDocumentsToApproveFromServer,
    startDigitalDocumentDownloadAction
} from "../actions";
import {
    getApprovationMethod,
    getDigitalDocuments,
    getIsLoading
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useHandleMyDigitalDocuments = (handleCloseCallback) => {

    const [document, setDocument] = useState(null);
    const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);

    const dispatch = useDispatch();
    const isLoading = useSelector(getIsLoading);
    const approvationMethod = useSelector(getApprovationMethod);
    const digitalDocuments = useSelector(getDigitalDocuments);

    const handleClose = useCallback(() => {
        setDocument(null);
        setConfirmDialogIsOpen(false);
        handleCloseCallback();
    }, [handleCloseCallback]);

    useEffect(() => {
        dispatch(getDigitalDocumentsToApproveFromServer())
            .then(resp => {
                if (!resp?.data?.docs?.length) {
                    dispatch(openDialogAction(
                        {
                            title: getLocalizedString("atention"),
                            msg: getLocalizedString("noDigitalDocuments"),
                            acceptOnly: true
                        }
                    ));
                    handleClose(false);
                }
            });

        return () => {
            dispatch({ type: RESET_STATE });
        };
    }, [dispatch, handleClose])

    const handleSubmit = (documentSelected) => {
        if (!Boolean(documentSelected)) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar un documento', severity: 'error' }));
            return;
        }

        setConfirmDialogIsOpen(true);
        setDocument(documentSelected);
    };

    const handleDownload = (docCode) => {
        dispatch(directDownloadFromServer(docCode))
            .then(() => dispatch(getDigitalDocumentsToApproveFromServer())); //traer los nuevos documentos y actualizar el contador del menu
    };

    const handlePreview = () => {
        dispatch(startDigitalDocumentDownloadAction({ docs: [document] }));
    }

    return {
        digitalDocuments,
        isLoading,
        approvationMethod,
        document,
        confirmDialogIsOpen,
        handleSubmit,
        handleClose,
        handleDownload,
        handlePreview,
    }
}


export default useHandleMyDigitalDocuments;
