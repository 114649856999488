import React from "react";
import { useSelector } from "react-redux";
import { getAppColor } from "../app/selectors";

const DialogTitleDivider = () => {
    const color = useSelector(getAppColor);
    return <div style={color && { borderBottom: `1px solid ${color}`, paddingTop: 10 }} />
}

export default DialogTitleDivider;
