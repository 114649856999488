import React, { useState } from "react";
import { templatesOptions, MAX_TEMPLATE_LENGTH, MIN_TEMPLATE_LENGTH } from "@icarius-utils/constants";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { useDispatch } from "react-redux";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateTemplateDialog = (props) => {

  const [role, setRole] = useState("");
  const [name, setName] = useState("");

  const {
    open,
    level,
    handleCreateTemplate,
    handleCloseDialog,
  } = props;

  const dispatch = useDispatch();

  const handleConfirm = () => {
    let isValid = true;
    let errorMsg = '';

    if (!name || !role) {
      isValid = false;
      errorMsg = 'Todos los campos son obligatorios';
    }

    if (name.length < MIN_TEMPLATE_LENGTH) {
      isValid = false;
      errorMsg = getLocalizedErrorString("templateMustHaveMinCharacters");
    }

    if (isValid) {
      handleCreateTemplate(role, name);
    } else {
      dispatch(openSnackbarAction({ msg: errorMsg, severity: 'error' }));
    }
  };

  let levelName = {
    "C": "COLABORADOR",
    "M": "MANAGER",
    "E": "EMPLEADOR",
  }[level];

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("createTemplate")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleCloseDialog} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={getLocalizedString("template")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputProps={{ maxLength: MAX_TEMPLATE_LENGTH }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label`}>{getLocalizedString("visualizationRol")}</InputLabel>
                <Select
                  value={role}
                  labelId={`label`}
                  id={`select`}
                  onChange={(e) => setRole(e.target.value)}
                  margin={"none"}
                >
                  {
                    templatesOptions
                      .filter((item) => item.profiles.includes(levelName))
                      .map((item) => {
                        return (
                          <MenuItem key={item.code} value={item.code}>
                            {item.label}
                          </MenuItem>
                        );
                      })
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleCloseDialog} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleConfirm} isAccept text={getLocalizedString("save")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateTemplateDialog;
