import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "./actionTypes";

export const openSnackbarAction = ({ msg, duration = 5000, path, severity }) => (dispatch) => {
  dispatch({
    type: OPEN_SNACKBAR,
    payload: { msg, duration, path, severity },
  });
}

export const closeSnackbarAction = (dispatch) => dispatch({ type: CLOSE_SNACKBAR });
