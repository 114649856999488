import React from "react";
import {
  Grid,
  Typography,
  Checkbox,
  IconButton,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import ProfileImg from "@icarius-common/profileImg";
import { VariableSizeList } from "react-window";
import { getUserData } from "src/app/selectors";
import { useSelector } from "react-redux";

const UserSection = React.memo((props) => {

  const {
    employees,
    isSelection,
    handleClick,
    loggedUserIsDisabled,
  } = props;

  const isSmall = useMediaQuery(`(max-width:959px)`);
  const userData = useSelector(getUserData);

  const Row = ({ index, style }) => {
    const employee = employees[index];
    const image = employee["FOTOGRAFIA"] || employee["img"] || employee["image"] || "";
    const isDisabled = loggedUserIsDisabled && userData.code === employee.key;

    return (
      <div style={style}>
        <Grid container alignItems="center" key={employee.key} style={{ height: 60, paddingBottom: 10 }}>
          <Grid item xs={2}>
            <ProfileImg
              image={image}
              size={40}
            />
          </Grid>
          <Grid
            item
            xs={8}
            style={{ cursor: isDisabled ? "" : "pointer" }}
            onClick={!isDisabled ? (() => handleClick(employee.key)) : null}
          >
            <Tooltip title={employee.name}>
              <Typography variant="h6" className="whiteText" noWrap style={{ fontWeight: 100, fontSize: 16 }}>
                {employee.name}
              </Typography>
            </Tooltip>
            {
              employee.position &&
              <Tooltip title={employee.position}>
                <Typography variant="h6" className="whiteText" noWrap style={{ fontWeight: 500, fontSize: 14 }}>
                  {employee.position}
                </Typography>
              </Tooltip>
            }
          </Grid>
          <Grid container alignItems="center" justify="center" item xs={2}>
            {
              !isDisabled ?
                (
                  isSelection ?
                    <Checkbox onChange={() => handleClick(employee.key)} checked={employee.isChecked} />
                    :
                    <IconButton onClick={() => handleClick(employee.key)}>
                      <CloseIcon style={{ fill: "var(--icons)" }} />
                    </IconButton>
                )
                : null
            }
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <VariableSizeList
      style={{ overflowX: "hidden" }}
      className="List"
      height={isSmall ? 150 : 300}
      itemCount={employees.length}
      itemSize={() => 60}
    >
      {Row}
    </VariableSizeList>
  )
})

export default UserSection;