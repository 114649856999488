import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "./actionTypes";

const initialState = {
  msg: null,
  open: false,
  severity: "info",
  duration: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        open: true,
        msg: action.payload.msg,
        duration: action.payload.duration,
        severity: action.payload.severity,
        path: action.payload.path,
      };
    case CLOSE_SNACKBAR:
      return { ...state, open: false, duration: null };
    default:
      return state;
  }
}
