import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {
  snackbarIsOpen,
  getSnackbarMessage,
  getSeverity,
  getDuration,
  getPath,
} from "../selectors";
import { closeSnackbarAction } from "../actions";

const CustomSnackbar = ({ alignCenter }) => {

  const dispatch = useDispatch();
  
  const history = useHistory();
  const open = useSelector(snackbarIsOpen);
  const msg = useSelector(getSnackbarMessage);
  const severity = useSelector(getSeverity);
  const duration = useSelector(getDuration);
  const path = useSelector(getPath);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(closeSnackbarAction);
  };

  const handleClick = (event) => {
    if (event.target && event.target.className && typeof event.target.className === "string" && event.target.className.indexOf("MuiAlert-message") !== -1) {
      history && history.push(path);
      handleClose();
    }
  }

  return (
    <div style={{ cursor: path ? "pointer" : "" }}>
      <Snackbar
        open={open}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: "bottom", horizontal: alignCenter ? "center" : "right" }}
        onClose={handleClose}
        onClick={path && handleClick}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={severity}>
          {msg}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}

export default CustomSnackbar;