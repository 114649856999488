import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import {
  DeleteIcon,
  SaveIcon,
  AddIcon,
  PublishIcon,
  GetAppIcon,
} from "@icarius-icons";

const TemplatesHeader = (props) => {
  const {
    gridRef,
    user,
    templates,
    selectedTemplate,
    getRole,
    handleImportTemplates,
    handleExportTemplates,
    handleOpenCreateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleSelectTemplateAndUpdateGrid,
  } = props;

  return (
    <>
      <div style={{ minWidth: 220, maxWidth: 220 }}>
        <FormControl fullWidth style={{ width: "100%" }}>
          <InputLabel id={`label-plantilla`}>{"Seleccione plantilla"}</InputLabel>
          <Select
            value={selectedTemplate?.Code || ''}
            onChange={(e) => handleSelectTemplateAndUpdateGrid(e.target.value, gridRef)}
            labelId={`label-plantilla`}
            id={`select-plantilla`}
            margin={"none"}
          >
            <MenuItem value="">{'Resetear'}</MenuItem>
            {
              templates?.map((item, index) => (
                <MenuItem key={index} value={item.Code}>
                  {`${item.U_template} - ${getRole(item.U_rol)}`}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      <Tooltip title={getLocalizedString("createTemplate")}>
        <IconButton onClick={handleOpenCreateTemplateDialog}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={getLocalizedString("importTemplate")}>
        <IconButton onClick={() => handleImportTemplates(gridRef)}>
          <PublishIcon />
        </IconButton>
      </Tooltip>
      {
        Boolean(selectedTemplate) && user?.code === selectedTemplate.U_codemp &&
        <>
          <Tooltip title={getLocalizedString("deleteTemplate")}>
            <IconButton onClick={() => handleOpenDeleteTemplateDialog(gridRef)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={getLocalizedString("updateTemplate")}>
            <IconButton onClick={() => handleOpenUpdateTemplateDialog(gridRef)}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={getLocalizedString("exportTemplate")}>
            <IconButton onClick={handleExportTemplates}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </>
      }
    </>
  )
}

export default TemplatesHeader;