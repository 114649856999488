import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getAutomaticProcessesAPI,
  executeAutomaticProcessAPI,
  viewAutomaticProcessAPI,
} from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "NO_PROCESS_ONLINE":
      errorString = 'No existe un procedo definido por defecto para poder calcular';
      break;
    case "USER_NOT_ENABLED":
      errorString = e.response.data.message;
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: e.response?.data?.status === "USER_NOT_ENABLED" ? null : 5000 }));
}

export const getAutomaticProcessesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_AUTOMATIC_PROCESSES });
  try {
    let response = await getAutomaticProcessesAPI();
    let isEnabled = response.data && response.data.isEnabled;
    let processList = response.data && response.data.processList;

    dispatch({
      type: actionTypes.GET_AUTOMATIC_PROCESSES_FULFILLED,
      payload: { isEnabled, processList },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_AUTOMATIC_PROCESSES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const executeAutomaticProcessAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.EXECUTE });
  try {
    let response = await executeAutomaticProcessAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.EXECUTE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.EXECUTE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: 'Proceso iniciado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EXECUTE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const viewAutomaticProcessAction = (employeeCode, processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.VIEW });
  try {
    let response = await viewAutomaticProcessAPI(`?employeeCode=${employeeCode}&process=${processCode}`);
    let status = response.data && response.data.status;
    let locale = response.data && response.data.currency_localization;
    let dateFormat = response.data && response.data.date_format;
    let result = response.data && response.data.result;
    let templates = response.data && response.data.templates;
    let process = response.data && response.data.process;

    if (status !== "OK") {
      dispatch({ type: actionTypes.VIEW_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.VIEW_FULFILLED,
      payload: {
        locale,
        dateFormat,
        result,
        templates,
        process
      }
    });

    return response;
  } catch (e) {
    if (e.response.data.status === 'NO_PROCESS_ONLINE') {
      dispatch(openSnackbarAction({ msg: 'No existe un procedo definido por defecto para poder consultar', severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.VIEW_REJECTED, payload: e });
    return e.response;
  }
};