import React from "react";
import { Typography } from "@material-ui/core";
import { getNineBoxColorByCoordinates } from "@icarius-utils/colors";
import getNineBoxIcon from "@icarius-common/nineBoxIcon";

const NineBoxIndicator = ({ data }) => {

    if (!data) return null;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                style={{
                    backgroundColor: getNineBoxColorByCoordinates(data.code),
                    borderRadius: 9,
                    width: "fit-content",
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <p style={{ display: "inline", marginRight: 10, marginLeft: 10 }}>
                    {getNineBoxIcon(data.code)}
                </p>
                <Typography
                    style={{
                        fontWeight: 200,
                        color: "black",
                        fontSize: '20px',
                        lineHeight: "28px",
                        margin: 10
                    }}
                >
                    {data.description}
                </Typography>
                <Typography
                    style={{
                        fontWeight: 200,
                        color: "black",
                        fontSize: '14px',
                        lineHeight: "17px",
                        marginLeft: 15,
                        marginRight: 10,
                        marginTop: 5
                    }}
                >
                    {data.date}
                </Typography>
            </div>
        </div>
    )
}

export default NineBoxIndicator;