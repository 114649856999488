import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel
} from "@material-ui/core";
import Loader from "@icarius-common/loader";
import { getDocumentsToRequestAction, requestDocumentAction } from "src/app/actions";
import { getDocumentsToRequestList, getIsLoadingDocumentsToRequest } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import useVisitPage from "@icarius-utils/hooks/useVisitPage";
import paths from "@icarius-localization/paths";

const StyledDialog = withStyles({
  paper: {
    maxWidth: "720px",
  },
})(Dialog);

const useRequestDocument = (callback) => {

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingDocumentsToRequest);
  const documents = useSelector(getDocumentsToRequestList);

  const [selectedDocument, setSelectedDocument] = useState("");

  useEffect(() => {
    dispatch(getDocumentsToRequestAction());
  }, [dispatch])

  const handleRequest = () => {
    if (selectedDocument !== "") {
      const dataToSend = documents.find(doc => doc.toSend.codoc === selectedDocument);

      if (dataToSend) {
        dispatch(requestDocumentAction(dataToSend.toSend))
          .then(() => callback());
      }
    }
  }

  return {
    isLoading,
    documents,
    selectedDocument,
    setSelectedDocument,
    handleRequest,
  };
}

const RequestDocumentsDialog = ({ open, handleClose }) => {

  const {
    isLoading,
    documents,
    selectedDocument,
    setSelectedDocument,
    handleRequest,
  } = useRequestDocument(handleClose);

  useVisitPage(paths.requestDocuments);

  return (
    <>
      <Loader open={isLoading} />
      {
        documents?.length > 0 ?
          <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open && !isLoading}
            maxWidth={"sm"}
            fullWidth={true}>
            <div className={"dialog-container"}>
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {getLocalizedString("requestDocumentsTitle")}
                <DialogTitleDivider />
              </DialogTitle>
              <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
              <DialogContent>
                <Grid container justify="flex-start" alignItems="center">
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="document-select-label">{getLocalizedString("selectDocument")}</InputLabel>
                    <Select
                      fullWidth
                      value={selectedDocument}
                      labelId="document-select-label"
                      id="document-select"
                      onChange={(e) => setSelectedDocument(e.target.value)}
                      margin={"none"}
                    >
                      {
                        documents.map(subItem => (
                          <MenuItem
                            className={"whiteText"}
                            key={subItem.toSend.codoc}
                            value={subItem.toSend.codoc}>
                            {subItem.texto}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
                <ButtonDialogAction onClick={handleRequest} isAccept text={getLocalizedString("request")} />
              </DialogActions>
            </div>
          </Dialog>
          :
          <StyledDialog
            TransitionComponent={DialogTransition}
            open={open && !isLoading}
            fullWidth={true}
          >
            <div className={"dialog-container"}>
              <Typography variant="h6" align="center" className="whiteText" style={{ paddingTop: 10, paddingBottom: 10 }}>
                {getLocalizedString("noDocumentsRequest")}
              </Typography>
              <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            </div>
          </StyledDialog>
      }
    </>
  );
}

export default RequestDocumentsDialog;