import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET_AUTOMATIC_PROCESSES = NAME + "/GET_AUTOMATIC_PROCESSES";
export const GET_AUTOMATIC_PROCESSES_FULFILLED = NAME + "/GET_AUTOMATIC_PROCESSES_FULFILLED";
export const GET_AUTOMATIC_PROCESSES_REJECTED = NAME + "/GET_AUTOMATIC_PROCESSES_REJECTED";

export const EXECUTE = NAME + "/EXECUTE";
export const EXECUTE_FULFILLED = NAME + "/EXECUTE_FULFILLED";
export const EXECUTE_REJECTED = NAME + "/EXECUTE_REJECTED";

export const VIEW = NAME + "/VIEW";
export const VIEW_FULFILLED = NAME + "/VIEW_FULFILLED";
export const VIEW_REJECTED = NAME + "/VIEW_REJECTED";

