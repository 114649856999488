import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const Loader = ({ open }) => {

  const color = useSelector(getAppColor);

  if (!open) return null;

  return (
    <Backdrop
      open
      style={{
        zIndex: 1310,
        color: '#fff',
      }}
      transitionDuration={0}
    >
      <CircularProgress style={{ color: color || "#e52521" }} />
    </Backdrop>
  )
}

export default Loader;