import * as actionTypes from "./actionTypes";
import { getFiltersAPI, applyFiltersAPI } from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

export const getFiltersAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FILTERS });
  try {
    let response = await getFiltersAPI();

    let filters = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FILTERS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FILTERS_FULFILLED,
      payload: { filters }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FILTERS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};


export const applyFiltersAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.APPLY_FILTERS });
  try {
    let response = await applyFiltersAPI(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.APPLY_FILTERS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.APPLY_FILTERS_FULFILLED,      
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("filtersOK"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: actionTypes.APPLY_FILTERS_REJECTED, payload: e });
    return e.response;
  }
};