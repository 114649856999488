import React from "react";
import { useDrag } from "react-dnd";

const Piece = ({ item, readOnly, children }) => {

  const [, drag] = useDrag(() => {
    return ({
      type: "nineBoxPiece",
      item: { item },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    })
  }, [item]);

  return (
    <div style={{ cursor: !readOnly && "move", padding: 5 }} ref={drag}>
      {children}
    </div>
  );
};

export default Piece;