import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";
export const CLEAR_PROCESSES_BY_PATH = NAME + "/CLEAR_PROCESSES_BY_PATH";

export const GET_PROCESSES = NAME + "/GET_PROCESSES";
export const GET_PROCESSES_FULFILLED = NAME + "/GET_PROCESSES_FULFILLED";
export const GET_PROCESSES_REJECTED = NAME + "/GET_PROCESSES_REJECTED";

export const EXECUTE_PROCESS = NAME + "/EXECUTE_PROCESS";
export const EXECUTE_PROCESS_FULFILLED = NAME + "/EXECUTE_PROCESS_FULFILLED";
export const EXECUTE_PROCESS_REJECTED = NAME + "/EXECUTE_PROCESS_REJECTED";