import React from "react";
import { Tooltip } from "@material-ui/core";

const squareStyle = {
  width: "100%",
  height: "100%",
  padding: 10,
};

const Quadrant = (props) => {

  const {
    code,
    tooltipContent,
    handleClick,
    children,
  } = props;

  return (
    <Tooltip
      arrow
      title={tooltipContent}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
        onClick={handleClick}
      >
        <div className={`grid-${code}`} style={squareStyle}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              overflowY: 'auto',
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default Quadrant;