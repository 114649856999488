import React, { useState, useRef } from "react";
import {
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  DialogActions,
  Slider,
  TextField,
  Input,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import SectionDivider from "./sectionDivider";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { updateMedalTableAction, updateMedalTableAdjustmentAction } from "../../actions";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const gridStyle = { height: 75 };

const initialFormData = {
  points: 1,
  reason: '',
  isSubtraction: false,
};

const styles = () => ({
  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },
  cssFocused: {
    color: "var(--mainText) !important",
  },
  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const {
    status,
    userName,
    userCode,
    userLevel,
    pointValue,
    reasonsList,
    maxPointsToDiscount,
    maxPointsToAssign,
    handleCloseDialog,
    classes,
  } = props;

  const dispatch = useDispatch();
  const formRef = useRef();
  const [isAdjustmentForm, setIsAdjustmentForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const maxPoints = formData.isSubtraction ? maxPointsToDiscount : maxPointsToAssign;

  const handleOpenForm = (isAdjustmentFormNewValue) => {
    setIsOpen(true);
    setIsAdjustmentForm(isAdjustmentFormNewValue);
    if (isAdjustmentFormNewValue) {
      setFormData(prev => ({ ...prev, isSubtraction: maxPointsToDiscount > 0 })); // si es ajustes y tiene maxPointsToDiscount, solo se puede descontar
    }
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 250);
  }

  const handleCloseForm = () => {
    setIsOpen(false);
    setFormData(initialFormData);
  }

  const submit = (dataToSend) => {
    dispatch((isAdjustmentForm ? updateMedalTableAdjustmentAction : updateMedalTableAction)(dataToSend))
      .then(res => {
        if (res?.status === 200) {
          handleCloseDialog(true);
        }
      })
  }

  const handleSubmit = () => {
    const { points, reason, isSubtraction } = formData;
    if (points && reason) {
      if (points > 0 && points <= maxPoints) {
        const dataToSend = {
          points,
          reason,
          code: userCode,
        };

        if (isAdjustmentForm) {
          dataToSend.isSubtraction = isSubtraction;
        }

        dispatch(openDialogAction({
          title: 'Atención',
          msg: `¿Está seguro que desea ${isSubtraction ? 'descontar' : 'entregar'} ${points} puntos a ${userName}?`,
          onConfirm: () => submit(dataToSend),
        }));
      } else {
        dispatch(openSnackbarAction({
          msg: `Solo puede ${isSubtraction ? 'descontar' : 'entregar'} entre 1 y ${maxPoints} puntos`,
          severity: 'error',
        }));
      }
    } else {
      dispatch(openSnackbarAction({ msg: 'Todos los campos son obligatorios', severity: 'error' }));
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'isSubtraction') {
      setFormData((prev) => ({
        ...prev,
        [fieldName]: value,
        points: 1,
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  }

  const handleInputChange = (event, fieldName) => {
    setFormValue(event.target.value === '' ? 0 : Number(event.target.value), fieldName);
  };

  const handleInputBlur = (fieldName) => {
    if (formData.points < 0) {
      setFormValue(0, fieldName);
    } else if (formData.points > maxPoints) {
      setFormValue(maxPoints, fieldName);
    }
  };

  if (!isOpen) {
    return (
      <Grid container item xs={12} justify={"center"} alignItems="center" style={{ paddingBottom: 10, marginTop: -5 }}>
        <DialogActions>
          <ButtonDialogAction isAccept onClick={() => handleOpenForm(false)} text={'Entregar puntos'} />
          {
            userLevel === 'E' && status === 'A' &&
            <ButtonDialogAction isAccept onClick={() => handleOpenForm(true)} text={'Ajustes'} />
          }
        </DialogActions>
      </Grid>
    )
  }

  const totalPointsValue = formatNumberExactDecimals(formData.points * pointValue, 2);

  return (
    <div ref={formRef}>
      <SectionDivider />
      <Grid container item xs={12} direction="row" style={{ margin: '20px 0px' }}>
        <Typography className="whiteText">
          {isAdjustmentForm && `${formData.isSubtraction ? 'Descuento' : 'Asignación'} de puntos`}
          {!isAdjustmentForm && 'Entrega de puntos'}
        </Typography>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid container item alignItems="center" xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={2} style={{ paddingRight: 10 }}>
                <Input
                  fullWidth
                  value={formData.points}
                  size="small"
                  onChange={(e) => handleInputChange(e, "points")}
                  onBlur={() => handleInputBlur("points")}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: maxPoints,
                    type: 'number',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Slider
                  onChange={(_, value) => setFormValue(value, "points")}
                  min={1}
                  max={maxPoints}
                  step={1}
                  defaultValue={0}
                  value={formData.points}
                />
              </Grid>
            </Grid>
            <Typography className="whiteText" variant="subtitle1" align="center" style={{ width: '100%' }}>
              {`${formData.points} punto(s) = Valor ${totalPointsValue}`}
            </Typography>
          </Grid>
          {
            isAdjustmentForm ?
              <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, marginTop: 10, height: '' }}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  margin={"none"}
                  label={'Motivo'}
                  value={formData.reason}
                  onChange={(e) => setFormValue(e.target.value, "reason")}
                  InputLabelProps={{
                    classes: {
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                  inputProps={{ maxLength: 100, spellCheck: 'false' }}
                />
              </Grid>
              :
              <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={'label-reason'}>{'Motivo'}</InputLabel>
                  <Select
                    value={formData.reason}
                    labelId={'label-reason'}
                    id={'select-reason'}
                    onChange={(e) => setFormValue(e.target.value, "reason")}
                    margin={"none"}
                  >
                    {
                      reasonsList?.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
          }
        </Grid>
      </Grid>
      <DialogActions style={{ padding: 0, paddingBottom: 20 }}>
        <ButtonDialogAction onClick={handleCloseForm} text={getLocalizedString("cancel")} />
        <ButtonDialogAction isAccept onClick={handleSubmit} text={'Aceptar'} />
      </DialogActions>
    </div>
  )
}

export default withStyles(styles)(Form);
