import * as actionTypes from "./actionTypes";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import {
  getMyCertificateListAPI,
  downloadMyCertificateAPI,
  confirmCertificateAPI,
  getMyReceiptList,
  downloadMyReceiptApi,
  confirmReceiptApi,
} from "@icarius-connection/api";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { downloadBlob } from "@icarius-utils/download";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";

//Certificados
export const getMyCertificateListFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MY_CERTIFICATES_LIST_ROWS });
  try {
    let response = await getMyCertificateListAPI();
    let certificates = response.data && response.data.certificates;
    let receiverList = response.data && response.data.receiverList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_MY_CERTIFICATES_LIST_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_MY_CERTIFICATES_LIST_ROWS_FULFILLED,
      payload: {
        certificates,
        receiverList,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_MY_CERTIFICATES_LIST_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadMyCertificateFromServer = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_MY_CERTIFICATE });
  try {
    let response = await downloadMyCertificateAPI(dataToSend);
    let documents = response.data && response.data.documents;
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND" || response.data.status === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.DOWNLOAD_MY_CERTIFICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("certificateNotCreated"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_MY_CERTIFICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_MY_CERTIFICATE_FULFILLED,
      payload: { documents },
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_MY_CERTIFICATE_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const confirmCertificateToServer = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CONFIRM_CERTIFICATE });
  try {
    let response = await confirmCertificateAPI(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CONFIRM_CERTIFICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getErrorStringFromError("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.CONFIRM_CERTIFICATE_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CONFIRM_CERTIFICATE_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

//Recibos
export const getMyReceiptsListFromServer = (isHistorical) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MY_RECEIPTS_LIST_ROWS });
  try {
    let response = await getMyReceiptList(`?onlyApprovedDocuments=${Boolean(isHistorical)}`);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_MY_RECEIPTS_LIST_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_MY_RECEIPTS_LIST_ROWS_FULFILLED,
      payload: { receipts: response.data.receipts },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_MY_RECEIPTS_LIST_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadMyReceiptFromServer = (code, reference) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_MY_RECEIPT });
  try {
    let response = await downloadMyReceiptApi(`?code=${code}&reference=${reference}`);
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND") {
      dispatch({ type: actionTypes.DOWNLOAD_MY_RECEIPT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("receiptNotCreated"), severity: "error" }));
      return;
    }

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_MY_RECEIPT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.DOWNLOAD_MY_RECEIPT_FULFILLED });
    downloadBlob(response.data, getFileExtension(title), getFileName(title));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_MY_RECEIPT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const confirmReceiptToServer = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CONFIRM_RECEIPT });
  try {
    let response = await confirmReceiptApi(data);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CONFIRM_RECEIPT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.CONFIRM_RECEIPT_FULFILLED });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CONFIRM_RECEIPT_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};