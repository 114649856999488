import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const ImageInput = (props) => {

  const { handleUploadImage } = props;
  const dispatch = useDispatch();

  const fileIsValidImage = (file) => {
    return (
      file.type.includes("/png") ||
      file.type.includes("/jpg") ||
      file.type.includes("/jpeg") ||
      file.type.includes("/bmp") ||
      file.type.includes("/gif")
    )
  }

  const uploadImage = (file) => {
    if (file.size > 5 * 1024 * 1024) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("imageSizeExceeded"), severity: "error" }));
      return;
    }
    if (!fileIsValidImage(file)) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fileMustBeImage"), severity: "error" }));
      return;
    }

    handleUploadImage(file);
  }

  return (
    <div>
      <input
        type="file"
        id="galleryImageUpload"
        style={{ display: "none" }}
        onChange={e => { uploadImage(e.target.files[0]) }}
      />

      <label htmlFor="galleryImageUpload">
        <Typography className="whiteText" style={{ textDecoration: "underline", cursor: "pointer" }}>{getLocalizedString("uploadImage")}</Typography>
      </label>
    </div>
  )
}

ImageInput.propTypes = {
  handleUploadImage: PropTypes.func.isRequired,
};

export default ImageInput;
