import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearInterval, setInterval } from "worker-timers";
import { getTime } from "./selectors";
import { setTime } from "./actions";

export const useHandleTime = () => {

  const dispatch = useDispatch();
  const time = useSelector(getTime);

  useEffect(() => {
    const tick = () => dispatch(setTime(new Date()));

    tick();
    window.addEventListener("focus", tick);
    let intervalId = setInterval(tick, 1000);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("focus", tick);
    }
  }, [dispatch])

  return time;
}