import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

//Digital Documents
export const GET_DIGITAL_DOCUMENTS_ROWS = NAME + "/GET_DIGITAL_DOCUMENTS_ROWS";
export const GET_DIGITAL_DOCUMENTS_ROWS_FULFILLED = NAME + "/GET_DIGITAL_DOCUMENTS_ROWS_FULFILLED";
export const GET_DIGITAL_DOCUMENTS_ROWS_REJECTED = NAME + "/GET_DIGITAL_DOCUMENTS_ROWS_REJECTED";

export const START_DOWNLOAD_DIGITAL_DOCUMENT = NAME + "/START_DOWNLOAD_DIGITAL_DOCUMENT";
export const START_DOWNLOAD_DIGITAL_DOCUMENT_FULFILLED = NAME + "/START_DOWNLOAD_DIGITAL_DOCUMENT_FULFILLED";
export const START_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED = NAME + "/START_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED";

export const CONFIRM_DIGITAL_DOCUMENT = NAME + "/CONFIRM_DIGITAL_DOCUMENT";
export const CONFIRM_DIGITAL_DOCUMENT_FULFILLED = NAME + "/CONFIRM_DIGITAL_DOCUMENT_FULFILLED";
export const CONFIRM_DIGITAL_DOCUMENT_REJECTED = NAME + "/CONFIRM_DIGITAL_DOCUMENT_REJECTED";

export const DIRECT_DOWNLOAD_DIGITAL_DOCUMENT = NAME + "/DIRECT_DOWNLOAD_DIGITAL_DOCUMENT";
export const DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_FULFILLED = NAME + "/DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_FULFILLED";
export const DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED = NAME + "/DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED";