import {
  SET_TIME,
} from "./actionTypes";

const initialState = {
  time: new Date(),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TIME:
      return { ...state, time: action.payload };
    default:
      return state;
  }
}
