import * as actionTypes from "./actionTypes";
import { SET_DIGITAL_DOCUMENT_QUANTITY, UPDATE_DIGITAL_DOCUMENTS_AMOUNT } from "src/app/actionTypes";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import {
  directDownloadMyDocumentWithDigitalSignApi,
  downloadMyDocumentWithDigitalSignApi,
  getDigitalDocumentsToApprove,
  confirmDigitalDocumentApi
} from "@icarius-connection/api";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { downloadBlob } from "@icarius-utils/download";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";

//Documentos Digitales
export const getDigitalDocumentsToApproveFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DIGITAL_DOCUMENTS_ROWS });
  try {
    let response = await getDigitalDocumentsToApprove();
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_DIGITAL_DOCUMENTS_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_DIGITAL_DOCUMENTS_ROWS_FULFILLED,
      payload: {
        digitalDocs: response.data.docs,
        dataSign: response.data.dataSign,
        dateFormat: response.data.data_format,
      }
    });

    dispatch({ type: SET_DIGITAL_DOCUMENT_QUANTITY, payload: response.data.docs.length });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_DIGITAL_DOCUMENTS_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

//Iniciar descarga de documento digital (con estampa), llega por socket
export const startDigitalDocumentDownloadAction = (docs) => async (dispatch) => {
  dispatch({ type: actionTypes.START_DOWNLOAD_DIGITAL_DOCUMENT });
  try {
    let response = await downloadMyDocumentWithDigitalSignApi(docs);

    if (response.status !== 200) {
      dispatch({ type: actionTypes.START_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.START_DOWNLOAD_DIGITAL_DOCUMENT_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.START_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const confirmDigitalDocumentToServer = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CONFIRM_DIGITAL_DOCUMENT });
  try {
    let response = await confirmDigitalDocumentApi(data);
    let digitalDocumentsAmount = response.data && response.data.digitalDocumentsAmount;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CONFIRM_DIGITAL_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getErrorStringFromError("defaultException"), severity: "error" }));
      return;
    }

    if (data.accepted) {
      dispatch(openSnackbarAction({ msg: "La aprobación y firma del documento se ha realizado con éxito", severity: "success" }));
    } else {
      dispatch(openSnackbarAction({ msg: "El rechazo del documento se ha realizado con éxito", severity: "success" }));
    }

    dispatch({ type: actionTypes.CONFIRM_DIGITAL_DOCUMENT_FULFILLED });
    dispatch({
      type: UPDATE_DIGITAL_DOCUMENTS_AMOUNT,
      payload: {
        digitalDocumentsAmount,
      }
    });
    return response;
  } catch (e) {
    if (e?.response?.data?.status === "UNAUTHORIZED") {
      dispatch(openSnackbarAction({ msg: 'El PIN ingresado es incorrecto', severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CONFIRM_DIGITAL_DOCUMENT_REJECTED, payload: e });
    return e.response;
  }
};

//Descarga de documento CON FIRMA post aceptacion
export const directDownloadFromServer = (doc) => async (dispatch) => {
  dispatch({ type: actionTypes.DIRECT_DOWNLOAD_DIGITAL_DOCUMENT });
  try {
    let response = await directDownloadMyDocumentWithDigitalSignApi({ doc });

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    let title = response.headers["x-content-namefile"];
    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    dispatch({ type: actionTypes.DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_FULFILLED });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DIRECT_DOWNLOAD_DIGITAL_DOCUMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};