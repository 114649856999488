import React from "react";
import { useDrop } from "react-dnd";
import Quadrant from "./quadrant";

const QuadrantDnd = (props) => {

  const {
    x,
    y,
    tooltipContent,
    onDrop,
    handleClick,
    children,
  } = props;

  const [, drop] = useDrop(() => {
    return ({
      accept: "nineBoxPiece",
      drop: (item) => {
        if (!onDrop) return;

        const previousX = item.item.position.x;
        const previousY = item.item.position.y;
        if (previousX === x && previousY === y) return; // si lo solté en el mismo lugar donde estaba, no hacer nada
        onDrop(item, x, y)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    });
  }, [x, y]);

  return (
    <div
      ref={drop}
      style={{ height: '100%', width: '100%' }}
    >
      <Quadrant
        code={`${y}-${x}`}
        tooltipContent={tooltipContent}
        handleClick={handleClick}
      >
        {children}
      </Quadrant>
    </div>
  );
};

export default QuadrantDnd;