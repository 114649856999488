import * as actionTypes from "./actionTypes";

const initialState = {
  open: false,
  audioName: null,
  title: null, 
  assistantVoiceType: true,
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN_AUDIO_PLAYER:
      return {
        ...state,
        open: true,       
        audioName: action.payload.audioName,
        title: action.payload.title,
      };
    case actionTypes.CLOSE_AUDIO_PLAYER:
      return { ...state, open: false };
    case actionTypes.SET_VOICE_GENRE:
      return { ...state, loading: false };    
    case actionTypes.SET_VOICE_GENRE_FULFILLED:
      return {
        ...state,
        assistantVoiceType: action.payload.assistantVoiceType,
        loading: false,
      }
    case actionTypes.SET_VOICE_GENRE_REJECTED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
