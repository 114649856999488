import React from "react";
import { CheckIcon, WarningIcon, PersonIcon, LockIcon, WorkIcon } from "@icarius-icons";
import { TextField } from "@material-ui/core";

const CustomTextField = React.forwardRef((props, ref) => {
  
  const {
    value,
    type,
    label,
    onChange,
    isValid,
    showState,
    color,
    maxWidth,
    login,
    onBlur,
    onKeyDown,
    isClient,
    noAdornment,
    autoFocus,
    noAutoComplete,
  } = props;

  const InputProps = (() => {
    if (noAdornment) {
      if (!noAutoComplete) return;
      return {
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off',
        },
      }
    }

    if (value && showState) {
      return {
        endAdornment: isValid ? <CheckIcon className={"check-icon"} /> : <WarningIcon className={"warning-icon"} />,
        autoComplete: noAutoComplete && 'new-password',
        form: {
          autoComplete: noAutoComplete ? 'new-password' : 'on',
        },
      }
    }

    const endAdornment = (() => {
      if (type === "password") return <LockIcon className={login ? "empty-icon" : "empty-icon-login"} />;
      if (isClient) return <WorkIcon className={login ? "empty-icon" : "empty-icon-login"} />;
      return <PersonIcon className={login ? "empty-icon" : "empty-icon-login"} />;
    })()

    return {
      endAdornment: endAdornment,
      style: { borderColor: color || "white" },
      autoComplete: noAutoComplete && 'new-password',
      form: {
        autoComplete: noAutoComplete ? 'new-password' : 'on',
      },
    }
  })();

  return (
    <TextField
      inputRef={ref}
      autoFocus={autoFocus}
      className={login && "custom-input" + (!isValid && value && showState ? " error-input" : "")}
      error={!isValid && Boolean(value) && showState}
      label={label}
      autoComplete="new-password"
      type={type}
      style={{
        color: color || "var(--mainText)",
        borderColor: color || "white",
        width: maxWidth === true ? "100%" : "inherit",
      }}
      onBlur={e => onBlur && onBlur(e.target.value)}
      onKeyDown={onKeyDown}
      value={value}
      onChange={e => onChange(e.target.value)}
      InputProps={InputProps}
    />
  )
})

export default CustomTextField;
