import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFavouriteFunctionsAction, modifyFavouriteFunctionAction } from "../actions";
import { getFavouriteList, getIsLoading } from "../selectors";

const useFavourites = (type) => {

  const dispatch = useDispatch();

  const favouriteListAll = useSelector(getFavouriteList);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    dispatch(getFavouriteFunctionsAction(type));
  }, [dispatch, type])

  const handleChangeFavourite = (type, code, setFavorite) => {
    dispatch(modifyFavouriteFunctionAction({
      type, code, setFavorite
    }))
  }

  return {
    isLoadingFavourites: isLoading,
    favouriteList: favouriteListAll[type],
    handleChangeFavourite,
  }
}

export default useFavourites;