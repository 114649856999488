import React from "react";
import { useSelector } from "react-redux";
import { CircularProgress, Typography, Box, Backdrop } from '@material-ui/core';
import { getAppColor } from "src/app/selectors";
import { getProgressValue, getProgressIsOpen } from "../selectors";

const Progress = () => {

  const open = useSelector(getProgressIsOpen);
  const color = useSelector(getAppColor);
  const progress = useSelector(getProgressValue);

  if (!open) return null;

  return (
    <Backdrop
      open
      style={{
        zIndex: 1310,
        color: '#fff',
      }}
      transitionDuration={0}
    >
        <Box position="relative" display="inline-flex">
          <CircularProgress style={{ color: color || "#e52521" }} value={progress} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subtitle2" component="div" style={{ color: "white" }}>
              {`${Math.round(progress,)}%`}
            </Typography>
          </Box>
        </Box>
    </Backdrop>
  )
}

export default Progress;