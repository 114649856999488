import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET_TEMPLATES = NAME + "/GET_TEMPLATES";
export const GET_TEMPLATES_FULFILLED = NAME + "/GET_TEMPLATES_FULFILLED";
export const GET_TEMPLATES_REJECTED = NAME + "/GET_TEMPLATES_REJECTED";

export const CREATE_TEMPLATE = NAME + "/CREATE_TEMPLATE";
export const CREATE_TEMPLATE_FULFILLED = NAME + "/CREATE_TEMPLATE_FULFILLED";
export const CREATE_TEMPLATE_REJECTED = NAME + "/CREATE_TEMPLATE_REJECTED";

export const MODIFY_TEMPLATE = NAME + "/MODIFY_TEMPLATE";
export const MODIFY_TEMPLATE_FULFILLED = NAME + "/MODIFY_TEMPLATE_FULFILLED";
export const MODIFY_TEMPLATE_REJECTED = NAME + "/MODIFY_TEMPLATE_REJECTED";

export const DELETE_TEMPLATE = NAME + "/DELETE_TEMPLATE";
export const DELETE_TEMPLATE_FULFILLED = NAME + "/DELETE_TEMPLATE_FULFILLED";
export const DELETE_TEMPLATE_REJECTED = NAME + "/DELETE_TEMPLATE_REJECTED";