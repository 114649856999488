import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPollCode,
  pollIsOpen,
  getPollTitle,
  getPollQuestions,
  getPollCompleted,
  getPollShowAll,
  getIsNPS,
  getIsAnonymous,
  getFile,
  getAnswers,
} from "../selectors";
import { CloseIcon } from "@icarius-icons";
import { answerPollAction, closePollAction } from "../actions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Question from "./question";
import Wizard from "./Wizard";
import { getAppColor } from "src/app/selectors";
import { previewFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import ViewAnswersDialog from "./viewAnswersDialog";

const Poll = () => {

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);
  const open = useSelector(pollIsOpen);
  const title = useSelector(getPollTitle);
  const questions = useSelector(getPollQuestions);
  const code = useSelector(getPollCode);
  const isCompleted = useSelector(getPollCompleted);
  const showAll = useSelector(getPollShowAll);
  const isNPS = useSelector(getIsNPS);
  const isAnonymous = useSelector(getIsAnonymous);
  const file = useSelector(getFile);
  const answers = useSelector(getAnswers);

  const [viewAnswersIsOpen, setViewAnswersIsOpen] = useState(false);

  const initialValues = questions.reduce((initialData, currentValue) => {
    return ({
      ...initialData,
      [currentValue.Id]: currentValue.Answer || '',
    })
  }, {});

  const onSubmit = (answers) => {
    const dataToSend = {
      id: code,
      questions: questions.map((item) => ({
        id: item.Id,
        type: item.Type,
        answer: answers[item.Id],
      })),
    }

    dispatch(answerPollAction(dataToSend))
      .then((resp) => {
        if (resp.status === 200) {
          if (Boolean(resp.data?.answers?.length)) {
            setViewAnswersIsOpen(true);
          }
        }
      })
    dispatch(closePollAction);
  };

  const handleClose = () => {
    dispatch(closePollAction);
  };

  const handleViewAnswers = () => {
    setViewAnswersIsOpen(true);
  }

  return (
    <>
      <Dialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}
        fullWidth
        maxWidth={"md"}
      >
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {title}
            <DialogTitleDivider />
          </DialogTitle>
          <DialogContent style={{ paddingTop: 0 }}>
            {
              Boolean(file) &&
              <Typography
                align="center"
                style={{ textDecoration: 'underline', paddingBottom: 10, cursor: 'pointer', color: color, fontSize: 20, fontWeight: 600 }}
                onClick={() => previewFromURL(RESOURCES_ENDPOINT + file)}
              >
                {
                  isCompleted ?
                    'Ver adjunto (haga clic para abrir)'
                    : 'Antes de responder, por favor revisa el adjunto (haga clic para abrir)'
                }
              </Typography>
            }
            <Wizard
              onSubmit={onSubmit}
              initialValues={initialValues}
              isCompleted={isCompleted}
              showAll={showAll}
              onClose={handleClose}
              isAnonymous={isAnonymous}
              hasAnswers={Boolean(answers?.length)}
              handleViewAnswers={handleViewAnswers}
            >
              {
                questions.map((item) => (
                  <Question
                    key={item.Id}
                    id={item.Id}
                    title={item.Text}
                    type={item.Type}
                    options={item.Options}
                    image={item.Image}
                    isNPS={isNPS}
                    isCompleted={isCompleted}
                    color={color}
                  />
                ))
              }
            </Wizard>
          </DialogContent>
        </div>
      </Dialog>
      {
        viewAnswersIsOpen &&
        <ViewAnswersDialog
          open={viewAnswersIsOpen}
          answers={answers}
          questionText={questions[0].Text}
          handleClose={() => setViewAnswersIsOpen(false)}
        />
      }
    </>
  );
}

export default Poll;