import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const TabMenu = (props) => {

  const {
    options, // array de strings
    optionSelected, // index del item de options que es seleccionado
    onClick, // funcion que recibe el index de la opcion que se clickea
    disableOffBorder, // desactiva el borde gris en las opciones no seleccionadas
  } = props;

  const color = useSelector(getAppColor);

  return (
    <Grid container direction="row" item xs={12}>
      {
        options.map((item, index) => {
          const borderColor = (() => {
            if (optionSelected === index) return color;
            if (disableOffBorder) return 'transparent';
            return "var(--secondaryBackgroundColor)";
          })();

          return (
            <Grid
              key={index}
              onClick={() => onClick(index)}
              container item xs={12} md={Math.round(12 / options.length)} justify="center"
              style={{ cursor: 'pointer', borderBottom: `2px solid ${borderColor}` }}
            >
              <Typography className="whiteText" variant="h6">
                {item}
              </Typography>
            </Grid>
          )
        })
      }
    </Grid>
  );
}

export default TabMenu;