import React from "react";
import { TextField } from "@material-ui/core";

const TextInput = (props) => {
    const {
        fieldConfig,
        label,
        value,
        handleChange,
        required,
        error,
    } = props;

    return (
        <TextField
            required={required}
            label={label}
            value={value}
            onChange={(e) => handleChange(e.target.value, fieldConfig.name)}
            inputProps={{ maxLength: fieldConfig.max }}
            margin="none"
            error={error}
            fullWidth
        />
    )
}

export default TextInput;