import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    Button,
    Grid,
    Typography,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { formatDate } from "@icarius-utils/date";
import { getAppColor, getIsLoadingRegisterAssistance } from "src/app/selectors";
import { registerAsistanceAction } from "src/app/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import Loader from "@icarius-common/loader";
import PaperDraggable from "@icarius-common/paperDraggable";
import { getTime } from "@icarius-common/clock/selectors";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";
import DialogTransition from "@icarius-common/dialogTransition";

const RegisterAssistanceDialog = (props) => {

    const {
        open,
        assistanceData,
        handleClose,
    } = props;

    const [workplace, setWorkplace] = useState(assistanceData?.defaultWorkplace || "");

    const dispatch = useDispatch();
    const color = useSelector(getAppColor);
    const time = useSelector(getTime);
    const registerAssistanceIsLoading = useSelector(getIsLoadingRegisterAssistance);

    const getOriginString = (origin) => {
        switch (origin) {
            case "T": return getLocalizedString("lastAssistanceEntryOriginTotemICARIUS");
            case "W": return getLocalizedString("lastAssistanceEntryOriginWebICARIUS");
            case "A": return getLocalizedString("lastAssistanceEntryOriginAppICARIUS");
            case "R": return getLocalizedString("lastAssistanceEntryOriginClock");
            case "M": return getLocalizedString("lastAssistanceEntryOriginManualManager");
            case "E": return getLocalizedString("lastAssistanceEntryOriginManualEmployer");
            default: return ""
        }
    }

    const handleRegister = (type) => {
        if (assistanceData.canSelectWorkplace && !workplace) {
            dispatch(openSnackbarAction({ msg: "Debe seleccionar un lugar de trabajo", path: "warning" }));
            return;
        }

        dispatch(registerAsistanceAction(type, workplace))
            .then((response) => {
                if (response && response.data && response.data.status === "OK") {
                    dispatch(openSnackbarAction({
                        msg: getLocalizedString("registryOK").replace("{Move}", type === "exit" ? getLocalizedString("salida") : getLocalizedString("entrada")),
                        severity: "success"
                    }));
                    handleClose();
                }
            });
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            maxWidth={"sm"}
            fullWidth
        >
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                        {getLocalizedString("registerAssistance")}
                        <PlayButton audioName={"Mi registro de asistencia"} title={"Mi registro de asistencia"} />
                    </div>
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
                <Loader open={registerAssistanceIsLoading} />
                <DialogContent>
                    <Grid item style={{ borderTop: `2px solid ${color}`, borderBottom: `2px solid ${color}` }}>
                        <Grid style={{ marginTop: 5, marginBottom: 5 }} container direction="column" alignItems="center">
                            <Grid item>
                                <Typography
                                    style={{ fontWeight: 300, fontSize: '20px', lineHeight: "30px" }}
                                    className={"whiteText"} variant="subtitle1" align="center"
                                >
                                    {'Su horario programado para hoy es:'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{ fontWeight: 500, fontSize: '22px', lineHeight: "30px" }}
                                    className={"whiteText"} variant="subtitle1" align="center"
                                >
                                    {assistanceData.turn}
                                </Typography>
                            </Grid>
                            {
                                assistanceData.holiday &&
                                <Grid item>
                                    <Typography
                                        style={{ color: color, fontWeight: 500, fontSize: '18px', lineHeight: "30px" }}
                                        variant="subtitle1" align="center"
                                    >
                                        {`(${assistanceData.holiday})`}
                                    </Typography>
                                </Grid>
                            }
                            {
                                time &&
                                <Grid item>
                                    <Typography className={"whiteText"} style={{ fontWeight: 500, fontSize: '30px', lineHeight: "30px" }}>
                                        {
                                            new Date(time).toLocaleTimeString("en-US", {
                                                hour12: false,
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                            })
                                        }
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} style={{ marginTop: 20, marginBottom: 20 }}>
                        {
                            assistanceData.canSelectWorkplace &&
                            <Grid item xs={12}>
                                <FormControl style={{ width: "100%" }}>
                                    <InputLabel required id={`label`}>{'Lugar de trabajo'}</InputLabel>
                                    <Select
                                        value={workplace}
                                        labelId={`label`}
                                        id={`select`}
                                        onChange={(e) => setWorkplace(e.target.value)}
                                        margin={"none"}
                                    >
                                        {
                                            assistanceData.workplaces?.map(item => (
                                                <MenuItem
                                                    className={"whiteText"}
                                                    key={item.key}
                                                    value={item.key}
                                                >
                                                    {item.value}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {
                            [
                                { key: "entry", value: "Entrada" },
                                { key: "exit", value: "Salida" },
                            ].map((item) => {
                                return (
                                    <Grid key={item.key} item xs={12} sm={6}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: color }}
                                            onClick={() => handleRegister(item.key)}
                                        >
                                            <Typography
                                                style={{ color: "white", textTransform: "none", fontSize: "22px" }}
                                            >
                                                {item.value}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {
                        assistanceData.latest_registered_origin &&
                        <Grid item container justify="center" align="center" style={{ marginBottom: 10 }} direction="column">
                            <Typography className="whiteText" style={{ fontWeight: "bold" }}>
                                {getLocalizedString("yourLastAssistanceEntryIs")}
                            </Typography>
                            <Typography className="whiteText" style={{ fontWeight: 400, fontSize: "1.25rem" }}>
                                {formatDate(assistanceData.latest_registered_date) + " " + assistanceData.latest_registered_time + " hrs.  - " + (assistanceData.latest_registered_type === "exit" ? getLocalizedString("exit") : getLocalizedString("entrance"))}
                            </Typography>
                            <Typography className="whiteText" style={{ fontWeight: "bold", paddingTop: 15 }}>
                                {getLocalizedString("lastAssistanceEntryOrigin")}
                            </Typography>
                            <Typography className="whiteText" style={{ fontWeight: 400, fontSize: "1.25rem" }}>
                                {getOriginString(assistanceData.latest_registered_origin)}
                            </Typography>
                        </Grid>
                    }
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default RegisterAssistanceDialog;